import React from 'react';
import { useTranslation } from 'react-i18next';
import { ImageContainer, TitleContainer } from './styles';

import Title from '../../components/text/Title';
import SuccessStoryDesktop from './section';
import { questions } from '../../utils/projectdiscovery';

const ProjectDiscovery = () => {
  const { t } = useTranslation();

  return (
    <>
      <ImageContainer />
      <TitleContainer>
        <Title content={t('Project Discovery')} size={1} />
      </TitleContainer>
      {questions.map((question, index) => (
        <SuccessStoryDesktop key={index} questions={question} />
      ))}
    </>
  );
};

export default ProjectDiscovery;
