import styled from "styled-components";
import Color from "../../../commons/Color";
import Device from "../../../commons/Device";
import Frame from "../../../assets/backgrounds/Frame2.png";
import { Col, Row } from "antd";
import Title from "../../../components/text/Title";

export const Container = styled.section`
  .frame-wrapper {
    background-image: url(${Frame});
    background-position: fixed;
    background-repeat: no-repeat;
    background-position-x: right;
    position: relative;
  }

  .data-container-modality {
    padding: 7% 15.5%;
    padding-top: 5%;

    .custom-title {
      margin-left: 0;
      margin-bottom: 0;
      font-size: 40px;
    }

    h2.custom-title {
      color: #fff;
      margin-bottom: 32px;
      font-size: 32px;
      font-family: "BarlowLight";
    }

    .item-project-modality h3 {
      font-size: 24px;
      color: white;
    }

    .modality-card_content {
      color: white;
      margin-top: 2rem;
      font-family: "Barlow";
      font-size: 18px;
    }

    .modality-card_content:before {
      content: "";
      width: 15%;
      height: 3px;
      top: -1rem;
      display: block;
      position: relative;
      background: ${Color.button} 0 20%;
    }
    .item-project-modality > h4 {
      margin-top: 0;
      margin-bottom: 0;
    }

    .modality-items {
      padding-top: 3em;
    }
  }

  .data-container-modality > h1 {
    color: white;
  }

  @media ${Device.xs} {
    .data-container-modality {
      padding: 7% 10%;
      .modality-card_content:before {
        width: 25%;
      }
    }
    .data-container-modality > h2 {
      font-size: 21px;
    }
    .frame-wrapper {
      background: none;
    }
  }
`;

export const ItemProjectModality = styled(Col)`
  h3.custom-title {
    font-size: 24px;
    color: white;
  }
  .custom-title {
    margin-left: 0;
    margin-bottom: 0;
  }

  h2.custom-title {
    color: #fff;
    margin-bottom: 32px;
    font-size: 24px;
    font-family: "BarlowLight";
  }
  .modality-card_content {
    color: white;
    margin-top: 1rem;
    font-family: "Barlow";
    font-size: 18px;
  }

  .modality-card_content:before {
    content: "";
    width: 20px;
    height: 3px;
    top: -0.85rem;
    display: block;
    position: relative;
    background: ${Color.button} 0 20%;
  }

  & > h4 {
    margin-top: 0;
    margin-bottom: 0;
  }
  &.modality-items {
    padding-top: 3em;
  }
  &.data-container-modality > h1 {
    color: white;
  }
`;

export const ModalityContainer = styled.div`
  padding: 5% 15.5% 7% 15.5%;
  @media ${Device.xs} {
    padding: 7% 10%;
  }
`;

export const ModalityItems = styled(Row)`
  padding-top: 3em;
`;

export const ModalItemsWrapper = styled.div`
  margin: 1rem;
  @media ${Device.xs} {
    margin: 1rem 0;
  }
`;

export const ModalityCardTitle = styled(Title)`
  font-size: 24px;
  color: white;
`;
export const ModalityCardContent = styled.p`
  &:before {
    content: "";
    width: 15%;
    height: 3px;
    top: -1rem;
    display: block;
    position: relative;
    background: ${Color.button} 0 20%;
  }
  @media ${Device.xs} {
    &:before {
      width: 25%;
    }
  }
`;

export const ModalContainer = styled.section`
  background-image: url(${Frame});
  background-repeat: no-repeat;
  background-position-x: right;
  position: relative;
  min-height: 98vh;
  margin: -14px;
  padding: 7% 15.5%;
  padding-top: 5%;
  @media ${Device.xs} {
    padding: 5%;
  }
`;
export const ModalTitle = styled.h1`
  font-family: "BarlowBold";
  font-size: 40px;
  color: #40ade3;
  margin: 1rem;
  @media ${Device.xs} {
    font-size: 3rem;
    margin: 0 !important;
  }
`;
export const ModalSubtitle = styled.h2`
  font-family: "Barlow";
  font-weight: bold;
  font-size: 28px;
  font-style: italic;
  color: white;
  margin: 3rem 1rem;
  @media ${Device.xs} {
    font-size: 24px;
    margin: 3rem 0;
  }
`;
export const ModalLeftContent = styled(Col)`
  padding: 1rem 2rem 1rem 1rem !important;
  font-family: "Barlow";
  font-size: 24px;
  color: white;
  @media ${Device.xs} {
    padding: 0 !important;
  }
`;
export const ModalRightContent = styled(Col)`
  padding: 1rem 1rem 1rem 2rem !important;
  font-family: "BarlowBold";
  font-size: 28px;
  color: white;
  &::before {
    content: "";
    display: block;
    height: 100%;
    width: 5px;
    border-left: 3px;
    border-color: white;
    border-style: solid;
    position: absolute;
    left: 0;
  }
  @media ${Device.xs} {
    font-size: 1.5rem;
  }
`;
export const StyledButton = styled.div`
  .default-button {
    min-width: 240px !important;
  }
  .button-wrapper {
    padding: 0;
  }
`;

export const ModalRow = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const LeftContentRow = styled(Row)`
  margin: 1rem 0;
  @media ${Device.xs} {
    font-size: 1.25rem;
  }
`;
