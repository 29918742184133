import React from "react";
import { useTranslation } from "react-i18next";
import { DataContainer, Img, DiamondImage } from "./styles";
import { Col, Row } from "antd";
import Description from "./description";
import Title from "../../components/text/Title";
import Diamond from "../../assets/services/Group7.svg";
import PropTypes from "prop-types";

const SuccessStoryMobile = ({ project }) => {
  const { t } = useTranslation();
  return (
    <DataContainer>
      <Row type="flex">
        <Col lg={12} className="description-col">
          {project.mockups &&
            project.mockups
              .slice(0, 1)
              .map((mockup, index) => (
                <Img src={mockup} key={index} alt={t(project.title)} />
              ))}
          <Title
            content={t("projects.aboutTitle")}
            size={2}
            className="project-description"
          />
          <p>{t(project.about)}</p>
          {project.subprojects ? (
            <>
              {project.subprojects.map((project, index) => (
                <div key={index}>
                  <Title
                    content={t(project.title)}
                    size={2}
                    className="subproject-title"
                  />
                  <Img src={project.mockup} key={index} alt={t(project.title)} />
                  <Description
                    challenge={t(project.challenge)}
                    interventions={project.interventions}
                  />
                </div>
              ))}
              <DiamondImage src={Diamond} alt="Diamante" />
            </>
          ) : (
            <>
              <Description
                challenge={t(project.challenge)}
                interventions={project.interventions}
              />
              {project.mockups &&
                project.mockups
                  .slice(1)
                  .map((mockup, index) => (
                    <Img src={mockup} key={index} alt={t(project.title)} />
                  ))}
              <DiamondImage src={Diamond} alt="Diamante" />
            </>
          )}
        </Col>
      </Row>
    </DataContainer>
  );
};

export default SuccessStoryMobile;

SuccessStoryMobile.propTypes = {
  /* *
   * The story project
   */
  project: PropTypes.object.isRequired,
};
