import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Bold } from './styles';
import Title from '../../components/text/Title';

const Description = ({ challenge, interventions }) => {
  const { t } = useTranslation();
  return (
    <>
      <Title
        content={t('projects.challenge')}
        size={2}
        className="project-description"
      />
      <p>{challenge}</p>
      <Title
        content={t('projects.intervention')}
        size={2}
        className="project-description"
      />
      {interventions.map((intervention, index) => (
        <p key={index}>
          {t(intervention.text1)}
          <Bold>{t(intervention.bold)}</Bold>
          {t(intervention.text)}
        </p>
      ))}
    </>
  );
};

export default Description;

Description.propTypes = {
  /* *
   * Specifies story challenge
   */
  challenge: PropTypes.string,
  /* *
   * Specifies story intervention/s
   */
  interventions: PropTypes.array,
};
