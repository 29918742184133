import React from 'react';
import { DataContainer, DataContainerLadder } from '../styles';
import { Col, Row } from 'antd';
import Title from '../../../components/text/Title';
import BackgroundText from '../../../components/text/BackgroundText';
import Description from '../../successStories/pdDescription';
import { isDevice } from '../../../utils';
import YouTube from 'react-youtube';
import BackgroundImage from '../../../assets/backgrounds/bg-what-we-do.svg';
import BackgroundImageSmall from '../../../assets/backgrounds/bg-what-we-do-small.svg';
import { useTranslation } from 'react-i18next';

const SuccessStoryDesktop = ({ questions }) => {
  const { t } = useTranslation();
  const getImage = () => {
    let style = {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    };
    let image = BackgroundImage;
    if (isDevice()) {
      image = BackgroundImageSmall;
      style['backgroundPosition'] = 'top';
    }
    style['backgroundImage'] = `url(${image})`;
    return style;
  };
  return (
    <>
      <DataContainer>
        <Row className="section-video" type="flex">
          <Col className="section-text" lg={6}>
            <div className="card-text-project">
              <Title
                content={t('projectDiscovery.aboutTitle')}
                size={1}
                className="project-description"
              />
              <p dangerouslySetInnerHTML={{ __html: t(questions.about) }} />
            </div>
          </Col>
          <Col className="Video-pods" lg={6}>
            <YouTube videoId="L457FsiB740" className="youtube-video" />
          </Col>
        </Row>
        <Row className="section-video" type="flex">
          <Col className="section-text" lg={6}>
            <div className="card-text-project">
              <Title
                content={t(questions.benefits)}
                size={1}
                className="project-description"
              />
              <Description
                challenge={t(questions.challenge)}
                interventions={t(questions.interventions)}
              />
            </div>
          </Col>
          <Col className="Video-pods" lg={6}>
            <YouTube videoId="r9tUA6Yzy0E" className="youtube-video" />
          </Col>
        </Row>
        <Row className="section-video" type="flex">
          <Col className="section-text" lg={6}>
            <div className="card-text-project">
              <Title
                content={t('projectDiscovery.goals')}
                size={1}
                className="project-description"
              />
              <p dangerouslySetInnerHTML={{ __html: t(questions.howTo) }} />
            </div>
          </Col>
          <Col className="Video-pods" lg={6}>
            <YouTube videoId="4BS0TnAWSaQ" className="youtube-video" />
          </Col>
        </Row>
        <BackgroundText
          content={t(questions.tags)}
          position="absolute"
          fontColor="#DE3080"
          fontSize="240px"
          lineHeight="100%"
          wordBreak="keep-all"
          margin="auto"
          isVisibleInMobile={false}
          transform="translateX(-50%)"
          bottom="0"
          left="50%"
          lgFontSize="180px"
        />
      </DataContainer>
      <DataContainerLadder style={getImage()}>
        <div className="frame-wrapper">
          <div className="bonus-text">
            <h1 className="track-text">{t(questions.bonusTrack)}</h1>
            <h1 className="exp-text">{t(questions.ourExp)}</h1>
          </div>
          <div
            className="data-container-modality"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <Row type="flex" className="modality-items">
              {' '}
              <div className="video-col-back">
                <YouTube videoId="jr9JYUFJ9jo" className="youtube-video" />
              </div>
            </Row>
          </div>
        </div>
      </DataContainerLadder>
    </>
  );
};

export default SuccessStoryDesktop;
