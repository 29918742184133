/*----- Core -----*/
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

/*----- Utils -----*/
import { testList } from '../../../utils';

/*----- Commons -----*/
import Barlow from '../../../commons/mixins/fonts/Barlow';
import Device from '../../../commons/Device';

/*----- Components -----*/
import ItemTest from './ItemTest';

const Styles = styled.div`
  p {
    ${Barlow(15, 400, '#1E115C')}
  }

  @media ${Device.xs} {
    .contact-information {
      display: none;
    }
  }
`;

const SectionTest = () => {
  const { t } = useTranslation();
  const getTestList = () => {
    return testList.map((test, idx) => (
      <ItemTest
        key={idx}
        title={test.title}
        url={test.url}
        download={test.download}
      />
    ));
  };

  return (
    <Styles className="section-test">
      <p className="section-test-description">{t('joinOurTeam.body1')}</p>
    </Styles>
  );
};

export default SectionTest;
