import React from "react";
import ArrowRight from "../../assets/services/arrows/ArrowRight";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Barlow from "../../commons/mixins/fonts/Barlow";
import Device from "../../commons/Device";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const StyledSeeMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:hover {
    cursor: pointer;
  }

  .arrow-see-more {
    display: inline-block;
    fill: #da3080;
    width: 1rem;
    height: 0.5rem;
  }

  @media ${Device.xs} {
    margin-top: 10px;
  }
`;

const Span = styled.span`
  ${Barlow(14, 500, "#DA3080")};
`;

const SeeMore = ({ link, history }) => {
  const { t } = useTranslation();
  return (
    <StyledSeeMore
      onClick={() => {
        history.push(link);
      }}
    >
      <Span className={"see-more"}>
        {t("menu.seeMore")} <ArrowRight className={"arrow-see-more"} />
      </Span>
    </StyledSeeMore>
  );
};
export default withRouter(SeeMore);

SeeMore.propTypes = {
  /* *
   *  Route link
   */
  link: PropTypes.string,
  /* *
   * Router history
   */
  history: PropTypes.object,
};
