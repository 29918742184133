import React from "react";
import { Container } from "./styles";
import FloatingElementsWhatWeDo from "./FloatingElements";
import CardDeck from "./CardDeck";
import BackgroundText from "../../../components/text/BackgroundText";

const WhatWeDoList = () => (
  <Container>
    <FloatingElementsWhatWeDo />
    <BackgroundText content="Software" left="33%" mobileMarginTop="10%" />
    <CardDeck />
  </Container>
);

export default WhatWeDoList;
