import React from "react";
import { useTranslation } from "react-i18next";
import {
  ImageContainer,
  DataContainer,
  TitleContainer,
  MainContainer,
  ProjectsBanner,
  Frame,
} from "./styles";

import { isDevice, projects } from "../../utils";
import Title from "../../components/text/Title";
import ProjectCard from "../../components/ProjectCard";

import { Row, Col } from "antd";
import DefaultButton from "../../components/button/DefaultButton";
import BackgroundText from "../../components/text/BackgroundText";

const Projects = () => {
  const { t } = useTranslation();
  const getParterList = () => {
    return projects.map((item, idx) => (
      <Col sm={24} md={12} lg={8} key={idx} className="project-list-col">
        <ProjectCard
          title={t(item.title)}
          subtitle={t(item.subtitle)}
          label={t(item.label)}
          seeMore={item.seeMore}
          image={item.image}
          mockups={item.mockups}
          about={t(item.about)}
          challenge={t(item.challenge)}
          interventions={item.interventions}
          home={false}
        />
      </Col>
    ));
  };

  return (
    <MainContainer>
      <ImageContainer />
      <TitleContainer>
        <Title content={t("menu.projects")} size={1} />
      </TitleContainer>
      <DataContainer data-aos="fade-up" data-aos-duration="2000">
        <Row type="flex" gutter={[24, 24]} className="project-list">
          {getParterList()}
        </Row>
      </DataContainer>
      <BackgroundText
        fontSize="12"
        lineHeight="80%"
        left="2.5%"
        content={t("backgrounds.successStories")}
        mobileFontSize="25"
        mobileBottom="-5%"
        position="relative"
        marginBottom="0"
      />
      <ProjectsBanner>
        <Row type="flex">
          {!isDevice() && <Frame />}
          <DefaultButton
            destination="/getInTouch"
            content={t("projects.button")}
            className="banner-button"
          />
        </Row>
      </ProjectsBanner>
    </MainContainer>
  );
};

export default Projects;
