import React from "react";

const Phone = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.719 23.871">
    <path
      className="a"
      d="M287.124,137.63l-2.825-2.968a1.933,1.933,0,0,0-2.825,0l-1.284,1.348a1.464,1.464,0,0,1-2.14,0l-5.565-5.852a1.649,1.649,0,0,1,0-2.249l1.284-1.348a2.17,2.17,0,0,0,0-2.968l-2.825-2.957a1.929,1.929,0,0,0-2.822,0l-1.029,1.07a7.851,7.851,0,0,0,0,10.7l8.818,9.27a6.956,6.956,0,0,0,10.185,0l1.027-1.078A2.181,2.181,0,0,0,287.124,137.63Zm-18.062-16a.645.645,0,0,1,.944,0l2.822,2.957a.724.724,0,0,1,0,.991l-.471.494-3.764-3.954Zm7.791,19.061-8.818-9.27a6.378,6.378,0,0,1-.366-8.3l3.751,3.941a3.1,3.1,0,0,0,.124,4.087L277.11,137h0a2.745,2.745,0,0,0,3.89.131l3.751,3.941A5.635,5.635,0,0,1,276.853,140.687Zm9.33-1.081-.471.5-3.766-3.959.471-.494a.646.646,0,0,1,.942,0l2.825,2.968A.726.726,0,0,1,286.183,139.607Z"
      transform="translate(-264.988 -120.023)"
    />
  </svg>
);

export default Phone;
