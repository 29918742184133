import React from "react";
import Tag from "../tag";
import {
  StyledInformationCard,
  StyledInformationTitle,
  StyledInformationSubtitle,
} from "./styles";
import SeeMore from "../commons/SeeMore";
import PropTypes from "prop-types";
import FloatingElements from "./FloatingElements";

const InformationCard = ({ title, subtitle, label, className, seeMore }) => (
  <StyledInformationCard className={className}>
    <FloatingElements />
    <StyledInformationTitle>{title}</StyledInformationTitle>
    <StyledInformationSubtitle>{subtitle}</StyledInformationSubtitle>
    <div>
      <Tag text={label} color={"#FFFFFF"} />
    </div>
    <SeeMore link={seeMore} />
  </StyledInformationCard>
);

export default InformationCard;

InformationCard.propTypes = {
  /* *
   * Specifies project title
   */
  title: PropTypes.string.isRequired,
  /* *
   * Specifies project subtitle
   */
  subtitle: PropTypes.string.isRequired,
  /* *
   * Specifies Label name
   */
  label: PropTypes.string.isRequired,
  /* *
   * Specifies class name
   */
  className: PropTypes.string,
  /* *
   * 'See more' details
   */
  seeMore: PropTypes.string,
};
