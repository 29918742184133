/*----- Core -----*/
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

/*----- Components -----*/
import IconButton from "../../button/IconButton";
import { Col, Row } from "antd";

/*----- Device -----*/
import Device from "../../../commons/Device";

/*----- Assets -----*/
import Linkedin from "../../../assets/social-networks/Linkedin";
import Facebook from "../../../assets/social-networks/Facebook";
import Instagram from "../../../assets/social-networks/Instagram";
import Medium from "../../../assets/social-networks/Medium";
import Behance from "../../../assets/social-networks/Behance";
import Spotify from "../../../assets/social-networks/Spotify";

const networks = [
  {
    destination: "https://www.instagram.com/snappler_ar/?hl=es-la",
    icon: <Instagram />,
  },
  {
    destination:
      "https://www.facebook.com/SnapplerAr/?eid=ARBHCtwcyXs8A-Z24ZzHrqAX5LP5N0LQl4v90bJhO6LRoxENlWJ_-zmrZD5URgJaBFWZ9kVJpkBaRNwd",
    icon: <Facebook />,
  },
  {
    destination: "https://www.linkedin.com/company/3742832/admin/",
    icon: <Linkedin />,
  },
  {
    destination: "https://medium.com/snappler",
    icon: <Medium />,
  },
  {
    destination: "https://www.behance.net/designsnappler",
    icon: <Behance />,
  },
  {
    destination: "https://open.spotify.com/show/5eGzlNBWBcG56ASvQ2koAk",
    icon: <Spotify />,
  },
];

const StyledSocialNetworks = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;

  @media ${Device.md} {
    margin-top: 0;
    justify-content: space-evenly;
  }
`;

const StyledSocialWrapper = styled.div`
  padding: 0;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  @media ${Device.md} {
    justify-content: flex-start;
  }
`;

const SocialNetworks = ({ iconSize = "26px" }) => {
  const getContentSocialNetworks = () =>
    networks.map((social, idx) => (
      <IconButton
        key={idx}
        destination={social.destination}
        icon={social.icon}
        size={iconSize}
      />
    ));

  return (
    <Col
      xs={{ span: 24, order: 2 }}
      sm={{ span: 24, order: 2 }}
      md={{ span: 6, order: 4 }}
    >
      <StyledSocialWrapper>
        <Row type="flex">
          <Col xs={24} md={12}>
            <StyledSocialNetworks>
              {getContentSocialNetworks().slice(0, 3)}
            </StyledSocialNetworks>
          </Col>
          <Col xs={24} md={12}>
            <StyledSocialNetworks>
              {getContentSocialNetworks().slice(3)}
            </StyledSocialNetworks>
          </Col>
        </Row>
      </StyledSocialWrapper>
    </Col>
  );
};

export default SocialNetworks;

/*----- PropTypes -----*/
SocialNetworks.propTypes = {
  /* *
   * Icon size in px
   */
  iconSize: PropTypes.string,
};
