import React from "react";
import CubeSoftware from "../../../assets/services/Grupo1853.svg";
import CubeWhatWeDo from "../../../assets/services/whatwedo-cube.svg";
import Lines from "../../../assets/services/lines.svg";
import Hexagon from "../../../assets/services/software-hex.svg";
import DiamondDarkBlue from "../../../assets/services/software-darkblue-diamond.svg";
import DiamondBlue from "../../../assets/services/software-blue-diamond.svg";
import FloatingImage from "../../../commons/FloatingImage";

const FloatingElementsWhatWeDo = () => (
  <>
    <FloatingImage
      image={CubeSoftware}
      width={"40px"}
      bottom={"7.5%"}
      left={"7.5%"}
    />
    <FloatingImage
      image={Lines}
      height={"180px"}
      bottom={"-1rem"}
      left={"0%"}
    />
    <FloatingImage
      image={CubeWhatWeDo}
      width={"50px"}
      bottom={"-25px"}
      right={"25%"}
      displayMobile
      bottomMobile={"-25px"}
      rightMobile={"15%"}
    />
    <FloatingImage image={Hexagon} width={"50px"} top={"30%"} right={"0%"} />
    <FloatingImage
      image={DiamondBlue}
      width={"40px"}
      top={"20%"}
      right={"2.5%"}
    />
    <FloatingImage
      image={DiamondDarkBlue}
      width={"35px"}
      top={"27.5%"}
      right={"5%"}
      displayMobile
      widthMobile={"25px"}
      topMobile={"5%"}
      rightMobile={"5%"}
    />
  </>
);

export default FloatingElementsWhatWeDo;
