/*----- Core -----*/
import React from "react";
import styled, { css } from "styled-components";

import BarlowBold from "../../../commons/mixins/fonts/BarlowBold";
import Device from "../../../commons/Device";

/*----- Components -----*/
import { Row, Col } from "antd";
import { isDevice } from "../../../utils";

const Styles = styled.section`
  .cover-image {
    .container-member-data {
      display: none;
      background-color: rgba(53, 47, 128, 0.65);
      color: white;
      font-size: 30px;
      position: absolute;

      .memeber-data {
        position: absolute;
        bottom: 0px;
        width: 100%;
        padding-left: 20px;
      }

      .member-name {
        ${BarlowBold(16, 700, "white")}
        margin-bottom:0px;
      }

      .member-rol {
        ${BarlowBold(12, 600, "#40ADE3")};
      }
    }
  }

  .container {
    height: 767px;
    display: flex;
  }

  .left {
    flex: 0 0 50%;
  }

  .right {
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;
  }

  .one {
    background-image: url("http://i.imgur.com/AzeiaRY.jpg");
    background-size: cover;
    flex: 25%;
  }

  .two {
    background-image: url("http://i.imgur.com/AzeiaRY.jpg");
    background-size: cover;
    flex: 25%;
  }

  ${(props) =>
    !props.isMobile &&
    css`
      .cover-image:hover {
        cursor: pointer;
        .container-member-data {
          display: block;
        }
      }
    `}

  .big-image {
    width: 34.8vw;
    height: 502px;
  }

  .small-image {
    width: 16.5vw;
    height: 243px;
  }

  .all-images {
    overflow: hidden;
    object-fit: cover;
  }

  .border-b {
    border-bottom: 15px solid white;
  }

  .border-r {
    border-right: 15px solid white;
  }

  .small-gallery {
    display: none;
  }

  @media ${Device.xs} {
    .small-image {
      width: 43vw;
      height: 160px;
    }

    .small-gallery {
      display: block;
    }

    .big-gallery {
      display: none;
    }
    .cover-image {
      border: 5px solid white;

      .container-member-data {
        display: block;
        background-color: initial;
        .memeber-data {
          padding-left: 10px;
          background-color: #030c39;
        }

        .member-name {
          font-size: 14px;
          line-height: 20px;
        }

        .member-rol {
          font-size: 12px;
          line-height: 14px;
          margin-bottom: 5px;
        }
      }
    }
  }
`;

const GalleryTeam = ({ data }) => {
  if (!data.length) {
    return <div></div>;
  }

  const getMemberData = (name, rol, className) => {
    return (
      <div className={`container-member-data ${className}`}>
        <div className="memeber-data">
          <p className="member-name">{name}</p>
          <p className="member-rol">{rol}</p>
        </div>
      </div>
    );
  };

  const getDataMobile = () => {
    const getMembers = () => {
      return data.map((member, idx) => {
        if (idx < 10) {
          return (
            <Col span={12} className="cover-image">
              {getMemberData(member.name, member.rol, "small-image")}
              <img
                className="small-image all-images"
                src={member.image}
                alt={member.alt}
              />
            </Col>
          );
        }
        return "";
      });
    };
    return (
      <div className="small-gallery">
        <Row className="row-small">{getMembers()}</Row>
      </div>
    );
  };

  const getData = () => {
    const Grid = styled.div``;
    const Row = styled.div`
      display: flex;
    `;
    const Col = styled.div`
      flex: ${(props) => props.size};
    `;
    return (
      <div className="big-gallery">
        <Grid>
          <Row className="border-b">
            <Col size={2}>
              <div className="border-r cover-image">
                {getMemberData(data[0].name, data[0].rol, "big-image")}
                <img
                  className="big-image all-images"
                  src={data[0].image}
                  alt={data[0].alt}
                />
              </div>
            </Col>

            <Col size={2}>
              <Row className="border-b">
                <Col size={1}>
                  <div className="border-r cover-image">
                    {getMemberData(data[1].name, data[1].rol, "small-image")}
                    <img
                      className="small-image all-images"
                      src={data[1].image}
                      alt={data[1].alt}
                    />
                  </div>
                </Col>

                <Col size={1}>
                  <div className="border-r cover-image">
                    {getMemberData(data[2].name, data[2].rol, "small-image")}
                    <img
                      className="small-image all-images"
                      src={data[2].image}
                      alt={data[2].alt}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col size={1}>
                  <div className="border-r cover-image">
                    {getMemberData(data[3].name, data[3].rol, "small-image")}
                    <img
                      className="small-image all-images"
                      src={data[3].image}
                      alt={data[3].alt}
                    />
                  </div>
                </Col>

                <Col size={1}>
                  <div className="border-r cover-image">
                    {getMemberData(data[4].name, data[4].rol, "small-image")}
                    <img
                      className="small-image all-images"
                      src={data[4].image}
                      alt={data[4].alt}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="border-b">
            <Col size={1}>
              <Row className="border-b">
                <Col size={1}>
                  <div className="border-r cover-image">
                    {getMemberData(data[5].name, data[5].rol, "small-image")}
                    <img
                      className="small-image all-images"
                      src={data[5].image}
                      alt={data[5].alt}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col size={1}>
                  <div className="border-r cover-image">
                    {getMemberData(data[6].name, data[6].rol, "small-image")}
                    <img
                      className="small-image all-images"
                      src={data[6].image}
                      alt={data[6].alt}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col size={2}>
              <div className="border-r cover-image">
                {getMemberData(data[7].name, data[7].rol, "big-image")}
                <img
                  className="big-image all-images"
                  src={data[7].image}
                  alt={data[7].alt}
                />
              </div>
            </Col>
            <Col size={1}>
              <Row className="border-b">
                <Col size={1}>
                  <div className="border-r cover-image">
                    {getMemberData(data[8].name, data[8].rol, "small-image")}
                    <img
                      className="small-image all-images"
                      src={data[8].image}
                      alt={data[8].alt}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col size={1}>
                  <div className="border-r cover-image">
                    {getMemberData(data[9].name, data[9].rol, "small-image")}
                    <img
                      className="small-image all-images"
                      src={data[9].image}
                      alt={data[9].alt}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col size={2}>
              <Row className="border-b">
                <Col size={1}>
                  <div className="border-r cover-image">
                    {getMemberData(data[10].name, data[10].rol, "small-image")}
                    <img
                      className="small-image all-images"
                      src={data[10].image}
                      alt={data[10].alt}
                    />
                  </div>
                </Col>

                <Col size={1}>
                  <div className="border-r cover-image">
                    {getMemberData(data[11].name, data[11].rol, "small-image")}
                    <img
                      className="small-image all-images"
                      src={data[11].image}
                      alt={data[11].alt}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="border-b">
                <Col size={1}>
                  <div className="border-r cover-image">
                    {getMemberData(data[12].name, data[12].rol, "small-image")}
                    <img
                      className="small-image all-images"
                      src={data[12].image}
                      alt={data[12].alt}
                    />
                  </div>
                </Col>
                <Col size={1}>
                  <div className="border-r cover-image">
                    {getMemberData(data[13].name, data[13].rol, "small-image")}
                    <img
                      className="small-image all-images"
                      src={data[13].image}
                      alt={data[13].alt}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col size={2}>
              <div className="border-r cover-image">
                {getMemberData(data[14].name, data[14].rol, "big-image")}
                <img
                  className="big-image all-images"
                  src={data[14].image}
                  alt={data[14].alt}
                />
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };

  const getGallery = () => {
    return isDevice() ? getDataMobile() : getData();
  };

  return <Styles isMobile={isDevice()}>{getGallery()}</Styles>;
};

export default GalleryTeam;
