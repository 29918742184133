import React, { useEffect } from "react";
import { connect } from "react-redux";

/*----- Components -----*/
import WhatWeDoList from "./List";
import ProjectModality from "./ProjectModality";
import Color from "../../commons/Color";

import { changeLogoColor } from "../app/AppAction";

const WhatWeDo = (props) => {
  useEffect(() => {
    props.changeLogoColor(Color.text);
  }, [props.changeLogoColor, props]);

  return (
    <>
      <WhatWeDoList />
      <ProjectModality />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  changeLogoColor: (payload) => changeLogoColor(dispatch, payload),
});

export default connect(null, mapDispatchToProps)(WhatWeDo);
