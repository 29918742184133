import Ruby from "../assets/joinOurTeam/ruby.png";
import RubyTest from "../assets/pdf/RubyTest.pdf";

export const testList = [
  {
    title: "Ruby on Rails Test",
    url: Ruby,
    download: RubyTest,
  },
];
