/*----- Core -----*/
import React from "react";

/*----- Components -----*/
import BenefitList from "./section/BenefitList";
import ValueList from "./section/ValueList";
import AboutUsHeader from "./section/AboutUsHeader";
import AboutUsTeam from "./section/AboutUsTeam";
import AboutUsWorkspace from "./section/AboutUsWorkspace";

const ContactUs = () => (
  <>
    <AboutUsHeader />
    <AboutUsTeam />
    <BenefitList />
    <ValueList />
    <AboutUsWorkspace />
  </>
);

export default ContactUs;
