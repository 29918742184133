import React from 'react'
import styled from 'styled-components'

/*----- Commons -----*/
import Device from '../Device'

/*----- Assets -----*/
import LightningPic from '../../assets/services/Grupo1918.svg'

const Styles = styled.div`
    position:absolute;
    z-index:3;
    top: 60%;
    left: 29%;
    background-color:transparent;

    img{
        width:18vh
    }

    @media ${Device.xs} {
        display:none;
    }
`

const Lightning = () => {
    return (
        <Styles>
            <img src={LightningPic} alt="LightningPic" />
        </Styles>
    )
}

export default Lightning