/*----- Core -----*/
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

/*----- Commons -----*/
import BarlowBold from "../../commons/mixins/fonts/BarlowBold";

/*----- Styles -----*/
const common = `
  margin: 16px;
  margin-bottom:3rem;
`;
const H1 = styled.h1`
  ${BarlowBold(34, 600, "Black")};
  ${common}
`;
const H2 = styled.h2`
  ${BarlowBold(26, 600, "Black")};
  ${common}
`;
const H3 = styled.h3`
  ${BarlowBold(18, 600, "Black")};
  ${common}
`;
const H4 = styled.h4`
  ${BarlowBold(16, 500, "Black")};
  ${common}
`;
const H5 = styled.h5`
  ${BarlowBold(14, 500, "Black")};
  ${common}
`;

/*----- Export -----*/
const Title = ({ content, size, className }) => {
  let classes = `custom-title ${className}`;

  switch (size) {
    case 1:
      return <H1 className={classes}>{content}</H1>;
    case 2:
      return <H2 className={classes}>{content}</H2>;
    case 3:
      return <H3 className={classes}>{content}</H3>;
    case 4:
      return <H4 className={classes}>{content}</H4>;
    case 5:
      return <H5 className={classes}>{content}</H5>;
    default:
      return <H1 className={classes}>{content}</H1>;
  }
};

export default Title;

/*----- PropTypes -----*/
Title.propTypes = {
  /* *
   * Specifies header size
   */
  size: PropTypes.number.isRequired,
  /* *
   * Specifies title content
   */
  content: PropTypes.string.isRequired,
};
