/*----- Core -----*/
import React from "react";
import styled from "styled-components";

import Title from "../../../components/text/Title";
import BarlowBold from "../../../commons/mixins/fonts/BarlowBold";
import Barlow from "../../../commons/mixins/fonts/Barlow";
import Device from "../../../commons/Device";
import { Row, Col } from "antd";

const Styles = styled.div`
  margin: 1rem;
  width: 100%;

  .benefit-icon {
    display: flex;
    align-items: center;
    img {
      width: 100%;
      padding: 0.7rem;
    }
  }
  .benefit-description {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .custom-title {
    margin: 0;
    ${BarlowBold(18, 600, "#352F80")}
  }

  p {
    ${Barlow(16, 500, "#352F80")}
    max-width: 88%;
    margin: 0;
  }

  @media ${Device.xl} {
    .custom-title {
      font-size: 24px;
    }

    p {
      font-size: 18px;
    }
  }

  @media ${Device.xs} {
    margin: 0;

    .custom-title {
      font-size: 14px;
    }
    .p {
      font-size: 1rem;
    }
  }
`;

const BenefitCard = ({ title, content, icon }) => (
  <Styles>
    <Row type="flex" justify="center">
      <Col xs={8} md={6} className="benefit-icon">
        <img src={icon} alt={title} />
      </Col>
      <Col xs={14} md={18} className="benefit-description">
        <Title content={title} size={3} />
        <p>{content}</p>
      </Col>
    </Row>
  </Styles>
);
export default BenefitCard;
