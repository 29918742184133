/*----- Core -----*/
import React from "react";
import styled from "styled-components";

/*----- Components -----*/
import BenefitCard from "./BenefitCard";
import { benefits } from "../../../utils";
import Device from "../../../commons/Device";
import Color from "../../../commons/Color";
import BarlowBold from "../../../commons/mixins/fonts/BarlowBold";
import ButtonContainerHome from "../../../components/button/ButtonContainerHome";
import { Row, Col } from "antd";
import FloatingElements from "./FloatingElements";
import { useTranslation } from "react-i18next";

const Styles = styled.div`
  min-height: 100vh;
  padding: 7.5% 15.4%;
  position: relative;
  .benefit-col {
    display: flex;
    align-items: center;
  }

  .container-title h1 {
    ${BarlowBold(36, 700, "#DE3080")}
    span {
      color: ${Color.button};
    }
  }
  .button-container {
    margin-top: 4rem;
  }
  @media ${Device.xs} {
    padding: 10% 5%;

    .container-title h1 {
      text-align: left;
      margin: 16px;
      font-size: 36px;
    }

    .button-container {
      margin: 2.5rem 0;
    }
  }
`;

const BenefitList = () => {
  const { t } = useTranslation();
  const getBenefits = () => {
    return benefits.map((benefit, idx) => (
      <Col key={idx} xs={24} md={12} className="benefit-col">
        <BenefitCard
          title={t(benefit.title)}
          content={t(benefit.content)}
          icon={benefit.icon}
        />
      </Col>
    ));
  };

  return (
    <Styles>
      <FloatingElements />
      <div className="container-title">
        <h1>{t("aboutUs.button")}</h1>
      </div>
      <Row
        type="flex"
        justify="start"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        {getBenefits()}
      </Row>
      <ButtonContainerHome
        destination={"/joinOurTeam"}
        content={t("aboutUs.button")}
      />
    </Styles>
  );
};

export default BenefitList;
