import benefits from './benefitsES.json';
import values from './valuesES.json';
import aboutUs from './aboutUsES.json';
import whatWeDo from './whatWeDoES.json';
import menu from './menuES.json';
import home from './homeES.json';
import backgrounds from './backgroundES.json';
import contact from './contactES.json';
import joinOurTeam from './joinOurTeamES.json';
import projects from './projectsES.json';
import projectDiscovery from './projectDiscoveryES.json';

export default {
  benefits: benefits,
  values: values,
  aboutUs: aboutUs,
  whatWeDo: whatWeDo,
  home: home,
  menu: menu,
  backgrounds: backgrounds,
  contact: contact,
  joinOurTeam: joinOurTeam,
  projects: projects,
  projectDiscovery: projectDiscovery,
};
