/*----- Core -----*/
import React from 'react'
import styled from 'styled-components'

/*----- Commons -----*/
import Device from '../Device'

/*----- Assets -----*/
import Cube from '../../assets/services/Grupo1626.svg'

const Styles = styled.div`
    position:absolute;
    z-index:3;
    top: -20px;
    left: 7%;
    background-color:transparent;

    img{
        width:40px
    }

    @media ${Device.xs} {
        left:20%;
    }
`

const FloatingCube = () => {
    return (
        <Styles>
            <img src={Cube} alt="Cubo" />
        </Styles>
    )
}

export default FloatingCube