/*----- Core -----*/
import React, { useRef } from "react";
import styled from "styled-components";

/*----- Components -----*/
import { Row, Col } from "antd";

/*----- Commons -----*/
import Device from "../../../commons/Device";
import Barlow from "../../../commons/mixins/fonts/Barlow";

/*----- Assets -----*/
import LocationIcon from "../../../assets/footer/location.svg";

const StyledLocation = styled.div`
  padding-top: 41px;
  width: 100%;

  p {
    ${Barlow(13, 500, "white")};
    padding-bottom: 0px;
  }

  p:hover {
    cursor: pointer;
  }

  img {
    margin-right: 10px;
  }

  .ant-row-flex {
    padding-top: 2px;
  }

  @media ${Device.sm} {
    p {
      text-align: center;
    }
  }

  @media ${Device.xs} {
    padding-top: 129px;
    p {
      text-align: center;
    }
    .ant-row-flex {
      padding-top: 0px;
    }
  }
`;

const LocationFooter = () => {
  const locationRef = useRef();

  const getLocation = () => {
    return "Calle 2 #786 - La Plata, Buenos Aires, Argentina";
  };

  return (
    <StyledLocation className="location-footer">
      <Row type="flex" justify="center" align="middle">
        <Col xs={23} md={12}>
          <p
            className="address"
            onClick={() => {
              locationRef.current.click();
            }}
          >
            <img src={LocationIcon} alt="Location" />
            {getLocation()}
          </p>
        </Col>
      </Row>
      <a
        style={{ display: "none" }}
        role="button"
        href={`https://goo.gl/maps/KRWUtyW35z8T2w8z8`}
        ref={locationRef}
        target="_blank"
        rel="noopener noreferrer"
      >
        &nbsp
      </a>
    </StyledLocation>
  );
};

export default LocationFooter;
