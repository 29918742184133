import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

/*----- Components -----*/
import Header from '../../components/commons/nav/Header';
import ScrollToTop from '../../components/commons/ScrollToTop';
import Footer from '../../components/commons/footer/Footer';

/*----- Modules -----*/
import Home from '../home/Home';
import AboutUs from '../aboutUs/AboutUs';
import WhatWeDo from '../whatWeDo';
import GetInTouch from '../getInTouch/GetInTouch';
import JoinOurTeam from '../joinOurTeam/JoinOurTeam';
import { projects } from '../../utils';
import Projects from '../projects';
import SuccessStory from '../successStories';

/*----- AnimationOnScroll(AOSjs) -----*/
import ProjectDiscovery from '../projectDiscovery/projectDiscovery';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ViewsContainer = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Header />
      <ScrollToTop>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/aboutUs" component={AboutUs} />
          <Route exact path="/whatWeDo" component={WhatWeDo} />
          <Route exact path="/getInTouch" component={GetInTouch} />
          <Route exact path="/joinOurTeam" component={JoinOurTeam} />
          <Route exact path="/projects" component={Projects} />
          <Route exact path="/projectDiscovery" component={ProjectDiscovery} />
          {projects.map((project, index) => (
            <Route
              exact
              path={project.seeMore}
              component={() => <SuccessStory project={project} />}
              key={index}
            />
          ))}
          <Route component={Home} />
        </Switch>
      </ScrollToTop>
      <Footer />
    </>
  );
};

export default ViewsContainer;
