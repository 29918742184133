import React from "react";
import ViewsContainer from "./modules/commons/ViewsContainer";

import "antd/dist/antd.css";
import "react-multi-carousel/lib/styles.css";
import GlobalStyle from "./GlobalStyle";
import "animate.css/animate.min.css";

import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { store, history } from "./store/Store";

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ViewsContainer />
      <GlobalStyle />
    </ConnectedRouter>
  </Provider>
);

export default App;
