/*----- Core -----*/
import React from "react";

import Lightning from "../../../../commons/lightning";
import FloatingImage from "../../../../commons/FloatingImage";
import SoftwareCube from "../../../../assets/services/software-cube.svg";
import BlueCube from "../../../../assets/services/Grupo1625.svg";
import SoftwareDot from "../../../../assets/services/software-dot.svg";
import Diamond from "../../../../assets/services/software-blue-diamond.svg";
import FloatingCube from "../../../../commons/cubes/FloatingCube";

const FloatingElements = () => (
  <>
    <Lightning
      top="1%"
      right="35%"
      width="1px"
      height="100px"
      downColor="#FFFFFF00"
      upColor="#00B6FF"
    />
    <Lightning
      top="5%"
      right="30%"
      width="1px"
      height="150px"
      downColor="#FFFFFF00"
      upColor="#00B6FF"
    />
    <Lightning
      top="50%"
      left="5%"
      width="1px"
      height="150px"
      downColor="#FFFFFF00"
      upColor="#DE3080"
    />
    <Lightning
      bottom="0"
      right="5%"
      width="1px"
      height="125px"
      downColor="#FFFFFF00"
      upColor="#352F80"
    />
    <FloatingImage
      image={SoftwareCube}
      width={"51px"}
      right={"5%"}
      top={"5%"}
    />
    <FloatingImage image={Diamond} width={"20px"} left={"6%"} bottom={"20%"} />
    <FloatingImage image={BlueCube} width={"35px"} left={"3%"} bottom={"12%"} />
    <FloatingImage image={SoftwareDot} width={"6px"} right={"5%"} top={"11%"} />
    <FloatingCube />
  </>
);

export default FloatingElements;
