import React from "react";
import { useTranslation } from "react-i18next";

/*----- Components -----*/
import { Container, DataContainer, StyledRow } from "./styles";
import { Col } from "antd";
import SectionTitle from "../../../../components/text/SectionTitle";
import ButtonContainerHome from "../../../../components/button/ButtonContainerHome";
import ProjectCard from "../../../../components/ProjectCard";
import FloatingElements from "./FloatingElements";
import BackgroundText from "../../../../components/text/BackgroundText";

/*----- Utils -----*/
import { projects } from "../../../../utils";

const List = ({ translation }) => {
  return projects.slice(0, 3).map((item, idx) => (
    <Col xs={24} sm={12} md={8} key={idx}>
      <ProjectCard
        title={translation(item.title)}
        subtitle={translation(item.subtitle)}
        label={translation(item.label)}
        category={translation(item.category)}
        image={item.image}
        seeMore={item.seeMore}
      />
    </Col>
  ));
};

const Projects = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <FloatingElements />
      <BackgroundText
        fontSize="12"
        lineHeight="80%"
        left="47.7%"
        bottom="-5%"
        content={t("backgrounds.successStories")}
        mobileFontSize="25"
        mobileBottom="-5%"
      />
      <DataContainer data-aos="fade-up" data-aos-duration="1500">
        <SectionTitle
          content={<h2 className="section-title">{t("menu.projects")}</h2>}
        />

        <StyledRow type="flex" gutter={[{ xs: 0, md: 24 }]}>
          <List translation={(val) => t(val)} />
        </StyledRow>
        <div>
          <ButtonContainerHome
            destination={"/projects"}
            content={t("menu.seeMore")}
          />
        </div>
      </DataContainer>
    </Container>
  );
};

export default Projects;
