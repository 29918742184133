/*----- Core -----*/
import React from "react";

/*----- Components -----*/
import FloatingElements from "./FloatingElements";
import { StyledServices } from "./styles";
import BackgroundText from "../../../../components/text/BackgroundText";

/*----- Assets -----*/
import CardDeck from "../../../whatWeDo/List/CardDeck";
import { Container } from "../../../whatWeDo/List/styles";

const Services = () => (
  <StyledServices>
    <FloatingElements />
    <BackgroundText content="Software" left="26%" />
    <Container>
      <CardDeck home={true} />
    </Container>
  </StyledServices>
);

export default Services;
