import React from "react";
import GetInTouchHeader from "./section/GetInTouchHeader";
import ContainerForm from "./section/ContainerForm";

const GetInTouch = () => (
  <>
    <GetInTouchHeader />
    <ContainerForm />
  </>
);

export default GetInTouch;
