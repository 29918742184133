/*----- Antd -----*/
import React from "react";
import { notification, Icon } from "antd";
import PropTypes from "prop-types";

const SuccessNotification = (description = "Formulario enviado con éxito") => {
  return notification.success({
    message: <p>{description}</p>,
    duration: 5,
    placement: "topRight",
    icon: <Icon type="smile" />,
  });
};

export default SuccessNotification;

SuccessNotification.propTypes = {
  /* *
   * Notification message
   */
  description: PropTypes.string,
};
