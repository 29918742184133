/*----- Core -----*/
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { connect } from "react-redux";

import { Form, Button } from "antd";

import Color from "../../../commons/Color";
import Device from "../../../commons/Device";
import Barlow from "../../../commons/mixins/fonts/Barlow";

/*----- Components -----*/
import InputItem from "../../../components/formItems/InputItem";
import TextAreaItem from "../../../components/formItems/TextAreaItem";

import errorNotifications from "../../../components/commons/notifications/ErrorNotifications";
import successNotifications from "../../../components/commons/notifications/SuccessNotifications";

const Styles = styled.div`
  .ant-input,
  .ant-input:focus {
    border-top: none;
    border-left: none;
    border-right: none;
  }

  .container-button-form {
    button {
      ${Barlow(14, 700, "white")};
      background: ${Color.button};
      text-transform: capitalize;
      height: 45px;
      border-radius: 3px;
      padding: 0 10px;
      cursor: pointer;
      border-color: initial;
      min-width: 160px;
    }

    button:hover {
      color: white;
      background: ${Color.buttonSecondary};
      transform: scale(1.04);
      box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.2);
    }
  }

  .ant-form-item {
    margin-bottom: 5px;
  }

  @media ${Device.xs} {
    .container-button-form {
      button {
        min-width: 100%;
      }
    }
  }
`;
const GetInTouchForm = (props) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { form } = props;
  const { getFieldDecorator } = form;

  const hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };

  const handleSubmit = (event, form) => {
    event.preventDefault();
    form.validateFields();
    if (hasErrors(form.getFieldsError())) {
      console.log("Error de validación");
    } else {
      let values = form.getFieldsValue();
      values.name +=
        values.company && values.company.length
          ? ` - Empresa: ${values.company} `
          : "";

      sendEmail(form.getFieldsValue(), form);
    }
  };

  const sendEmail = async (formValues, form) => {
    setLoading(true);
    let response = await fetch("https://mensajes.snappler.com/api/messages", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token token=b0b36e74-297a-43a2-82f4-3185680b9564",
      },
      mode: "cors",
      body: JSON.stringify({
        sender: formValues.name,
        sender_email: "snappler-web@snappler.com",
        recipients: ["info@snappler.com"],
        subject: "Consulta desde snappler-web",
        template_name: "mensajes-simple",
        template_params: {
          header: "Nuevo mensaje desde la web",
          body: `<p>Email de ${formValues.email}: ${formValues.message}</p>`,
        },
        reply_to: [formValues.email],
      }),
    });

    if (response.ok) {
      successNotifications(
        "Enviado, te contactamos en cuanto leamos el comentario."
      );
      form.resetFields();
    } else {
      errorNotifications();
    }
    setLoading(false);
  };

  return (
    <Styles className="get-in-touch-form">
      <Form
        onSubmit={(event) => {
          handleSubmit(event, form);
        }}
      >
        <InputItem
          fieldName={"name"}
          getFieldDecorator={getFieldDecorator}
          placeholder={t("contact.form.name")}
          rules={[
            {
              required: true,
              message: t("joinOurTeam.form.error.required"),
            },
          ]}
        />

        <InputItem
          fieldName={"company"}
          getFieldDecorator={getFieldDecorator}
          placeholder={t("contact.form.company")}
        />

        <InputItem
          fieldName={"email"}
          getFieldDecorator={getFieldDecorator}
          placeholder="Email"
          rules={[
            {
              required: true,
              message: t("joinOurTeam.form.error.required"),
            },
            {
              type: "email",
              message: t("joinOurTeam.form.error.invalid"),
            },
          ]}
        />

        <TextAreaItem
          fieldName={"message"}
          placeholder={t("contact.form.msg")}
          getFieldDecorator={getFieldDecorator}
          rules={[
            {
              required: true,
              message: t("joinOurTeam.form.error.required"),
            },
          ]}
        />
        <div className="container-button-form">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="custom-button-ant"
            loading={loading}
          >
            {t("joinOurTeam.form.submit")}
          </Button>
        </div>
      </Form>
    </Styles>
  );
};

function mapStateToProps(state) {
  return {
    // dataCheckout : dataCheckoutTransferSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    //setCheckoutTrasfer: (payload) => setCheckoutTrasfer(dispatch, payload),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: "form" })(GetInTouchForm));
