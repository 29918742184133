/*----- Core -----*/
import React, { useState, useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components";

/*----- Components -----*/
import Drawer from "./Drawer";
import MenuButtom from "./MenuButtom";
import MenuLogo from "./MenuLogo";

/*----- Common -----*/
import Color from "../../../commons/Color";
import Device from "../../../commons/Device";

/*----- Styles -----*/
const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  position: fixed;
  z-index: 1100;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15.8%;
  padding-right: 15.8%;
  top: 0;

  .menu-logo .conainer-logo svg {
    fill: ${(props) => (props.changeLogo ? Color.text : "white")};
  }

  ${(props) =>
    !props.transparent &&
    css`
      background: ${Color.background};
      .menu-logo .conainer-logo svg {
        fill: white;
      }
    `}

  @media ${Device.xs} {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 10px;
    padding-bottom: 10px;

    .menu-buttom {
      width: 100%;
      text-align: right;
    }
  }
`;

const Header = () => {
  const [visible, setVisible] = useState(false);
  const [transparent, setTransparent] = useState(true);
  const location = useLocation();
  const summerTraining = location.pathname === "/summerTraining";
  const changeLogo =
    location.pathname === "/aboutUs" || location.pathname === "/whatWeDo";

  useLayoutEffect(() => {
    setTransparent(!summerTraining);
  }, [summerTraining]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 75) {
        setTransparent(false);
      } else {
        setTransparent(!summerTraining);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [summerTraining]);

  return (
    <StyledHeader transparent={transparent} changeLogo={changeLogo}>
      <MenuLogo />

      <MenuButtom
        onClick={() => {
          setVisible(true);
        }}
        visible={!visible}
      />

      <Drawer
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
      />
    </StyledHeader>
  );
};

export default Header;
