import React from "react";
import { useTranslation } from "react-i18next";
import { itemsProjectDiscovery } from "../../../utils/modality";
import { Row } from "antd";
import {
  Container,
  ItemProjectModality,
  LeftContentRow,
  ModalRow,
  ModalItemsWrapper
} from "./styles";
import { ProjectModalityCard } from ".";
import {
  ModalContainer,
  ModalTitle,
  ModalSubtitle,
  ModalLeftContent,
  ModalRightContent,
} from "./styles";

const ModalItems = () => {
  const { t } = useTranslation();
  return (
    <Row type="flex" gutter={[84, 32]} className="modality-items">
      {itemsProjectDiscovery.map((item, idx) => (
        <ItemProjectModality key={idx} xs={24} md={12} xl={8} xxl={7}>
          <ProjectModalityCard
            title={t(item.title)}
            content={t(item.content)}
          />
        </ItemProjectModality>
      ))}
    </Row>
  );
};

const ProjectDiscoveryModal = () => {
  const { t } = useTranslation();
  return (
    <ModalContainer>
      <ModalTitle>{t("whatWeDo.projectDiscovery.title")}</ModalTitle>
      <ModalRow type="flex">
        <ModalLeftContent md={11} xs={24}>
          <LeftContentRow>
            {t("whatWeDo.projectDiscovery.descriptionLeft1")}
          </LeftContentRow>
          <LeftContentRow>
            {t("whatWeDo.projectDiscovery.descriptionLeft2")}
          </LeftContentRow>
        </ModalLeftContent>
        <ModalRightContent md={10} xs={24}>
          {t("whatWeDo.projectDiscovery.descriptionRight")}
        </ModalRightContent>
      </ModalRow>
      <ModalSubtitle>{t("whatWeDo.projectDiscovery.subtitle")}</ModalSubtitle>
      <ModalItemsWrapper>
        <Container>
          <ModalItems />
        </Container>
      </ModalItemsWrapper>
    </ModalContainer>
  );
};

export default ProjectDiscoveryModal;
