/*----- Core -----*/
import React from "react";
import styled from "styled-components";

/*----- Commons -----*/
import Device from "../../../commons/Device";
import Color from "../../../commons/Color";
import BarlowBold from "../../../commons/mixins/fonts/BarlowBold";
import FloatingElements from "../../../modules/aboutUs/section/FloatingElementsHeader";

/*----- Components -----*/
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";

const Styles = styled.section`
  padding-top: 124px;
  padding-left: 15.8%;
  position: relative;

  h1 {
    ${BarlowBold(40, 700, Color.text)}
    padding-bottom: 0px;
    padding-top: 0px;
  }

  h2 {
    ${BarlowBold(32, 700, Color.text)}
    margin: 0px;
    line-height: 38px;
  }

  span {
    ${BarlowBold(40, 700, "#40ADE3")}
  }

  .first-container {
    padding-top: 100px;
    h2 {
      max-width: 295px;
    }
  }

  .second-container {
    padding-top: 80px;
    h2 {
      max-width: 245px;
    }
  }

  .third-container {
    padding-top: 90px;
  }

  @media ${Device.xs} {
    padding-left: 10%;

    h1 {
      ${BarlowBold(36, 700, Color.text)}
      padding-bottom: 0px;
      padding-top: 0px;
    }

    h2 {
      ${BarlowBold(22, 700, Color.text)}
      margin: 0px;
      line-height: 26px;
    }

    span {
      ${BarlowBold(35, 700, "#40ADE3")}
    }

    .first-container {
      padding-top: 50px;
    }

    .second-container {
      padding-top: 50px;
    }

    .third-container {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }
`;

const AboutUsHeader = () => {
  const { t } = useTranslation();
  return (
    <Styles data-aos="fade-up" data-aos-duration="2000">
      <FloatingElements />
      <div>
        <h1>{t("aboutUs.title")}</h1>
      </div>

      <Row type="flex">
        <Col
          xs={{ span: 25, order: 1 }}
          sm={{ span: 8, order: 1 }}
          md={{ span: 8, order: 1 }}
        >
          <div className="first-container">
            <h2>
              <span>19 </span>
              {t("aboutUs.first")}
            </h2>
          </div>
        </Col>
        <Col
          xs={{ span: 24, order: 2 }}
          sm={{ span: 8, order: 2 }}
          md={{ span: 8, order: 2 }}
        >
          <div className="second-container">
            <h2>
              + <span>100</span> {t("aboutUs.second")}
            </h2>
          </div>
        </Col>
        <Col
          xs={{ span: 24, order: 3 }}
          sm={{ span: 8, order: 3 }}
          md={{ span: 8, order: 3 }}
        >
          <div className="third-container">
            <h2>
              + <span>200</span>
            </h2>
            <h2>{t("aboutUs.third")}</h2>
          </div>
        </Col>
      </Row>
    </Styles>
  );
};

export default AboutUsHeader;
