/*----- Core -----*/
import React, { forwardRef } from 'react';
import styled from 'styled-components';

/*----- Components -----*/
import Device from '../../../commons/Device';
import { Row, Col } from 'antd';
import SectionTest from './SectionTest';
import CvForm from './CvForm';
import FloatingElements from './FloatingElements';

const Styles = styled.div`
  min-height: 57vh;
  padding: 5% 16%;
  position: relative;

  .data-container {
    padding-top: 50px;
  }

  .section-test-description,
  .cv-form {
    padding-left: 20%;
    padding-right: 20%;
  }

  .get-in-touch-description {
    padding-top: 30px;
  }

  @media ${Device.xs} {
    padding: 10% 8%;
    background-size: 400% !important;
    .data-container {
      padding-top: 25px;
      padding-bottom: 25px;
    }

    .section-test-description,
    .cv-form {
      padding-left: 3%;
      padding-right: 3%;
    }

    .get-in-touch-description {
      padding-top: 0px;
      padding-bottom: 80px;
    }
  }
`;

const ContainerForm = (props, ref) => {
  return (
    <Styles>
      <div className="data-container" ref={ref}>
        <FloatingElements />
        <Row type="flex">
          <Col xs={24} md={12}>
            <SectionTest />
          </Col>

          <Col xs={24} md={12}>
            <CvForm />
          </Col>
        </Row>
      </div>
    </Styles>
  );
};
export default forwardRef(ContainerForm);
