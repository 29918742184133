/*----- Core -----*/
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

/*----- Utils -----*/
import ContactInformation from "./ContactInformation";

/*----- Commons -----*/
import Barlow from "../../../commons/mixins/fonts/Barlow";
import Device from "../../../commons/Device";

const Styles = styled.div`
  p {
    ${Barlow(15, 400, "#1E115C")}
  }

  .get-in-touch-contact {
    position: absolute;
    bottom: 35px;
  }

  @media ${Device.xs} {
    .contact-information {
      display: none;
    }
  }
`;

const GetInTouchInformation = () => {
  const { t } = useTranslation();
  return (
    <Styles className="get-in-touch-information">
      <p className="get-in-touch-description">{t("contact.contactMessage")}</p>
      <div className="get-in-touch-contact">
        <ContactInformation />
      </div>
    </Styles>
  );
};

export default GetInTouchInformation;
