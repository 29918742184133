/*----- Core -----*/
import React from "react";

/*----- Components -----*/
import { Row, Col } from "antd";
import ButtonContainerHome from "../../../../components/button/ButtonContainerHome";
import BackgroundText from "../../../../components/text/BackgroundText";

/*----- Assets -----*/
import videoTeam from "../../../../assets/video/video_web_snappler_marzo_2021.mp4";
import { StyledTeam } from "./styles";
import { useTranslation } from "react-i18next";

const Team = () => {
  const { t, i18n } = useTranslation();
  return (
    <StyledTeam>
      <Row type="flex">
        <Col span={24} style={{ position: "relative" }}>
          <div className="video-container">
            <div className="video-overlay" />
            <video
              preload="true"
              autoPlay="autoplay"
              loop="loop"
              webkit-playsinline="true"
              playsInline={true}
              muted="muted"
            >
              <source src={videoTeam} type="video/mp4" id="videoA" />
            </video>
          </div>
          <div className="background-text-container">
            <BackgroundText
              content={t("backgrounds.realTeam")}
              fontSize="22.5"
              wordBreak={i18n.language === "en"}
              fontOpacity="0.25"
              fontColor="white"
              position="relative"
              lineHeight="80%"
              mobileFontSize="22.5"
              mobileLineHeight="85%"
            />
          </div>
          <Row className="data-container">
            <ButtonContainerHome
              destination={"/joinOurTeam"}
              content={t("menu.joinOurTeam")}
            />
          </Row>
        </Col>
      </Row>
    </StyledTeam>
  );
};
export default Team;
