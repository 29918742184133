import { benefits as utils_benefits } from './benefits';
import { values as utils_values } from './values';
import { itemsProjectModality as utils_modality } from './modality';
import { itemsWhatWeDo as utils_whatwedo } from './whatwedo';
import { partnersLinks as utils_partners } from './partners';
import { projects as utils_projects } from './projects';
import { testList as utils_rubytest } from './rubytest';
import { questions as utils_projectdiscovery } from './projectdiscovery';

export const benefits = utils_benefits;
export const values = utils_values;
export const itemsProjectModality = utils_modality;
export const itemsWhatWeDo = utils_whatwedo;
export const partnersLinks = utils_partners;
export const projects = utils_projects;
export const testList = utils_rubytest;
export const projectD = utils_projectdiscovery;

export const isDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

export const numberWithDot = (x) => {
  var parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return parts.join(',');
};
