/*----- Imports -----*/
import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

/*----- Commons-----*/
import Montserrat from "../../commons/mixins/fonts/Montserrat";
import Color from "../../commons/Color";
import Device from "../../commons/Device";

/*----- Styles-----*/
const StyledIconButton = styled.a`
  ${Montserrat(12, 400, `${Color.button}`)}
  padding: 0 8px;

  ${(props) => css`
    svg {
      width: ${props.size};
      height: ${props.size};
      fill: ${Color.button} !important;
      transition: all 0.5s;
      /* transform: scale(0.9); */
    }
  `}
  &:focus {
    outline: none;
  }
  &:hover {
    transition: all 0.5s;

    circle:nth-child(1) {
      fill: #d83180;
      fill-opacity: 1 !important;
      stroke-opacity: 0;
    }
  }
  ${(props) =>
    props.invert &&
    css`
      svg {
        fill: ${Color.buttonSecondary} !important;
      }
      &:hover {
        svg {
          fill: ${Color.button} !important;
        }
      }
    `}

  @media ${Device.xs} {
    svg {
      width: 46px;
      height: 46px;
    }
  }
`;

/*----- Component -----*/
const IconButton = ({ content, destination, icon, size = "25px" }) => (
  <StyledIconButton
    href={destination}
    target="_blank"
    className="icon-button"
    size={size}
  >
    {icon}
    {content}
  </StyledIconButton>
);
export default IconButton;

/*----- PropTypes -----*/
IconButton.propTypes = {
  /* *
   * Destination route path
   */
  destination: PropTypes.string.isRequired,
  /* *
   * Source asset
   */
  icon: PropTypes.object.isRequired,
  /* *
   * Button Size in px
   */
  size: PropTypes.string.isRequired,
  /* *
   * Button text
   */
  content: PropTypes.string,
};
