/*----- Core -----*/
import React from "react";
import Cube from "../../../../assets/services/Grupo1683.svg";
import FloatingImage from "../../../../commons/FloatingImage";

const FloatingElements = () => (
  <FloatingImage
    image={Cube}
    width={"50px"}
    right={"17%"}
    top={"10%"}
    displayMobile
    topMobile={"5%"}
    rightMobile={"15%"}
  />
);

export default FloatingElements;
