import styled from "styled-components";
import Device from "../../commons/Device";
import ImageRectangle from "../../assets/backgrounds/bg-canvas.png";
import projectsBannerBackground from "../../assets/projects/banner/bannerBackgroundDesk.jpg";
import projectsBannerBackgroundMobile from "../../assets/projects/banner/bannerBackgroundMobile.svg";
import frame from "../../assets/projects/banner/Frame.svg";

export const DataContainer = styled.div`
  position: relative;
  padding: 2rem 15%;
  .project-list {
    justify-content: center;
  }
  @media ${Device.md} {
    .project-list {
      justify-content: flex-start;
    }
  }
  @media ${Device.xs} {
    padding: 2rem 5%;
  }
`;

export const ImageContainer = styled.div`
  background-image: url(${ImageRectangle});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  height: 35vh;

  @media ${Device.xs} {
    height: 45vh;
  }
`;

export const TitleContainer = styled.div`
  position: absolute;
  top: 18%;
  padding-left: 15%;

  .custom-title {
    color: #fff !important;
    margin-bottom: 1.5em;
  }

  @media ${Device.xs} {
    width: 100%;
    padding-left: 5%;
  }
`;

export const MainContainer = styled.div`
  overflow-x: hidden;
`;

export const ProjectsBanner = styled.div`
  position: relative;
  .default-button {
    margin: 2rem auto;
    min-width: 300px;
    @media ${Device.xs} {
      height: 45px;
    }
  }

  @media ${Device.md} {
    background-image: url(${projectsBannerBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 128px;
  }

  @media ${Device.xs} {
    background-image: url(${projectsBannerBackgroundMobile});
    height: 192px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const Frame = styled.div`
  background-image: url(${frame});
  width: 160px;
  height: 128px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  right: 0;
`;
