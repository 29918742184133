import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Title from '../../components/text/Title';

const Description = ({ challenge }) => {
  const { t } = useTranslation();
  return (
    <>
      <Title size={2} className="project-description" />
      <p dangerouslySetInnerHTML={{ __html: challenge }} />
    </>
  );
};

export default Description;

Description.propTypes = {
  /* *
   * Specifies story challenge
   */
  challenge: PropTypes.string,
  /* *
   * Specifies story intervention/s
   */
  interventions: PropTypes.array,
};
