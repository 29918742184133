import React from 'react';
import Device from '../../commons/Device';
import styled, { css } from 'styled-components';
import BackgroundBarlowBold from '../../commons/mixins/fonts/BackgroundBarlowBold';

const StyledBackgroundText = styled.h1`
  ${BackgroundBarlowBold(14.5, 800, '#285DDB', 0.06)}
  position: absolute;
  line-height: 300px;
  word-break: break-all;
  margin-bottom: none;

  @media ${Device.xs} {
    ${BackgroundBarlowBold(45)}
    line-height: 101px;
    left: 0%;
    margin-top: none;
  }

  ${(props) =>
    css`
      ${BackgroundBarlowBold(
        props.fontSize,
        props.fontWeight,
        props.fontColor,
        props.fontOpacity
      )}
      position: ${props.position};
      line-height: ${props.lineHeight};
      left: ${props.left};
      right: ${props.right};
      bottom: ${props.bottom};
      top: ${props.top};
      margin: ${props.margin};
      margin-bottom: ${props.marginBottom};
      transform: ${props.transform};
      ${props.wordBreak &&
      css`
        word-break: initial;
      `};

      display: block;

      @media ${Device.xs} {
        ${BackgroundBarlowBold(props.mobileFontSize)}
        line-height: ${props.mobileLineHeight};
        left: ${props.mobileLeft};
        bottom: ${props.mobileBottom};
        margin-top: ${props.mobileMarginTop};
        display: ${props.isVisibleInMobile ? 'block' : 'none'};
      }

      @media ${Device.lg} {
        font-size: ${props.lgFontSize};
      }

      @media ${Device.xl} {
        font-size: ${props.fontSize};
      }
    `}
`;

const BackgroundText = ({
  content,
  position,
  fontSize,
  fontWeight,
  fontColor,
  fontOpacity,
  left,
  right,
  bottom,
  top,
  marginBottom,
  lineHeight,
  mobileFontSize,
  mobileLineHeight,
  mobileLeft,
  mobileBottom,
  mobileMarginTop,
  wordBreak,
  margin,
  isVisibleInMobile = true,
  transform,
  lgFontSize,
}) => (
  <StyledBackgroundText
    lineHeight={lineHeight}
    fontSize={fontSize}
    fontWeight={fontWeight}
    fontColor={fontColor}
    fontOpacity={fontOpacity}
    position={position}
    left={left}
    right={right}
    bottom={bottom}
    top={top}
    marginBottom={marginBottom}
    mobileFontSize={mobileFontSize}
    mobileLineHeight={mobileLineHeight}
    mobileLeft={mobileLeft}
    mobileBottom={mobileBottom}
    mobileMarginTop={mobileMarginTop}
    wordBreak={wordBreak}
    margin={margin}
    isVisibleInMobile={isVisibleInMobile}
    transform={transform}
    lgFontSize={lgFontSize}
  >
    {content}
  </StyledBackgroundText>
);

export default BackgroundText;
