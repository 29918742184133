import styled from "styled-components";

import Device from "../../../commons/Device";
import Color from "../../../commons/Color";

export const Container = styled.section`
  padding-bottom: 2rem;
  padding-top: 4rem;
  position: relative;
  .ant-row-flex {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .col-flex-center {
    display: flex;
    justify-content: center;
  }

  .data-container {
    padding-top: 8%;
    padding-left: 15%;
    padding-right: 15%;
  }

  .data-container > .custom-title {
    color: ${Color.text}!important;
    font-size: 42px;
  }

  .svg-container {
    position: absolute;
    z-index: -1;
  }

  .svg-container {
    width: 97%;
  }

  .text-software {
    width: 100%;
    position: absolute;
    padding: 0;
    right: 0;
    top: 2rem;
  }
  .text-experiences {
    position: relative;
  }

  @media ${Device.xs} {
    .data-container {
      padding-left: 5%;
      padding-right: 5%;
    }
  }
  @media ${Device.md} {
    .text-software {
      padding-right: 10%;
      width: 70%;
      top: 5rem;
    }
    .text-experiences-home {
      width: 65%;
      padding-left: 2rem;
      position: absolute;
      left: 1rem;
    }
    .text-experiences {
      width: 65%;
      position: relative;
    }
  }
`;
