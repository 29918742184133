/*----- Core -----*/
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

/*----- Commons -----*/
import Device from "../../../commons/Device";
import BarlowBold from "../../../commons/mixins/fonts/BarlowBold";

const StyledOptionDrawer = styled(Link)`
  text-decoration: none;
  ${BarlowBold(32, 900, "white")};

  &:hover {
    text-decoration: none;
    color: white;
  }
  p:hover {
    .text-title {
      /* text-shadow: #DA3080 -2px -1px; */
    }
  }
  .text-letter {
    color: #e83e8c;
  }

  &:focus {
    outline: none;
  }

  p {
    position: relative;
    margin-bottom: 20px;
    width: fit-content;
  }

  p:after {
    content: "";
    width: 0;
    height: 20px;
    bottom: 3px;
    display: block;
    z-index: -1;
    position: absolute;
    background: #da3080 0 30%;
    color: white;
    visibility: hidden;
    opacity: 0;
    text-shadow: 10px 10px 10px #da3080;
    transition: all 0.32s ease-in-out;
  }

  p:hover:after {
    visibility: visible;
    opacity: 1;
    width: 105%;
    z-index: -1;
  }

  @media ${Device.xs} {
    ${BarlowBold(20, 900, "white")};

    p:after {
      background: #da3080 0 90%;
      height: 10px;
    }

    p:hover:after {
      width: 90%;
    }
  }
`;

const OptionDrawer = ({ content, destination, onSelect }) => (
  <StyledOptionDrawer to={destination} onClick={onSelect}>
    <p>
      <span className="text-letter">_</span>
      <span className="text-title">{content}</span>
    </p>
  </StyledOptionDrawer>
);

export default OptionDrawer;

/*----- PropTypes -----*/
OptionDrawer.propTypes = {
  /* *
   * Set Title
   */
  content: PropTypes.string.isRequired,
  /* *
   * Destination route path
   */
  destination: PropTypes.string.isRequired,
  /* *
   * Callback handler when selected
   */
  onSelect: PropTypes.func.isRequired,
};
