/*----- Core -----*/
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

/*----- Components -----*/
import Title from "../../../components/text/Title";
import Color from "../../../commons/Color";

const Styles = styled.div`
  display: flex;
  flex-direction: row;
  img {
    width: 45px;
    height: 45px;
  }
  .title-container {
    .custom-title {
      color: ${Color.text};
      margin: 0px;
      margin-left: 10px;
    }

    .custom-title:last-child {
      color: #da3080;
    }
  }

  .download-text {
    color: #da3080;
  }

  .link-download {
    display: none;
  }
  .download-container {
    display: inline-block;
  }

  .download-container:hover {
    cursor: pointer;
  }
`;

const ItemTest = ({ title, url, download }) => {
  const refDownload = useRef(null);
  const { t } = useTranslation();
  return (
    <Styles>
      <img src={url} alt={"title"} />
      <div className="title-container">
        <Title content={title} size={4} />
        <div
          className="download-container"
          onClick={() => {
            refDownload.current.click();
          }}
        >
          <Title content={t("joinOurTeam.download")} size={5} />
          <a
            className="link-download"
            role="button"
            href={`${download}`}
            download="aplicacion-front-back"
            ref={refDownload}
          >
            &nbsp
          </a>
        </div>
      </div>
    </Styles>
  );
};

export default ItemTest;
