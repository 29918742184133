/*----- Core -----*/
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
/*----- Commons -----*/
import Color from '../../../commons/Color';
import Device from '../../../commons/Device';
import Title from '../../../components/text/Title';
import BarlowLight from '../../../commons/mixins/fonts/BarlowLight';
import { Row, Col } from 'antd';
import SeeMore from '../../../components/commons/SeeMore';

/*----- Utils -----*/
import { isDevice } from '../../../utils';

const Styles = styled.div`
  padding: 32px;
  border: 2px solid ${Color.button};
  border-radius: 5px;
  max-width: 400px;
  min-height: 380px;
  height: 100%;

  .card-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .card-container_image {
    position: absolute;
    left: 0;
    top: 0;
    svg {
      opacity: 0.2;
      height: 8vh;
      fill: ${Color.button};
    }
  }

  .card_description p {
    color: ${Color.text};
  }

  .card-container_title {
    min-height: 100px;
  }

  .card-container_title > .custom-title {
    font-size: 22px;
    color: ${Color.button};
    margin-left: 27.5%;
    margin-top: 1.65rem;
  }

  .card-container_description {
    width: 98%;
    min-height: 15vh;
    flex-grow: 0;
    p {
      ${BarlowLight(16, 300, Color.text)}
    }
  }

  .card-container_seeMore {
    min-height: 5vh;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }

  .card-container_tags {
    width: 98%;
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    .card-tag {
      ${BarlowLight(12, 300, Color.text)}
      font-style: italic;
      padding: 3px;
      border-color: #352f80;
      border-style: solid;
      border-width: 1px;
      border-radius: 5px;
      padding: 5px;
      margin: 2px;
    }
  }
  .see-more {
    font-size: 1rem;
  }

  .card-rows_tags svg {
    fill: ${Color.button};
  }

  ${(props) =>
    !props.isDevice &&
    !props.inHome &&
    css`
      &:hover {
        background-color: #002944;
        border-color: #002944;
        cursor: pointer;

        .card-container_title,
        .custom-title {
          color: #40ade3;
          background-color: #002944;
        }

        .card-container_description p {
          color: white;
          background-color: #002944;
        }

        .card-container_image svg {
          fill: #285ddb;
        }

        .card-tag {
          background-color: #002944;
          color: white;
          border-color: white;
        }

        .see-more {
          background-color: #002944;
        }
      }
    `}

  @media ${Device.xs} {
    .card-rows_tags {
      align-items: flex-end !important;
    }
    .card-container_description {
      p {
        font-size: 16px;
      }
    }
    .card-container_title .custom-title {
      font-size: 22px;
      margin-top: 1rem;
      margin-left: 3.5rem;
    }
  }

  @media ${Device.sm} {
    .card-container_description {
      min-height: 28vh;
    }
  }

  @media ${Device.md} {
    .card-container_description {
      min-height: 26vh;
      p {
        ${BarlowLight(14, 300, Color.text)}
      }
    }
  }

  @media ${Device.lg} {
    .card-container_description {
      p {
        ${BarlowLight(15, 300, Color.text)}
      }
    }
    .card-container_title .custom-title {
      font-size: 19px;
      margin-top: 1rem;
      margin-left: 1rem;
    }
  }

  @media ${Device.xl} {
    .card-container_description {
      p {
        ${BarlowLight(17, 300, Color.text)}
      }
      min-height: 18vh;
    }
    .card-container_title .custom-title {
      font-size: 22px;
      margin-top: 1.5rem;
      margin-left: 27.5%;
    }
  }
`;

const WhatWeDoCard = ({ title, description, tags, number, home }) => {
  const getTags = () => {
    return tags.map((tag, idx) => (
      <Col key={idx} style={{ display: 'flex' }}>
        <span className="card-tag">{tag}</span>
      </Col>
    ));
  };

  return (
    <Styles isDevice={isDevice()} inHome={home}>
      <div className="card-container">
        <div className="card-container_image">{number}</div>

        <div className="card-container_title">
          <Title content={title} size={2} />
        </div>

        <div className="card-container_description">
          <p>{description}</p>
        </div>
        {!home && (
          <div className="card-container_seeMore">
            <SeeMore />
          </div>
        )}
        <div className="card-container_tags">
          <Row type="flex" align="middle" className="card-rows_tags">
            <Col span={24}>
              <Row type="flex" justify="start">
                {getTags()}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </Styles>
  );
};

export default WhatWeDoCard;

WhatWeDoCard.propTypes = {
  /* *
   * Card Title
   */
  title: PropTypes.string,
  /* *
   * Card Description
   */
  description: PropTypes.string,
  /* *
   * card tags
   */
  tags: PropTypes.array,
  /* *
   * Card number
   */
  number: PropTypes.object,
  /* *
   * Home location flag
   */
  home: PropTypes.bool,
};
