/*----- Core -----*/
import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

/*----- Components -----*/
import { Row, Col } from "antd";
import Isologotype from "./Isologotype";
import LocationFooter from "./LocationFooter";
import SocialNetworks from "./SocialNetworks";

/*----- Commons -----*/
import Device from "../../../commons/Device";

import FooterBackground from "../../../assets/footer/footer-background.svg";
import FooterBackgroundMobile from "../../../assets/footer/footer-background-mobile.svg";
import WhiteButton from "../../button/WhiteButton";

const StyledFooter = styled.footer`
  position: relative;
  & > div {
    height: 90%;
  }
  @media ${Device.md} {
    background-image: url(${FooterBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 128px;
    & > div {
      height: 100%;
    }
  }

  @media ${Device.xs} {
    background-image: url(${FooterBackgroundMobile});
    height: 607px;

    .ant-row-flex-end {
    }
  }
`;

const Footer = () => {
  const { t } = useTranslation();
  return (
    <StyledFooter className="footer">
      <Row type="flex" justify="center">
        <Col
          xs={{ span: 25, order: 1 }}
          sm={{ span: 24, order: 1 }}
          md={{ span: 6, order: 2 }}
        >
          <WhiteButton
            destination={"/getInTouch"}
            content={t("menu.contact")}
            color={"white"}
          />
        </Col>

        <Col
          xs={{ span: 24, order: 3 }}
          sm={{ span: 24, order: 3 }}
          md={{ span: 12, order: 3 }}
        >
          <LocationFooter />
          <Isologotype />
        </Col>
        <SocialNetworks />
      </Row>
    </StyledFooter>
  );
};

export default forwardRef(Footer);
