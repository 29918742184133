/*----- Core -----*/
import React from "react";
import styled from "styled-components";

/*----- Commons -----*/
import Device from "../../../commons/Device";
import Barlow from "../../../commons/mixins/fonts/Barlow";

/*----- Components -----*/
// import {Row, Col } from 'antd'

/*----- Assets -----*/
import Isologo from "../../../assets/footer/isotipo-snappler.png";

const StyledIsologo = styled.div`
  span {
    ${Barlow(13, 500, "white")}
  }
  img {
    width: 20px;
    margin-right: 5.98px;
  }

  @media ${Device.sm} {
    text-align: center;
  }

  @media ${Device.xs} {
    text-align: center;
    padding-top: 20px;
  }
`;

const Isologotype = () => {
  const year = new Date().getFullYear();
  return (
    <StyledIsologo className="isologotype">
      <img src={Isologo} alt="Snappler isologo" />
      <span>
        Snappler <small>&copy;</small> {year}{" "}
      </span>
    </StyledIsologo>
  );
};

export default Isologotype;
