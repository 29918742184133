/*----- Core -----*/
import React from 'react';
import styled from 'styled-components';

/*----- Commons -----*/
import Device from '../Device'

const Styles = styled.div`
	position:absolute;
	z-index: ${props => props.zIndex};
	top: ${ props => props.top };
	left: ${ props => props.left };
	bottom: ${ props => props.bottom };
	right: ${ props => props.right };
	background-color:transparent;
	display: ${ props => props.displayDesktop ? 'block' : 'none' };
	
	img{
		width: ${ props => props.width };
	}

	@media ${Device.xs} {
		display: ${ props => props.displayMobile ? 'block' : 'none' };
		top: ${ props => props.topMobile };
		left: ${ props => props.leftMobile };
		bottom: ${ props => props.bottomMobile };
		right: ${ props => props.rightMobile };

		img{
			width: ${ props => props.widthMobile || props.width };
		}
	}
`

const FloatingImage = ({ image, className, displayMobile, displayDesktop, ...props}) => (
	<Styles className={className} {...props} displayMobile={displayMobile} displayDesktop={displayDesktop}>
		<img src={image} alt="Diamond" />
	</Styles>
)

export default FloatingImage

FloatingImage.defaultProps = {
	top: 'auto',
	left: 'auto',
	right: 'auto',
	bottom: 'auto',
	displayMobile: false,
	topMobile: 'auto',
	bottomMobile: 'auto',
	leftMobile: 'auto',
	rightMobile: 'auto',
	zIndex: 3,
	displayDesktop: true
}