/*----- Core -----*/
import React, { useState, useEffect } from "react";
import styled from "styled-components";

/*----- Team -----*/
import { team } from "../../../team";
import GalleryTeam from "./GalleryTeam";

/*----- Components -----*/
import BarlowExtraBold from "../../../commons/mixins/fonts/BarlowExtraBold";
import Color from "../../../commons/Color";
import Device from "../../../commons/Device";
import { useTranslation } from "react-i18next";

const Styles = styled.section`
  padding-top: 7%;
  min-height: 98vh;

  .data-container {
    padding-left: 15%;
    padding-right: 15%;
    margin-bottom: 10%;

    h1 {
      ${BarlowExtraBold(40, 700, Color.button)}
      padding-bottom: 5%;
    }
  }

  @media ${Device.xs} {
    .data-container {
      padding-left: 5%;
      padding-right: 5%;
      h1 {
        font-size: 36px;
        padding-left: 5%;
      }
    }
  }
`;

const AboutUsTeam = () => {
  const [imagesGallery, setImagesGallery] = useState([]);
  const { t } = useTranslation();

  const images = [];
  const size = 15;

  const getNumberRandom = (count) => {
    return Math.floor(Math.random() * count);
  };

  const isInImagesArray = (arr, newMember) =>
    arr.find((member) => member.key === newMember.key);

  useEffect(() => {
    for (let i = 0; i < size; i++) {
      let newMember = team[getNumberRandom(team.length)];
      while (isInImagesArray(images, newMember)) {
        newMember = team[getNumberRandom(team.length)];
      }
      images.push(newMember);
    }

    setImagesGallery(images);
  }, []);

  useEffect(() => {
    setInterval(() => {
      let newMember = team[getNumberRandom(team.length)];

      while (isInImagesArray(images, newMember)) {
        newMember = team[getNumberRandom(team.length)];
      }

      images[getNumberRandom(size)] = newMember;
      setImagesGallery(images.slice());
    }, 2000);
  }, []);

  return (
    <Styles>
      <div className="data-container">
        <h1 className="pink">{t("aboutUs.ourTeam")}</h1>
        <GalleryTeam data={imagesGallery} />
      </div>
    </Styles>
  );
};

export default AboutUsTeam;
