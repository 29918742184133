import styled from "styled-components";
import Device from "../../../commons/Device";
import ImageRectangle from "../../../assets/backgrounds/bg-canvas.png";

export const ImageContainer = styled.div`
  background-image: url(${ImageRectangle});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  height: 35vh;

  @media ${Device.xs} {
    height: 45vh;
  }
`;

export const TitleContainer = styled.div`
  position: absolute;
  top: 18%;
  padding-left: 15%;

  .custom-title {
    color: #fff !important;
    margin-bottom: 1.5em;
  }

  @media ${Device.xs} {
    width: 100%;
    padding-left: 5%;
  }
`;

export const MainContainer = styled.div`
  overflow-x: hidden;
`;
