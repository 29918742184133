import VanzatoAgustin from './assets/aboutus/team/2021/vanzato-agustin.jpg';
import ChirinoAgustina from './assets/aboutus/team/2021/chirino-agustina.jpg';
import CiancioBenjamin from './assets/aboutus/team/2021/ciancio-benjamin.jpg';
import DeLaPennaGiuliano from './assets/aboutus/team/2021/de-la-penna-giuliano.jpg';
import GrimbergDamian from './assets/aboutus/team/2021/grimberg-damian.jpg';
import MarchiVeronica from './assets/aboutus/team/2021/marchi-veronica.jpg';
import FernandesEmir from './assets/aboutus/team/2021/fernandes-emir.jpg';
import BoucheFederico from './assets/aboutus/team/2021/bouche-federico.jpg';
import SuarezEsteban from './assets/aboutus/team/2021/suarez-esteban.jpg';
import BilbaoGonzalo from './assets/aboutus/team/2021/bilbao-gonzalo.jpg';
import CabotJuan from './assets/aboutus/team/2021/cabot-juan.jpg';
import CremaschiAgustin from './assets/aboutus/team/2021/cremaschi-agustin.jpg';
import DelleDonneMatias from './assets/aboutus/team/2021/delle-donne-matias.jpg';
import FeregottiJulian from './assets/aboutus/team/2021/feregotti-julian.jpg';
import GalvanPatricio from './assets/aboutus/team/2021/galvan-patricio.jpg';
import AlinaMartinez from './assets/aboutus/team/2021/martinez-alina.png';
import GonikJulia from './assets/aboutus/team/2021/gonik-julia.jpg';
import ZagagliaFernando from './assets/aboutus/team/2021/zagaglia-fernando.jpg';
import AtampizMiguel from './assets/aboutus/team/2021/atampiz-miguel.jpg';
import FloresIgnacio from './assets/aboutus/team/2021/ignacio-flores.jpg';
import CominottiAlejandro from './assets/aboutus/team/2021/alejandro-cominotti.jpg';
import RodriguezGabriel from './assets/aboutus/team/2021/rodriguez-gabriel.jpg';
import GotelliAgustina from './assets/aboutus/team/2021/agustina-diaz-gotelli.jpg';
import SustachVanesa from './assets/aboutus/team/2021/sustach-vanesa.jpg';
import DelGrossoAugusto from './assets/aboutus/team/2021/augusto-del-grosso.jpg';
import campetellaCarla from './assets/aboutus/team/2021/carla-campetella.jpg';
import FernandezFederico from './assets/aboutus/team/2021/federico-fernandez.jpg';
import CiancioGaston from './assets/aboutus/team/2021/gaston-ciancio.jpg';
import AlvarezIgnacio from './assets/aboutus/team/2021/ignacio-alvarez.jpg';
import RocaJessica from './assets/aboutus/team/2021/jessica-roca.jpg';
import CasasJosefina from './assets/aboutus/team/2021/josefina-casas.jpg';
import OttoneJuanManuel from './assets/aboutus/team/2021/juan-manuel-ottone.jpg';
import MastronardiLautaro from './assets/aboutus/team/2021/lautaro-mastronardi.jpg';
import GrimbergLeiza from './assets/aboutus/team/2021/leiza-grimberg.jpg';
import AllegrettiMailen from './assets/aboutus/team/2021/mailen-allegretti.jpg';
import RuschMartina from './assets/aboutus/team/2021/martina-rusch.jpg';
import ArceMicaela from './assets/aboutus/team/2021/micaela-arce.jpg';
import GarciaNicolas from './assets/aboutus/team/2021/nicolas-garcia.jpg';
import IrazustaSebastian from './assets/aboutus/team/2021/sebastian-irazusta.jpg';
import DinardoSedeni from './assets/aboutus/team/2021/sedeni-dinardo.jpg';
import RacitiSofia from './assets/aboutus/team/2021/sofia-raciti.jpg';

export const team = [
  {
    key: 1,
    name: 'AGUSTÍN VANZATO',
    rol: 'Software developer',
    image: VanzatoAgustin,
    alt: 'Agustín Vanzato - Software developer',
  },
  {
    key: 2,
    name: 'AGUSTINA CHIRINO',
    rol: 'People Care',
    image: ChirinoAgustina,
    alt: 'Agustina Chirino - People Care',
  },
  {
    key: 3,
    name: 'BENJAMIN CIANCIO',
    rol: 'Software developer',
    image: CiancioBenjamin,
    alt: 'Benjamin Ciancio - Software developer',
  },
  {
    key: 4,
    name: 'GIULIANO DE LA PENNA',
    rol: 'Software developer',
    image: DeLaPennaGiuliano,
    alt: 'Giuliano De La Penna - Software developer',
  },
  {
    key: 5,
    name: 'GABRIEL RODRIGUEZ',
    rol: 'Software developer',
    image: RodriguezGabriel,
    alt: 'Gabriel Rodriguez - Software developer',
  },
  {
    key: 6,
    name: 'DAMIÁN GRIMBERG',
    rol: 'CFO & CEO',
    image: GrimbergDamian,
    alt: 'Damián Grimberg - CFO & CEO',
  },
  {
    key: 7,
    name: 'MIGUEL ATAMPIZ',
    rol: 'Software developer',
    image: AtampizMiguel,
    alt: 'Miguel Atampiz - Software developer',
  },
  {
    key: 8,
    name: 'VERÓNICA MARCHI',
    rol: 'Administrative and People Manager',
    image: MarchiVeronica,
    alt: 'Verónica Marchi- Administrative and People Manager',
  },
  {
    key: 9,
    name: 'EMIR FERNANDES DA PONTE',
    rol: 'Software developer',
    image: FernandesEmir,
    alt: 'Emir Fernandes da Ponte - Software developer',
  },
  {
    key: 10,
    name: 'FEDERICO BOUCHE',
    rol: 'Software developer',
    image: BoucheFederico,
    alt: 'Federico Bouche - Software developer',
  },
  {
    key: 11,
    name: 'ALEJANDRO COMINOTTI',
    rol: 'Software developer',
    image: CominottiAlejandro,
    alt: 'Alejandro Cominotti - Software developer',
  },
  {
    key: 12,
    name: 'ESTEBAN SUAREZ',
    rol: 'Project Manager',
    image: SuarezEsteban,
    alt: 'Esteban Suarez - Project Manager',
  },

  {
    key: 13,
    name: 'JUAN CABOT',
    rol: 'Software developer',
    image: CabotJuan,
    alt: 'Juan Cabot - Software developer',
  },
  {
    key: 14,
    name: 'AGUSTÍN CREMASCHI',
    rol: 'Software developer',
    image: CremaschiAgustin,
    alt: 'Agustín Cremaschi - Software developer',
  },
  {
    key: 15,
    name: 'MATIAS DELLE DONNE',
    rol: 'Software developer',
    image: DelleDonneMatias,
    alt: 'Matias Delle Donne - Software developer',
  },
  {
    key: 16,
    name: 'JULIÁN FEREGOTTI',
    rol: 'Software developer',
    image: FeregottiJulian,
    alt: 'Julian Feregotti - Software developer',
  },
  {
    key: 17,
    name: 'PATRICIO GALVAN',
    rol: 'Software developer',
    image: GalvanPatricio,
    alt: 'PATRICIO GALVAN - Software developer',
  },
  {
    key: 18,
    name: 'ALINA MARTINEZ',
    rol: 'Project Manager',
    image: AlinaMartinez,
    alt: 'Alina Martinez - Project Manager',
  },
  {
    key: 19,
    name: 'JULIA GONIK',
    rol: 'Software developer',
    image: GonikJulia,
    alt: 'Julia Gonik - Software developer',
  },
  {
    key: 20,
    name: 'GONZALO BILBAO',
    rol: 'Software developer',
    image: BilbaoGonzalo,
    alt: 'Gonzalo Bilbao - Software developer',
  },

  {
    key: 21,
    name: 'IGNACIO FLORES',
    rol: 'Software developer',
    image: FloresIgnacio,
    alt: 'Ignacio Flores - Software developer',
  },
  {
    key: 22,
    name: 'AGUSTINA DIAZ GOTELLI',
    rol: 'Administrativo',
    image: GotelliAgustina,
    alt: 'Agustina Díaz Gotelli - Administrativo',
  },
  {
    key: 23,
    name: 'FERNANDO ZAGAGLIA',
    rol: 'Product Owner-Teytu',
    image: ZagagliaFernando,
    alt: 'Fernando Zagaglia - Product Owner-Teytu',
  },
  {
    key: 24,
    name: 'VANESA SUSTACH',
    rol: 'Project Manager',
    image: SustachVanesa,
    alt: 'Vanesa Sustach - Project Manager',
  },
  {
    key: 25,
    name: 'AUGUSTO DEL GROSSO',
    rol: 'Software developer',
    image: DelGrossoAugusto,
    alt: 'Augusto Del Grosso - Software developer',
  },
  {
    key: 26,
    name: 'CARLA CAMPETELLA',
    rol: 'Software developer',
    image: campetellaCarla,
    alt: 'Carla Campetella - Software developer',
  },
  {
    key: 27,
    name: 'FEDERICO FERNANDEZ',
    rol: 'Software developer',
    image: FernandezFederico,
    alt: 'Federico Fernendez - Software developer',
  },
  {
    key: 28,
    name: 'GASTON CIANCIO',
    rol: 'Software developer',
    image: CiancioGaston,
    alt: 'Gaston Ciancio - Software developer',
  },
  {
    key: 29,
    name: 'IGNACIO ALVAREZ',
    rol: 'Software developer',
    image: AlvarezIgnacio,
    alt: 'Ignacio Alvarez - Software developer',
  },
  {
    key: 30,
    name: 'JESSICA ROCA',
    rol: 'Software developer',
    image: RocaJessica,
    alt: 'Jessica ROca - Software developer',
  },
  {
    key: 31,
    name: 'JOSEFINA CASAS',
    rol: 'People Assistant',
    image: CasasJosefina,
    alt: 'Josefina Casas - People Assistant',
  },
  {
    key: 32,
    name: 'JUAN MANUEL OTTONE',
    rol: 'Software developer',
    image: OttoneJuanManuel,
    alt: 'Juan Manuel Ottone - Software developer',
  },
  {
    key: 33,
    name: 'LAUTARO MASTRONARDI',
    rol: 'Software developer',
    image: MastronardiLautaro,
    alt: 'Lautaro Mastronardi - Software developer',
  },
  {
    key: 34,
    name: 'LEIZA GRIMBERG',
    rol: 'QA Tester',
    image: GrimbergLeiza,
    alt: 'Leiza Grimberg - QA Tester',
  },
  {
    key: 35,
    name: 'MAILÉN ALLEGRETTI',
    rol: 'UX/UI Designer',
    image: AllegrettiMailen,
    alt: 'Mailén Allegretti - UX/UI Designer',
  },
  {
    key: 36,
    name: 'MARTINA RUSCH',
    rol: 'UX/UI Designer',
    image: RuschMartina,
    alt: 'Martina Rusch - UX/UI Designer',
  },
  {
    key: 37,
    name: 'MICAELA ARCE',
    rol: 'Project Manager',
    image: ArceMicaela,
    alt: 'Micaela Arce - Project Manager',
  },
  {
    key: 38,
    name: 'NICOLAS GARCÍA',
    rol: 'Software developer',
    image: GarciaNicolas,
    alt: 'Nicolás García - Software developer',
  },
  {
    key: 39,
    name: 'SEBASTIÁN IRAZUSTA',
    rol: 'Project Manager',
    image: IrazustaSebastian,
    alt: 'Sebastián Irazusta - Project Manager',
  },
  {
    key: 40,
    name: 'SEDENI DINARDO',
    rol: 'Social Media',
    image: DinardoSedeni,
    alt: 'Sedeni Dinardo - Social Media',
  },
  {
    key: 41,
    name: 'SOFIA RACITI',
    rol: 'Software developer',
    image: RacitiSofia,
    alt: 'Sofia Raciti - Software developer',
  },
];
