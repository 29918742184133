import React from "react";
import CubeSoftware from "../../../assets/services/Grupo1853.svg";
import DiamondBlue from "../../../assets/services/software-blue-diamond.svg";
import FloatingImage from "../../../commons/FloatingImage";
import DiamondLightBlue from "../../../assets/services/software-lightblue-diamond.svg";

const FloatingElementsProjectModality = () => (
  <>
    <FloatingImage
      image={DiamondLightBlue}
      displayMobile
      displayDesktop={false}
      widthMobile={"25px"}
      bottomMobile={"20%"}
      rightMobile={"50%"}
    />
    <FloatingImage
      image={CubeSoftware}
      displayMobile
      displayDesktop={false}
      widthMobile={"25px"}
      topMobile={"55%"}
      rightMobile={"50%"}
    />
    <FloatingImage
      image={DiamondBlue}
      displayMobile
      displayDesktop={false}
      widthMobile={"25px"}
      topMobile={"55%"}
      rightMobile={"40%"}
    />
  </>
);

export default FloatingElementsProjectModality;
