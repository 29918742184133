export const itemsProjectModality = [
  {
    title: "whatWeDo.howWeDo.third.title",
    content: "whatWeDo.howWeDo.third.description",
    button: "whatWeDo.howWeDo.third.button",
  },
  {
    title: "whatWeDo.howWeDo.first.title",
    content: "whatWeDo.howWeDo.first.description",
  },
  {
    title: "whatWeDo.howWeDo.second.title",
    content: "whatWeDo.howWeDo.second.description",
  },
];

export const itemsProjectDiscovery = [
  {
    title: "whatWeDo.projectDiscovery.first.title",
    content: "whatWeDo.projectDiscovery.first.description",
  },
  {
    title: "whatWeDo.projectDiscovery.second.title",
    content: "whatWeDo.projectDiscovery.second.description",
  },
  {
    title: "whatWeDo.projectDiscovery.third.title",
    content: "whatWeDo.projectDiscovery.third.description",
  },
];
