/*----- Core -----*/
import React from "react";
import FloatingImage from "../../../commons/FloatingImage";
import Lightning from "../../../commons/lightning";
import ValuesCube from "../../../assets/services/cube-about-us.svg";
import BenefitsCube from "../../../assets/services/Grupo1625.svg";

const FloatingElements = () => (
  <>
    <Lightning
      top="0%"
      left="5%"
      width="1px"
      height="150px"
      downColor="#FFFFFF00"
      upColor="#DE3080"
    />
    <Lightning
      top="5%"
      right="22%"
      width="1px"
      height="150px"
      downColor="#FFFFFF00"
      upColor="#40ADE3"
    />
    <Lightning
      top="0%"
      right="15%"
      width="1px"
      height="120px"
      downColor="#FFFFFF00"
      upColor="#352F80"
    />

    <Lightning
      bottom="60%"
      right="5%"
      width="1px"
      height="125px"
      downColor="#FFFFFF00"
      upColor="#40ADE3"
    />
    <Lightning
      bottom="25%"
      right="50%"
      width="1px"
      height="125px"
      downColor="#FFFFFF00"
      upColor="#40ADE3"
    />
    <Lightning
      bottom="2.5%"
      right="20%"
      width="1px"
      height="150px"
      downColor="#FFFFFF00"
      upColor="#DE3080"
    />
    <FloatingImage
      image={BenefitsCube}
      width={"51px"}
      right={"45%"}
      top={"5%"}
    />
    <FloatingImage
      image={ValuesCube}
      width={"51px"}
      right={"35%"}
      bottom={"10%"}
    />
  </>
);

export default FloatingElements;
