export const Drawers = [
  {
    key: 1,
    content: 'menu.home',
    destination: '/',
  },
  {
    key: 2,
    content: 'menu.whatWeDo',
    destination: '/whatWeDo',
  },
  {
    key: 3,
    content: 'menu.aboutUs',
    destination: '/aboutUs',
  },
  {
    key: 4,
    content: 'menu.joinOurTeam',
    destination: '/joinOurTeam',
  },
  {
    key: 5,
    content: 'menu.projects',
    destination: '/projects',
  },
  {
    key: 6,
    content: 'menu.contact',
    destination: '/getInTouch',
  },
];
