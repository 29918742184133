import benefits from './benefitsEN.json';
import values from './valuesEN.json';
import aboutUs from './aboutUsEN.json';
import whatWeDo from './whatWeDoEN.json';
import menu from './menuEN.json';
import home from './homeEN.json';
import backgrounds from './backgroundsEN.json';
import contact from './contactEN.json';
import joinOurTeam from './joinOurTeamEN.json';
import projects from './projectsEN.json';
import projectDiscovery from './projectDiscoveryEN.json';

export default {
  benefits: benefits,
  values: values,
  aboutUs: aboutUs,
  whatWeDo: whatWeDo,
  menu: menu,
  home: home,
  backgrounds: backgrounds,
  contact: contact,
  joinOurTeam: joinOurTeam,
  projects: projects,
  projectDiscovery: projectDiscovery,
};
