import { createGlobalStyle } from "styled-components";

/*** Herabarium Fonts ***/
import HerbariumRegularAltFont from "./fonts/HerbariumFont/OTF/Herbarium.otf";

/*** Barlow Fonts ***/
import BarlowRegularFont from "./fonts/Barlow/Barlow-Regular.ttf";
import BarlowLightFont from "./fonts/Barlow/Barlow-Light.ttf";
import BarlowBoldFont from "./fonts/Barlow/Barlow-Bold.ttf";
import BarlowExtraBoldFont from "./fonts/Barlow/Barlow-ExtraBold.ttf";

import Color from "./commons/Color";
import Device from "./commons/Device";

export default createGlobalStyle`
    @font-face {
        font-family: 'Herbarium';
        src:
            url(${HerbariumRegularAltFont}) format('opentype');
    }

    @font-face {
        font-family: 'Barlow';
        src:
            url(${BarlowRegularFont}) format('truetype');
    }
    @font-face {
        font-family: 'BarlowLight';
        src:
            url(${BarlowLightFont}) format('truetype');
    }

    @font-face {
        font-family: 'BarlowBold';
        src:
            url(${BarlowBoldFont}) format('truetype');
    }

    @font-face {
        font-family: 'BarlowExtraBold';
        src:
            url(${BarlowExtraBoldFont}) format('truetype');
    }

    .over-floating-elements{
        z-index: 2;
    }

    .ant-notification{
        z-index: 1120 !important;
    }

    body .custom-modal .ant-modal-content{
        background-color:transparent;
        z-index: 1200 !important;
    }
    body .icon-custom-close svg{
        width:100px !important;
        height:3vh;

    }
    body .icon-custom-close:hover svg{
        fill : ${Color.button};
    }

    @media ${Device.xs} {
        body .icon-custom-close{
            right:3vh !important;
            top:3vh !important;
            svg{
                height:1.2em;
            }
        }

        body .custom-modal .ant-modal-content{
            top:60px;

            .ant-modal-body{
                min-height:initial !important;
            }
            padding-bottom:45px;
        }

        body .custom-modal{
            overflow:hidden;
        }
    }


`;
