/* Projects Cards*/

import ProjectCardTiendificar from '../assets/projects/cards/card-tiendificar.png';
import MockupTiendificar1 from '../assets/projects/mockups/Tiendificar/mock-tiendificar1.png';

import ProjectCardHelvaci from '../assets/projects/cards/card-helvaci.png';
import MockupHelvaci1 from '../assets/projects/mockups/Helvaci/mock-helvaci1.png';
import MockupHelvaci2 from '../assets/projects/mockups/Helvaci/mock-helvaci2.png';

import ProjectCardAero from '../assets/projects/cards/card-aero.png';
import MockupAero1 from '../assets/projects/mockups/Aero/mock-aero1.png';
import MockupAero2 from '../assets/projects/mockups/Aero/mock-aero2.png';
import MockupAero3 from '../assets/projects/mockups/Aero/mock-aero3.png';

import ProjectCardTeytu from '../assets/projects/cards/card-teytu.png';
import MockupTeytu1 from '../assets/projects/mockups/Teytu/mock-teytu1.png';
import MockupTeytu2 from '../assets/projects/mockups/Teytu/mock-teytu2.png';

import ProjectCardAgs from '../assets/projects/cards/card-ags.png';
import MockupAgs1 from '../assets/projects/mockups/AGS/mock-ags1.png';
import MockupAgs2 from '../assets/projects/mockups/AGS/mock-ags2.png';

import ProjectCardHomeselect from '../assets/projects/cards/card-homeselect.png';
import MockupHomeselect1 from '../assets/projects/mockups/Homeselect/mock-homeselect1.png';

import ProjectCardMedicina from '../assets/projects/cards/card-medicina.png';
import MockupMedicina1 from '../assets/projects/mockups/Medicinafetal/mock-medicinafetal1.png';

import ProjectCardBanda from '../assets/projects/cards/card-banda.png';
import MockupBanda from '../assets/projects/mockups/BandaInvitada/mock-banda.png';

import ProjectCardHoc from '../assets/projects/cards/card-hoc.png';
import MockupHoc from '../assets/projects/mockups/HOC/mock-hoc.png';

import MockupWashInn1 from '../assets/projects/mockups/Washinn/mock-washinn1.png';
import MockupWashInn2 from '../assets/projects/mockups/Washinn/mock-washinn2.png';
import ProjectCardWashinn from '../assets/projects/cards/card-washinn.png';

import MockupBriales1 from '../assets/projects/mockups/Briales/mock-briales1.png';
import ProjectCardBriales from '../assets/projects/cards/card-briales.png';

import ProjectCardCulbot from '../assets/projects/cards/card-culbot.png';

import MockupCulbot1 from '../assets/projects/mockups/Culbot/mock-culbot1.png';
import MockupCulbot2 from '../assets/projects/mockups/Culbot/mock-culbot2.png';

export const projects = [
  {
    title: 'projects.washinn.title',
    subtitle: 'projects.washinn.subtitle',
    label: 'projects.washinn.label',
    seeMore: '/projects/washinn',
    image: ProjectCardWashinn,
    mockups: [MockupWashInn1, MockupWashInn2],
    tags: 'projects.washinn.tags',
    tagsMobile: 'projects.washinn.tagsMobile',
    about: 'projects.washinn.about',
    challenge: 'projects.washinn.challenge',
    interventions: [
      {
        bold: 'projects.washinn.intervention1.title',
        text: 'projects.washinn.intervention1.description',
      },
    ],
  },
  {
    title: 'projects.briales.title',
    subtitle: 'projects.briales.subtitle',
    label: 'projects.briales.label',
    image: ProjectCardBriales,
    seeMore: '/projects/briales',
    mockups: [MockupBriales1],
    tags: 'projects.briales.tags',
    tagsMobile: 'projects.briales.tagsMobile',
    about: 'projects.briales.about',
    challenge: 'projects.briales.challenge',
    interventions: [
      {
        bold: 'projects.briales.intervention1.title',
        text: 'projects.briales.intervention1.description',
      },
      {
        bold: 'projects.briales.intervention2.title',
        text: 'projects.briales.intervention2.description',
      },
      {
        bold: 'projects.briales.intervention3.title',
        text: 'projects.briales.intervention3.description',
      },
    ],
  },
  {
    title: 'Homeselect',
    subtitle: 'projects.homeselect.subtitle',
    label: 'projects.homeselect.label',
    image: ProjectCardHomeselect,
    seeMore: '/projects/homeselect',
    mockups: [MockupHomeselect1],
    tags: 'projects.homeselect.tags',
    tagsMobile: 'projects.homeselect.tagsMobile',
    about: 'projects.homeselect.about',
    challenge: 'projects.homeselect.challenge',
    interventions: [
      {
        bold: 'projects.homeselect.intervention1.title',
        text: 'projects.homeselect.intervention1.description',
      },
    ],
  },
  {
    title: 'projects.culbot.title',
    subtitle: 'projects.culbot.subtitle',
    label: 'projects.culbot.label',
    image: ProjectCardCulbot,
    seeMore: '/projects/culbot',
    mockups: [MockupCulbot1, MockupCulbot2],
    tags: 'projects.culbot.tags',
    tagsMobile: 'projects.culbot.tagsMobile',

    about: 'projects.culbot.about',
    challenge: 'projects.culbot.challenge',
    interventions: [
      {
        bold: 'projects.culbot.intervention1.title',
        text: 'projects.culbot.intervention1.description',
      },
      {
        text1: 'projects.culbot.intervention2.description',
        bold: 'projects.culbot.intervention2.bold',
        text: 'projects.culbot.intervention2.description2',
      },
      {
        text1: 'projects.culbot.intervention3.description',
        bold: 'projects.culbot.intervention3.bold',
        text: 'projects.culbot.intervention3.description2',
      },
      {
        text1: 'projects.culbot.intervention4.description',
        bold: 'projects.culbot.intervention4.bold',
        text: 'projects.culbot.intervention4.description2',
      },
    ],
  },
  {
    title: 'Aero',
    subtitle: 'projects.aero.subtitle',
    label: 'projects.aero.label',
    seeMore: '/projects/aero',
    image: ProjectCardAero,
    tags: 'projects.aero.tags',
    tagsMobile: 'projects.aero.tagsMobile',
    about: 'projects.aero.about',
    subprojects: [
      {
        mockup: MockupAero1,
        title: 'projects.aero.cotizador.title',
        challenge: 'projects.aero.cotizador.challenge',
        interventions: [
          {
            bold: 'projects.aero.cotizador.intervention1.title',
            text: 'projects.aero.cotizador.intervention1.description',
          },
        ],
      },
      {
        mockup: MockupAero2,
        title: 'projects.aero.remoteAttention.title',
        challenge: 'projects.aero.remoteAttention.challenge',

        interventions: [
          {
            bold: 'projects.aero.remoteAttention.intervention1.title',
            text: 'projects.aero.remoteAttention.intervention1.description',
          },
        ],
      },
      {
        mockup: MockupAero3,
        title: 'projects.aero.aeroNews.title',
        challenge: 'projects.aero.aeroNews.challenge',
        interventions: [
          {
            bold: 'projects.aero.aeroNews.intervention1.title',
            text: 'projects.aero.aeroNews.intervention1.description',
          },
        ],
      },
    ],
  },
  {
    title: 'Tiendificar',
    subtitle: 'E-Commerce',
    label: 'projects.tiendificar.label',
    seeMore: '/projects/tiendificar',
    image: ProjectCardTiendificar,
    mockups: [MockupTiendificar1],
    tags: 'projects.tiendificar.tags',
    tagsMobile: 'projects.tiendificar.tagsMobile',
    about: 'projects.tiendificar.about',
    challenge: 'projects.tiendificar.challenge',
    interventions: [
      {
        bold: 'projects.tiendificar.intervention1.title',
        text: 'projects.tiendificar.intervention1.description',
      },
      {
        bold: 'projects.tiendificar.intervention2.title',
        text: 'projects.tiendificar.intervention2.description',
      },
    ],
  },
  {
    title: 'Helvaci',
    subtitle: 'projects.helvaci.subtitle',
    label: 'projects.helvaci.label',
    seeMore: '/projects/helvaci',
    image: ProjectCardHelvaci,
    mockups: [MockupHelvaci1, MockupHelvaci2],
    tags: 'projects.helvaci.tags',
    tagsMobile: 'projects.helvaci.tagsMobile',
    about: 'projects.helvaci.about',
    challenge: 'projects.helvaci.challenge',
    interventions: [
      {
        bold: 'projects.helvaci.intervention1.title',
        text: 'projects.helvaci.intervention1.description',
      },
      {
        bold: 'projects.helvaci.intervention2.title',
        text: 'projects.helvaci.intervention2.description',
      },
    ],
  },
  {
    title: 'Teytu',
    subtitle: 'projects.teytu.subtitle',
    label: 'projects.teytu.label',
    image: ProjectCardTeytu,
    seeMore: '/projects/teytu',
    mockups: [MockupTeytu1, MockupTeytu2],
    tags: 'projects.teytu.tags',
    tagsMobile: 'projects.teytu.tagsMobile',
    about: 'projects.teytu.about',
    challenge: 'projects.teytu.challenge',
    interventions: [
      {
        bold: 'projects.teytu.intervention1.title',
        text: 'projects.teytu.intervention1.description',
      },
    ],
  },
  {
    title: 'AGS',
    subtitle: 'Progressive Web Application',
    label: 'projects.ags.label',
    image: ProjectCardAgs,
    seeMore: '/projects/ags',
    mockups: [MockupAgs1, MockupAgs2],
    tags: 'projects.ags.tags',
    tagsMobile: 'projects.ags.tagsMobile',
    about: 'projects.ags.about',
    challenge: 'projects.ags.challenge',
    interventions: [
      {
        bold: 'projects.ags.intervention1.title',
        text: 'projects.ags.intervention1.description',
      },
    ],
  },
  {
    title: 'projects.medicinaFetal.title',
    subtitle: 'projects.medicinaFetal.subtitle',
    label: 'projects.medicinaFetal.label',
    image: ProjectCardMedicina,
    seeMore: '/projects/medicinafetal',
    mockups: [MockupMedicina1],
    tags: 'projects.medicinaFetal.tags',
    tagsMobile: 'projects.medicinaFetal.tagsMobile',
    about: 'projects.medicinaFetal.about',
    challenge: 'projects.medicinaFetal.challenge',
    interventions: [
      {
        bold: 'projects.medicinaFetal.intervention1.title',
        text: 'projects.medicinaFetal.intervention1.description',
      },
      {
        bold: 'projects.medicinaFetal.intervention2.title',
        text: 'projects.medicinaFetal.intervention2.description',
      },
      {
        bold: 'projects.medicinaFetal.intervention3.title',
        text: 'projects.medicinaFetal.intervention3.description',
      },
    ],
  },
  {
    title: 'projects.bandaInvitada.title',
    subtitle: 'projects.bandaInvitada.subtitle',
    label: 'projects.bandaInvitada.label',
    image: ProjectCardBanda,
    seeMore: '/projects/bandaInvitada',
    mockups: [MockupBanda],
    tags: 'projects.bandaInvitada.tags',
    tagsMobile: 'projects.bandaInvitada.tagsMobile',
    about: 'projects.bandaInvitada.about',
    challenge: 'projects.bandaInvitada.challenge',
    interventions: [
      {
        bold: 'projects.bandaInvitada.intervention1.title',
        text: 'projects.bandaInvitada.intervention1.description',
      },
      {
        bold: 'projects.bandaInvitada.intervention2.title',
        text: 'projects.bandaInvitada.intervention2.description',
      },
    ],
  },
  {
    title: 'projects.hoc.title',
    subtitle: 'projects.hoc.subtitle',
    label: 'projects.hoc.label',
    image: ProjectCardHoc,
    seeMore: '/projects/hoc',
    mockups: [MockupHoc],
    tags: 'projects.hoc.tags',
    tagsMobile: 'projects.hoc.tagsMobile',
    about: 'projects.hoc.about',
    challenge: 'projects.hoc.challenge',
    interventions: [
      {
        bold: 'projects.hoc.intervention1.title',
        text: 'projects.hoc.intervention1.description',
      },
    ],
  },
];
