import styled from "styled-components";
import Device from "../../commons/Device";
import ImageRectangle from "../../assets/backgrounds/bg-canvas.png";

export const DataContainer = styled.div`
  padding: 0 15%;
  .subproject-title {
    font-family: BarlowBold;
    font-style: normal;
    font-size: 24px;
    line-height: 30px;
    color: #40ade3 !important;
    text-transform: uppercase !important;
  }
  .mockups-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: -5rem;
    margin-left: 1.5rem;
  }
  .description-col {
    position: relative;
    padding: 2rem 0.5rem;
  }

  .project-description {
    color: #352f80 !important;
    margin: 0;
    margin-bottom: 0.75rem;
    padding: 0;
    white-space: pre-wrap;
  }

  p {
    color: #002944 !important;
    font-family: BarlowLight;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 3.5rem;
    white-space: pre-wrap;
  }
  @media ${Device.xs} {
    padding: 2rem 5%;
    .project-description {
      font-family: Barlow;
    }
  }
`;

export const ImageContainer = styled.div`
  background-image: url(${ImageRectangle});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  height: 35vh;
  position: relative;

  @media ${Device.xs} {
    height: 45vh;
  }
`;

export const TitleContainer = styled.div`
  position: absolute;
  top: 15%;
  padding-left: 15.8%;

  .project-title {
    color: #fff !important;
    margin: 0;
  }

  .project-subtitle {
    color: #fff !important;
    font-family: "Barlow";
    font-weight: 400;
    margin: 0;
  }

  @media ${Device.xs} {
    width: 100%;
    padding-left: 5%;
    top: 25%;
    .project-title {
      margin-left: 1rem;
    }

    .project-subtitle {
      margin-left: 1rem;
    }
  }
`;

export const MainContainer = styled.div`
  overflow-x: hidden;
`;

export const Bold = styled.b`
  font-family: BarlowBold;
  white-space: pre-wrap;
`;

export const Img = styled.img`
  width: 100%;
  @media ${Device.md} {
    margin-bottom: 2rem;
    margin-left: 1rem;
  }
`;

export const DiamondImage = styled.img`
  width: 40px;
  display: block;
`;
