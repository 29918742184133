/*----- Core -----*/
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

/*----- Components -----*/
import Device from "../../../commons/Device";
import GetInTouchInformation from "./GetInTouchInformation";
import GetInTouchForm from "./GetInTouchForm";
import { Row, Col } from "antd";
import BackgroundText from "../../../components/text/BackgroundText";

const Styles = styled.div`
  min-height: 80vh;
  padding: 5% 16%;

  .data-container {
    padding-top: 50px;
  }

  .data-container > .custom-title {
    color: #ffffff;
    margin-bottom: 1.5em;
    margin-left: 0px;
    font-size: 42px;
  }

  .get-in-touch-form,
  .get-in-touch-information {
    padding-left: 20%;
    padding-right: 20%;
  }

  .container-contact-infomration {
    display: none;
  }

  .get-in-touch-description {
    padding-top: 30px;
  }

  @media ${Device.xs} {
    padding: 10% 8%;
    background-size: 400% !important;
    .data-container {
      padding-top: 90px;
    }

    .get-in-touch-form,
    .get-in-touch-information {
      padding-left: 3%;
      padding-right: 3%;
    }

    .data-container > .custom-title {
      text-align: center;
    }

    .container-contact-infomration {
      display: block;
      margin-top: 20px;
      text-align: center;
    }

    .get-in-touch-description {
      padding-top: 0px;
      padding-bottom: 80px;
    }
  }
`;

const ContainerForm = () => {
  const { t, i18n } = useTranslation();
  return (
    <Styles>
      <div className="data-container">
        <Row type="flex">
          <Col xs={24} md={14}>
            <BackgroundText
              content={t("menu.contact")}
              position="absolute"
              lineHeight="0.6"
              fontSize={i18n.language === "en" ? "14" : "9"}
            />
            <GetInTouchInformation />
          </Col>
          <Col xs={24} md={10}>
            <GetInTouchForm />
          </Col>
        </Row>
      </div>
    </Styles>
  );
};
export default ContainerForm;
