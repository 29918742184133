import LogoAero from "../assets/partners/logos/aero-BN.png";
import LogoAeroColor from "../assets/partners/logos/aero-color.png";
import LogoHelvaci from "../assets/partners/logos/helvaci-BN.svg";
import LogoHelvaciColor from "../assets/partners/logos/helvaci-color.svg";
import LogoTiendificar from "../assets/partners/logos/tiendificar-BN.svg";
import LogoTiendificarColor from "../assets/partners/logos/tiendificar-color.png";
import LogoCronos from "../assets/partners/logos/cronos-BN.svg";
import LogoCronosColor from "../assets/partners/logos/cronos-color.svg";
import LogoHomeSelect from "../assets/partners/logos/homeselect-BN.png";
import LogoHomeSelectColor from "../assets/partners/logos/homeselect-color.png";
import LogoMejuri from "../assets/partners/logos/mejuri-BN.png";
import LogoMejuriColor from "../assets/partners/logos/mejuri-color.png";
import LogoTheCocktail from "../assets/partners/logos/the-cocktail-BN.png";
import LogoTheCocktailColor from "../assets/partners/logos/the-cocktail-color.png";
import LogoFetalIdEducation from "../assets/partners/logos/fetal-id-education-BN.png";
import LogoFetalIdEducationColor from "../assets/partners/logos/fetal-id-education-color.png";
import LogoAfalp from "../assets/partners/logos/afalp-BN.svg";
import LogoAfalpColor from "../assets/partners/logos/afalp-color.svg";
import LogoTeytu from "../assets/partners/logos/teytu-BN.png";
import LogoTeytuColor from "../assets/partners/logos/teytu-color.png";
import LogoJobbier from "../assets/partners/logos/jobbier-BN.svg";
import LogoJobbierColor from "../assets/partners/logos/jobbier-color.svg";
import LogoAgs from "../assets/partners/logos/ags-BN.png";
import LogoAgsColor from "../assets/partners/logos/ags-color.png";
import LogoSioc from "../assets/partners/logos/sioc-BN.svg";
import LogoSiocColor from "../assets/partners/logos/sioc-color.svg";
import LogoCenterBuilding from "../assets/partners/logos/center-building-BN.png";
import LogoCenterBuildingColor from "../assets/partners/logos/center-building-color.png";
import LogoBriales from "../assets/partners/logos/briales-BN.svg";
import LogoBrialesColor from "../assets/partners/logos/briales-color.svg";
import LogoFaico from "../assets/partners/logos/faico-BN.svg";
import LogoFaicoColor from "../assets/partners/logos/faico-color.svg";

const CaseAero = "/pdf/Aeroturismo.pdf";
const CaseTeytu = "/pdf/Teytuturismo.pdf";

export const partnersLinks = [
  {
    title: "Aero",
    logo: LogoAero,
    logoColor: LogoAeroColor,
    url: CaseAero,
    width: "60%",
  },
  {
    title: "Helvaci",
    logo: LogoHelvaci,
    logoColor: LogoHelvaciColor,
    url: "https://www.helvaci.com.ar/",
    width: "100%",
  },
  {
    title: "Tiendificar",
    logo: LogoTiendificar,
    logoColor: LogoTiendificarColor,
    url: "https://tiendificar.com/",
    width: "60%",
  },
  {
    title: "Cronos",
    logo: LogoCronos,
    logoColor: LogoCronosColor,
    url: "",
    width: "60%",
  },
  {
    title: "Home Select",
    logo: LogoHomeSelect,
    logoColor: LogoHomeSelectColor,
    url: "https://www.homeselect.com/es/madrid",
    width: "100%",
  },
  {
    title: "Mejuri",
    logo: LogoMejuri,
    logoColor: LogoMejuriColor,
    url: "https://mejuri.com/",
    width: "100%",
  },
  {
    title: "The cocktail",
    logo: LogoTheCocktail,
    logoColor: LogoTheCocktailColor,
    url: "https://the-cocktail.com/",
    width: "100%",
  },
  {
    title: "Medicina Fetal Barcelona",
    logo: LogoFetalIdEducation,
    logoColor: LogoFetalIdEducationColor,
    url: "https://medicinafetalbarcelona.org/",
    width: "80%",
  },
  {
    title: "Afalp",
    logo: LogoAfalp,
    logoColor: LogoAfalpColor,
    url: "https://www.afalp.org.ar/",
    width: "100%",
  },
  {
    title: "Teytu",
    logo: LogoTeytu,
    logoColor: LogoTeytuColor,
    url: CaseTeytu,
    width: "100%",
  },
  {
    title: "Jobbier",
    logo: LogoJobbier,
    logoColor: LogoJobbierColor,
    url: "https://jobbier.com/",
  },
  {
    title: "AGS",
    logo: LogoAgs,
    logoColor: LogoAgsColor,
    url: "https://agshandling.com",
  },
  {
    title: "SIOC",
    logo: LogoSioc,
    logoColor: LogoSiocColor,
    url: "http://www.sioc.com.ar/",
  },
  {
    title: "Center Builiding",
    logo: LogoCenterBuilding,
    logoColor: LogoCenterBuildingColor,
    url: "",
  },
  {
    title: "Briales",
    logo: LogoBriales,
    logoColor: LogoBrialesColor,
    url: "https://www.briales.com.ar",
  },
  {
    title: "Faico",
    logo: LogoFaico,
    logoColor: LogoFaicoColor,
    url: "http://faico.com.ar/",
  },
];
