import styled from "styled-components";
import Device from "../Device";

export default styled.span`
  position: absolute;
  z-index: 1;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  bottom: ${(props) => props.bottom};
  right: ${(props) => props.right};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  transform: matrix(0.89, 0.45, -0.45, 0.89, 0, 0);
  background: transparent
    linear-gradient(
      215deg,
      ${(props) => props.downColor} 0%,
      ${(props) => props.upColor} 100%
    )
    0% 0% no-repeat padding-box;
  opacity: 1;

  @media ${Device.xs} {
    display: ${(props) => (props.displayMobile ? "block" : "none")};
    top: ${(props) => props.topMobile};
    left: ${(props) => props.leftMobile};
    bottom: ${(props) => props.bottomMobile};
    right: ${(props) => props.rightMobile};
  }
`;
