import React from 'react';
import styled from 'styled-components';
import Barlow from '../../commons/mixins/fonts/Barlow'
import Color from '../../commons/Color'
import PropTypes from 'prop-types';

const StyledTag = styled.span`
  ${Barlow(12,600,Color.button)};
  color: ${props => props.color};
  padding-left: ${props => props.paddingLeft};
`

const Tag = ({text, color, paddingLeft}) => (
  <StyledTag color={color} paddingLeft={paddingLeft} >{text}</StyledTag>
)

export default Tag

Tag.defaultProps = {
  color: Color.button,
  text: '',
  paddingLeft: '0px'
}

Tag.propTypes = {
  /* *
   * tag text specification
   */
  text: PropTypes.string.isRequired,
  /* *
   * tag color
   */
  color: PropTypes.string,
  /* *
   * padding left in px
   */
  paddingLeft: PropTypes.string,
};