
import { CHANGE_LOGO_COLOR } from './AppConstants'

const initialState = {
  logoColor: 'white',
}

export function appReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_LOGO_COLOR:
            return {
                ...state,
                logoColor: action.payload
            }
        default:
            return state
        }
}

export default appReducer