/*----- Core -----*/
import React from "react";

/*----- Components -----*/
import Particles from "react-particles-js";
import { isDevice } from "../../utils";

const BubbleParticles = () => {
  let params = {
    particles: {
      number: {
        value: 160,
        density: {
          enable: false,
        },
      },
      color: {
        value: "#FF33DA",
      },
      size: {
        value: 2.5,
        random: true,
        anim: {
          speed: 4,
          size_min: 0.3,
        },
      },
      line_linked: {
        enable: false,
      },
      move: {
        random: true,
        speed: 1,
        direction: "bottom-left",
        out_mode: "out",
      },
    },
  };

  if (!isDevice()) {
    params["interactivity"] = {
      events: {
        onhover: {
          enable: true,
          mode: "grab",
        },
        onclick: {
          enable: true,
          mode: "repulse",
        },
      },
      modes: {
        bubble: {
          distance: 250,
          duration: 2,
          size: 0,
          opacity: 0,
        },
        repulse: {
          distance: 400,
          duration: 4,
        },
      },
    };
  }

  return (
    <Particles
      style={{
        background: "transparent",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 1,
      }}
      className="particles-bubble"
      params={params}
    />
  );
};

export default BubbleParticles;
