import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";

import { partnersLinks, isDevice } from "../../../../utils";

const Styles = styled.div`
  .col-image {
    height: 100px;

    .container-logo {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
      }
    }
  }

  .link-partner {
    .with-hover {
      display: none;
    }
    .without-hover {
      display: block;
    }
  }

  .link-partner:hover {
    .with-hover {
      display: block;
    }
    .without-hover {
      display: none;
    }
  }

  .first-row {
    .col-image {
      height: 88px;
    }
  }

  .third-row {
    padding-top: 8px;
  }

  .container-logo-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    .partner-logo-mobile {
      width: 70%;
      margin: 1rem;
    }
  }
`;

const ColLogo = ({
  size,
  logo,
  title,
  url,
  logoColor,
  aStyle = {},
  imgStyle = {},
}) => {
  const getImages = () => (
    <>
      <img
        className="with-hover"
        src={logoColor}
        alt={title}
        style={imgStyle}
      />
      <img className="without-hover" src={logo} alt={title} style={imgStyle} />
    </>
  );

  return (
    <Col md={size} className="col-image">
      <div className="container-logo">
        {url.length ? (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={url}
            className="link-partner"
            style={aStyle}
          >
            {getImages()}
          </a>
        ) : (
          <div className="link-partner" style={aStyle}>
            {getImages()}
          </div>
        )}
      </div>
    </Col>
  );
};

const PartnerList = () => {
  const getPartners = () => {
    return partnersLinks.map((partner, idx) => (
      <Col key={idx} xs={12} className="container-logo-mobile">
        {partner.url.length ? (
          <a target="_blank" rel="noopener noreferrer" href={partner.url}>
            <img
              src={partner.logoColor}
              alt={partner.title}
              className="partner-logo-mobile"
            />
          </a>
        ) : (
          <div>
            <img
              src={partner.logoColor}
              alt={partner.title}
              className="partner-logo-mobile"
            />
          </div>
        )}
      </Col>
    ));
  };

  const getContent = () => {
    if (isDevice()) {
      return <Row type="flex">{getPartners()}</Row>;
    }
    return (
      <>
        <Row type="flex" className="first-row">
          {partnersLinks.slice(0, 6).map((partner, index) => (
            <ColLogo
              key={index}
              size={4}
              url={partner.url}
              logo={partner.logo}
              logoColor={partner.logoColor}
              title={partner.title}
              aStyle={{ width: "90%", textAlign: "left" }}
              imgStyle={{ width: partner.width }}
            />
          ))}
        </Row>
        <Row type="flex" align="center" className="second-row">
          {partnersLinks.slice(6, 11).map((partner, index) => (
            <ColLogo
              key={index}
              size={4}
              url={partner.url}
              logo={partner.logo}
              logoColor={partner.logoColor}
              title={partner.title}
              aStyle={{
                textAlign: "right",
                paddingTop: "18px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
              imgStyle={{ width: partner.width, padding: "0 10% 0 10%" }}
            />
          ))}
        </Row>
        <Row type="flex" className="third-row">
          {partnersLinks.slice(11, 17).map((partner, index) => (
            <ColLogo
              key={index}
              size={4}
              url={partner.url}
              logo={partner.logo}
              logoColor={partner.logoColor}
              title={partner.title}
              aStyle={{ textAlign: "right" }}
              imgStyle={{ width: "100%", padding: "0 20% 0 0" }}
            />
          ))}
        </Row>
      </>
    );
  };
  return <Styles>{getContent()}</Styles>;
};

export default PartnerList;
