import Benefit1 from "../assets/aboutus/benefits/1.svg";
import Benefit2 from "../assets/aboutus/benefits/2.svg";
import Benefit3 from "../assets/aboutus/benefits/3.svg";
import Benefit4 from "../assets/aboutus/benefits/4.svg";
import Benefit5 from "../assets/aboutus/benefits/5.svg";
import Benefit6 from "../assets/aboutus/benefits/6.svg";
import Benefit7 from "../assets/aboutus/benefits/7.svg";
import Benefit8 from "../assets/aboutus/benefits/8.svg";
import Benefit9 from "../assets/aboutus/benefits/9.svg";

export const benefits = [
  {
    title: "benefits.0.title",
    content: "benefits.0.content",
    icon: Benefit1,
  },
  {
    title: "benefits.1.title",
    content: "benefits.1.content",
    icon: Benefit2,
  },
  {
    title: "benefits.2.title",
    content: "benefits.2.content",
    icon: Benefit3,
  },
  {
    title: "benefits.3.title",
    content: "benefits.3.content",
    icon: Benefit4,
  },
  {
    title: "benefits.4.title",
    content: "benefits.4.content",
    icon: Benefit5,
  },
  {
    title: "benefits.5.title",
    content: "benefits.5.content",
    icon: Benefit6,
  },
  {
    title: "benefits.6.title",
    content: "benefits.6.content",
    icon: Benefit7,
  },
  {
    title: "benefits.7.title",
    content: "benefits.7.content",
    icon: Benefit8,
  },
  {
    title: "benefits.8.title",
    content: "benefits.8.content",
    icon: Benefit9,
  },
];
