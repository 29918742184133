import React from "react";

const Linkedin = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13" cy="13" r="12.5" stroke="white" fillOpacity="0" />
    <path
      d="M9.49932 19H7.1384V10.8966H9.49932V19ZM7 8.1159C7 7.79136 7.11669 7.52425 7.35007 7.31455C7.58887 7.10485 7.9118 7 8.31886 7C8.72049 7 9.04071 7.10485 9.27951 7.31455C9.51832 7.52425 9.63772 7.79136 9.63772 8.1159C9.63772 8.44543 9.5156 8.71504 9.27137 8.92474C9.03256 9.13444 8.71506 9.23929 8.31886 9.23929C7.92266 9.23929 7.60244 9.13444 7.35821 8.92474C7.1194 8.71504 7 8.44543 7 8.1159Z"
      fill="white"
    />
    <path
      d="M13.6106 10.8966L13.6839 11.8328C14.3134 11.1088 15.1574 10.7468 16.2157 10.7468C17.1493 10.7468 17.844 10.999 18.2999 11.5033C18.7558 12.0075 18.9891 12.7615 19 13.765V19H16.6472V13.8174C16.6472 13.3581 16.5387 13.0261 16.3216 12.8214C16.1045 12.6117 15.7436 12.5068 15.2388 12.5068C14.5767 12.5068 14.0801 12.7664 13.749 13.2857V19H11.3962V10.8966H13.6106Z"
      fill="white"
    />
  </svg>
);

export default Linkedin;
