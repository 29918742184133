/*----- Core -----*/
import React from "react";
import styled from "styled-components";

/*----- Commons -----*/
import Barlow from "../../../commons/mixins/fonts/Barlow";

/*----- Assets -----*/
import Mail from "../../../assets/social-networks/Mail";
import Phone from "../../../assets/social-networks/Phone";

const Styles = styled.div`
  p {
    ${Barlow(15, 400, "#1E115C")}
    margin-bottom:5px;
    svg {
      width: 20px;
      height: 20px;
      fill: #d83180 !important;
      padding-top: 5px;
      margin-right: 10px;
    }
  }
  .container-phone {
    svg {
      width: 25px;
      height: 25px;
    }
  }

  .container-mail {
    margin-bottom: 15px;
  }
`;
const ContactInformation = () => (
  <Styles className="contact-information">
    <p className="container-phone">
      <Phone />
      +54 9 221 6161830
    </p>
    <p className="container-mail">
      <Mail />
      info@snappler.com.ar
    </p>
  </Styles>
);

export default ContactInformation;
