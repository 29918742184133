import { css } from "styled-components";

const BackgroundBarlowBold = (size, weight, color, opacity) => css`
  font-family: "BarlowBold";
  font-size: ${size}vw;
  color: ${color};
  font-weight: ${weight};
  opacity: ${opacity};
`;

export default BackgroundBarlowBold;
