import React from "react";
import { Form, Input } from "antd";
import PropTypes from "prop-types";

const { Item } = Form;

const InputItem = ({
  label,
  rules,
  fieldName,
  placeholder,
  getFieldDecorator,
}) => (
  <Item label={label}>
    {getFieldDecorator(fieldName, {
      rules: rules,
      validateTrigger: "onSubmit",
    })(<Input placeholder={placeholder} />)}
  </Item>
);

export default InputItem;

/*----- PropTypes -----*/
InputItem.propTypes = {
  /* *
   * Specifies input field name
   */
  fieldName: PropTypes.string.isRequired,
  /* *
   * Specifies placeholder text
   */
  placeholder: PropTypes.string.isRequired,
  /* *
   * Field decorator function
   */
  getFieldDecorator: PropTypes.func.isRequired,
  /* *
   * Specifies Label name
   */
  label: PropTypes.string,
  /* *
   * Specifies input rules / constraints
   */
  rules: PropTypes.array,
};
