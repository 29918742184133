import React from "react";
import { useTranslation } from "react-i18next";
import { ImageContainer, TitleContainer, MainContainer } from "./styles";
import Title from "../../components/text/Title";
import BackgroundText from "../../components/text/BackgroundText";
import Diamond1 from "../../assets/services/Grupo1625.svg";
import FloatingImage from "../../commons/FloatingImage";
import SuccessStoryDesktop from "./StoryDesktop";
import SuccessStoryMobile from "./StoryMobile";
import { isDevice } from "../../utils";
import { ProjectsBanner } from "../projects/styles";
import DefaultButton from "../../components/button/DefaultButton";
import { Row } from "antd";

const SuccessStory = ({ project }) => {
  const { t } = useTranslation();
  return (
    <MainContainer>
      <ImageContainer>
        <FloatingImage
          image={Diamond1}
          width="40px"
          bottom="-15px"
          left="10%"
          displayMobile
          widthMobile="30px"
          bottomMobile="-12px"
          rightMobile="10%"
        />
      </ImageContainer>
      <TitleContainer>
        <Title
          content={t("menu.projects")}
          size={3}
          className="project-subtitle"
        />
        <Title content={t(project.title)} size={1} className="project-title" />
      </TitleContainer>
      {isDevice() ? (
        <SuccessStoryMobile project={project} />
      ) : (
        <SuccessStoryDesktop project={project} />
      )}
      <BackgroundText
        content={isDevice() ? t(project.tagsMobile) : t(project.tags)}
        position="relative"
        fontColor="#DE3080"
        fontSize="9.4"
        mobileFontSize="14"
        lineHeight="75%"
        mobileLineHeight="0.875"
        wordBreak="keep-all"
      />
      <ProjectsBanner>
        <Row type="flex">
          <DefaultButton
            destination="/getInTouch"
            content={t("projects.button")}
            className="banner-button"
          />
        </Row>
      </ProjectsBanner>
    </MainContainer>
  );
};

export default SuccessStory;
