/*----- Core -----*/
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

/*----- Components -----*/
import { Button } from "antd";

/*----- Assets -----*/
import MenuIcon from "../../../assets/images/hamburger-menu.png";

const StyledMenuButtom = styled.div`
  img {
    width: 25px;
  }

  .ant-btn {
    background-color: transparent;
    border: 0px;
    padding: 5px 10px;
    padding-right: 0px;
  }

  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active,
  .ant-btn.active {
    background: transparent;
  }
`;

const MenuButtom = ({ onClick, visible }) => (
  <StyledMenuButtom className="menu-buttom">
    {visible ? (
      <Button onClick={onClick}>
        <img src={MenuIcon} alt="menu" />
      </Button>
    ) : (
      ""
    )}
  </StyledMenuButtom>
);

export default MenuButtom;

MenuButtom.propTypes = {
  /* *
   * Callback handler when clicked
   */
  onClick: PropTypes.func.isRequired,
  /* *
   * Turns on/off visibility
   */
  visible: PropTypes.bool.isRequired,
};
