import React from "react";

const Mail = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.824"
    height="16.584"
    viewBox="0 0 21.824 16.584"
  >
    <defs>
      <style></style>
    </defs>
    <path
      d="M19.233,45.945H2.592A2.545,2.545,0,0,0,0,48.433v11.6a2.545,2.545,0,0,0,2.592,2.491H19.233a2.545,2.545,0,0,0,2.592-2.491v-11.6a2.545,2.545,0,0,0-2.592-2.488Zm0,14.39H2.592c-.21,0-.394-.138-.394-.292V49.967l7.53,6.358a.858.858,0,0,0,.553.2h1.272a.858.858,0,0,0,.553-.2l7.53-6.356V60.038c0,.155-.185.292-.4.292Zm-8.317-5.99L3.6,48.142H18.239Z"
      transform="translate(0 -45.945)"
    />
  </svg>
);

export default Mail;
