/*----- Core -----*/
import React from 'react'
/*----- Assets -----*/
import Lightning8 from '../../../commons/lightning/Lightning8'
import Lightning9 from '../../../commons/lightning/Lightning9'
import FloatingCube17 from '../../../commons/cubes/FloatingCube17'
import FloatingDiamond9 from '../../../commons/diamonds/FloatingDiamond9'


const FloatingElements = () => {
    return (
        <>
            <Lightning8/>
            <Lightning9/>
            <FloatingCube17/>
            <FloatingDiamond9/>
        </>
    )
}

export default FloatingElements