/*----- Core -----*/
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
/*----- utils -----*/
import { itemsWhatWeDo } from "../../../utils";

/*----- Components -----*/
import Title from "../../../components/text/Title";
import WhatWeDoCard from "./WhatWeDoCard";
import { Row, Col, Modal, Icon } from "antd";
import ModalCard from "./ModalCard";
import ButtonContainerHome from "../../../components/button/ButtonContainerHome";
import BackgroundText from "../../../components/text/BackgroundText";

const CardDeck = ({ home = false }) => {
  const { t } = useTranslation("translation");
  const [visible, setVisible] = useState(false);
  const [card, setCard] = useState(null);

  const getList = () => {
    return itemsWhatWeDo.map((item, idx) => (
      <Col
        key={idx}
        xs={24}
        lg={12}
        xl={8}
        onClick={() => {
          if (!home) {
            setVisible(true);
            setCard(item);
          }
        }}
        className="col-flex-center"
      >
        <WhatWeDoCard
          title={t(item.title)}
          description={t(item.description)}
          tags={item.tags}
          number={item.number}
          home={home}
        />
      </Col>
    ));
  };
  return (
    <>
      <div
        className="data-container"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <Title content= {t("menu.whatWeDo")} size={1} />
        <Row type="flex" justify="center" gutter={[24, 16]}>
          {getList()}
        </Row>
        <Row type="flex" justify="start" gutter={[24, 16]}>
          {home ? (
            <>
              <BackgroundText
                content={t("backgrounds.experiences")}
                fontSize="11"
                lineHeight="60%"
                bottom="-20%"
                left="5%"
                mobileFontSize="34.3"
                mobileLineHeight="80%"
                mobileBottom="-7%"
              />
              <ButtonContainerHome
                destination="/whatWeDo"
                content={t("menu.seeMore")}
              />
            </>
          ) : (
            <BackgroundText
              content={t("backgrounds.experiences")}
              fontSize="11"
              lineHeight="100%"
              bottom="0%"
              left="-10%"
              mobileFontSize="31"
              mobileLineHeight="60%"
              mobileBottom="-7%"
              position="relative"
            />
          )}
        </Row>
      </div>
      <Modal
        zIndex={1200}
        width={window.innerWidth - 20}
        title={null}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        style={{ backgroundColor: "none", top: 0, zIndex: 10 }}
        className="custom-modal"
        bodyStyle={{
          backgroundColor: "rgba(00, 29, 44, 0.94)",
          minHeight: "98vh",
        }}
        closeIcon={
          <Icon
            type="close"
            className="icon-custom-close"
            style={{
              color: "white",
              width: "50px",
              position: "absolute",
              right: "20vh",
              top: "10vh",
            }}
          />
        }
      >
        <ModalCard card={card} />
      </Modal>
    </>
  );
};

export default CardDeck;

CardDeck.propTypes = {
  /* *
   * Home location flag
   */
  home: PropTypes.bool,
};
