/*----- Core -----*/
import React, { useRef } from "react";
import JounOurTeamHeader from "./section/JounOurTeamHeader";
import ContainerFormJoinOurTeam from "./section/ContainerFormJoinOurTeam";

const JoinOurTeam = () => {
  const ref = useRef(null);
  return (
    <>
      <JounOurTeamHeader />
      <ContainerFormJoinOurTeam ref={ref} />
    </>
  );
};

export default JoinOurTeam;
