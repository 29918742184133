import React from "react";
import { withRouter } from "react-router-dom";
import { CardContainer, StyledImage } from "./styles";
import PropTypes from "prop-types";
import InformationCard from "./InformationCard";
import { isDevice } from "../../utils";

const ProjectCard = ({
  history,
  title,
  subtitle,
  label,
  image,
  seeMore,
  home = true,
}) => {
  return (
    <CardContainer home={home} onClick={() => history.push(seeMore)}>
      <StyledImage src={image} />
      {!isDevice() && (
        <InformationCard
          className="information-card"
          title={title}
          subtitle={subtitle}
          label={label}
          seeMore={seeMore}
          home={home}
        />
      )}
    </CardContainer>
  );
};

export default withRouter(ProjectCard);

ProjectCard.propTypes = {
  /* *
   * Specifies project title
   */
  title: PropTypes.string.isRequired,
  /* *
   * Specifies project subtitle
   */
  subtitle: PropTypes.string.isRequired,
  /* *
   * Specifies Label name
   */
  label: PropTypes.string.isRequired,
  /* *
   * source image asset
   */
  image: PropTypes.string.isRequired,
  /* *
   * Router history
   */
  history: PropTypes.object,
  /* *
   * see more details
   */
  seeMore: PropTypes.string,
  /* *
   * Called from home module
   */
  home: PropTypes.bool,
};
