import styled from 'styled-components';
import Device from '../../commons/Device';
import ImageRectangle from '../../assets/backgrounds/bg-canvas.png';
import projectsBannerBackground from '../../assets/projects/banner/bannerBackgroundDesk.jpg';
import projectsBannerBackgroundMobile from '../../assets/projects/banner/bannerBackgroundMobile.svg';
import Frame from '../../assets/backgrounds/Frame2.png';

export const DataContainer = styled.div`
  position: relative;
  padding: 2rem 5%;

  .project-list {
    justify-content: center;
  }

  .project-description {
    color: #002944 !important;
    font-size: 20px;
    margin: 0;
    margin-bottom: 0.75rem;
    padding: 0;
    white-space: pre-wrap;
  }

  @media ${Device.xs} {
    padding: 2rem 5%;
  }

  .Video-pods {
    margin: auto;
    margin-bottom: 2rem;
  }

  iframe {
    height: 100%;
    width: 100%;
  }

  @media ${Device.sm} {
    .project-list {
      justify-content: flex-start;
    }
    .youtube-video {
      height: 100%;
      width: 100% !important;
    }
  }

  @media ${Device.md} {
    .project-list {
      justify-content: flex-start;
    }
    .youtube-video {
      height: 100%;
      width: 100%;
    }
  }

  @media ${Device.lg} {
    .section-video {
      margin-left: 9rem;
      margin-bottom: 5rem;
    }
    .Video-pods {
      margin-left: 15rem;
      width: 490px;
      height: 280px;
      margin-bottom: 2rem;
      margin-top: 0.5rem;
    }

    .youtube-video {
      height: 280px;
      width: 563px;
    }

    .card-text-project {
      width: 27rem;
    }

    .project-description {
      color: #de3080 !important;
      margin: 0;
      margin-bottom: 0.75rem;
      padding: 0;
      white-space: pre-wrap;
      font-size: 26px;
    }
    .section-text {
      width: 30% !important;
    }
  }

  @media ${Device.xl} {
    .section-video {
      margin-left: 9rem;
      margin-bottom: 5rem;
    }
    .Video-pods {
      margin-left: 15rem;
      width: 563px;
      height: 280px;
      margin-bottom: 2rem;
      margin-top: 2rem;
    }

    .youtube-video {
      height: 280px;
      width: 563px;
    }

    .project-description {
      color: #de3080;
      font-size: 35px;
    }
    .section-text {
      width: 30% !important;
      align-self: center;
    }
  }
`;

export const DataContainerLadder = styled.div`
  position: relative;
  .project-list {
    justify-content: center;
  }

  .project-description {
    color: #002944 !important;
    font-size: 20px;
    margin: 0;
    margin-bottom: 0.75rem;
    padding: 0;
    white-space: pre-wrap;
  }

  .track-text {
    color: white;
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 0;
  }

  .exp-text {
    color: #de3080 !important;
    font-size: 25px;
  }

  .youtube-video {
    width: 100%;
    height: 100%;
  }

  @media ${Device.xs} {
    padding: 2rem 5%;
    iframe {
      height: 100%;
      width: 100%;
    }

    .frame-wrapper {
      margin-bottom: 4rem;
    }

    .video-col-back {
      margin-left: 9%;
      margin-bottom: 5rem;
      margin-top: 2rem;
      margin: auto;
    }

    .track-text {
      color: white;
      font-weight: bold;
      font-size: 36px;
      margin-bottom: 0;
    }

    .exp-text {
      color: #de3080 !important;
      font-size: 18px;
    }
  }

  @media ${Device.md} {
    .project-list {
      justify-content: flex-start;
    }
  }

  @media ${Device.lg} {
    .bonus-text {
      margin-left: 10rem;
    }
    .track-text {
      padding-top: 3rem;
    }
    .video-col-back {
      margin-left: 27%;
      margin-bottom: 4rem;
    }
  }

  @media ${Device.xl} {
    .bonus-text {
      margin-left: 15rem;
    }
    .video-col-back {
      margin-left: 27%;
      margin-top: 6rem;
      margin-bottom: 7rem;
    }
    .youtube-video {
      width: 917px;
      height: 456px;
    }
    iframe {
      height: 100%;
      width: 100%;
    }
    .frame-wrapper {
      background-image: url(${Frame});
      padding-top: 2rem;
      background-position: fixed;
      background-repeat: no-repeat;
      background-position-x: right;
      position: relative;
    }
  }
`;

export const ImageContainer = styled.div`
  background-image: url(${ImageRectangle});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  height: 35vh;
`;

export const TitleContainer = styled.div`
  position: absolute;
  top: 18%;
  padding-left: 15%;

  .custom-title {
    color: #fff !important;
    margin-bottom: 1.5em;
  }

  @media ${Device.xs} {
    width: 100%;
    padding-left: 5%;
  }
`;

export const ProjectsBanner = styled.div`
  position: relative;
  .default-button {
    margin: 2rem auto;
    min-width: 300px;
    @media ${Device.xs} {
      height: 45px;
    }
  }

  @media ${Device.md} {
    background-image: url(${projectsBannerBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 128px;
  }

  @media ${Device.xs} {
    background-image: url(${projectsBannerBackgroundMobile});
    height: 192px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
