import React from "react";
import styled from "styled-components";

import { Radio } from "antd";

import Barlow from "../../commons/mixins/fonts/Barlow";
import Color from "../../commons/Color";

const StyledRadio = styled(Radio)`
  ${Barlow(20, 500, "white")};
  .ant-radio-inner {
    background: none;
  }
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-checked .ant-radio-inner {
    border-color: ${Color.button}!important;
  }
  .ant-radio-inner::after {
    background: ${Color.button};
  }
  span:last-child {
    color: white;
    font-weight: bolder;
  }
`;

const StyledGroup = styled(Radio.Group)`
  .ant-radio-wrapper {
    display: block;
    margin: 0.5rem;
  }
`;

const RadioGroup = ({ options, handleClick, value }) => {
  return (
    <StyledGroup onChange={(e) => handleClick(e.target.value)} value={value}>
      {options.map((option, index) => (
        <StyledRadio key={index} value={option.value}>
          {option.label}
        </StyledRadio>
      ))}
    </StyledGroup>
  );
};

export default RadioGroup;
