/*----- Core -----*/
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

/*----- Components -----*/
import { Row } from "antd";
import DefaultButton from "./DefaultButton";

/*----- Commons -----*/
import Device from "../../commons/Device";

const StyledButtonContainer = styled(Row)`
  width: 100%;

  .button-wrapper {
    z-index: 999;
    padding: 8px 12px;
  }
  @media ${Device.xs} {
    .default-button {
      max-width: 100%;
      height: 45px;
    }
  }
`;
/*----- Component -----*/
const ButtonContainerHome = ({ destination, onClick, content }) => (
  <StyledButtonContainer className="button-container" type="flex">
    <div className="button-wrapper">
      <DefaultButton
        destination={destination}
        content={content}
        handlerClick={onClick}
        color={"white"}
      />
    </div>
  </StyledButtonContainer>
);

export default ButtonContainerHome;

/*----- PropTypes -----*/
ButtonContainerHome.propTypes = {
  /* *
   * Destination Route path
   */
  destination: PropTypes.string.isRequired,
  /* *
   * onClick handler
   */
  onClick: PropTypes.func.isRequired,
  /* *
   * Button's text
   */
  content: PropTypes.string.isRequired,
};
