import React from "react";

const Instagram = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13" cy="13" r="12.5" stroke="white" fillOpacity="0" />
    <circle cx="17" cy="9" r="1" fill="white" strokeOpacity="1" />
    <circle cx="13" cy="13" r="3.5" stroke="white" fillOpacity="0" />
    <path
      d="M6.5 8.54623C6.5 7.41613 7.41613 6.5 8.54623 6.5H17.4538C18.5839 6.5 19.5 7.41613 19.5 8.54623V17.4538C19.5 18.5839 18.5839 19.5 17.4538 19.5H8.54623C7.41613 19.5 6.5 18.5839 6.5 17.4538V8.54623Z"
      stroke="white"
      fillOpacity="0"
    />
  </svg>
);

export default Instagram;
