/*----- Core -----*/
import React from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next"

/*----- Component -----*/
import { Row, Col } from "antd";
import Title from "../../../components/text/Title";
import BarlowLight from "../../../commons/mixins/fonts/BarlowLight";
/*----- Common -----*/
import Device from "../../../commons/Device";

const Styles = styled.div`
  padding: 8% 20%;

  .modal-container {
    height: auto;
    margin: 0 auto;
  }

  .card-data .custom-title {
    color: #77c0ff;
    margin: 0px;
    padding-bottom: 0.7em;
  }

  .card-data_item {
    color: white;
    font-size: 17px;
    font-family: "Barlow";
    color: white;
  }
  .card-container_title {
    position: relative;
  }

  .card-container_title .card-container-content {
    width: 50%;
    padding-bottom: 2em;

    .custom-title {
      line-height: 40px;
      font-size: 36px !important;
      color: #77c0ff;
      margin: 0px;
      padding-top: 20px;
    }
    p {
      padding-top: 1em;
      font-size: 20px;
      font-family: "Barlow";
      color: white;
    }

    .card-container-tags {
      padding: 1em 0;
    }
    .card-tag {
      ${BarlowLight(12, 300, "#fff")}
      font-style: italic;
      border-color: #352f80;
      border-style: solid;
      border-width: 1px;
      border-radius: 5px;
      padding: 3px;
      border-color: white;
    }
    .card-tag-col {
      margin-left: 5px;
      margin-top: 8px;
    }
  }

  .card-container_image {
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    text-align: right;
    svg {
      fill: #77c0ff;
      opacity: 0.2;
      max-width: 80%;
    }
  }

  @media ${Device.xs} {
    padding: 0%;
    .card-container_title .card-container-content {
      width: 100%;
      padding-bottom: 1em;
      .custom-title {
        line-height: 28px;
        font-size: 24px !important;
      }
      .card-container-tags {
        display: none;
      }

      p {
        display: none;
      }
    }

    .card-data .custom-title {
      font-size: 20px;
    }

    .card-data_item {
      font-size: 15px;
    }
  }
`;

const ModalCard = ({ card }) => {
  const {t} = useTranslation("translation")

  const getSubcontent = (item) => {
    return item.subcontent.map((item, idx) => (
      <p className="card-data_item" key={idx}>
        <span style={{ fontWeight: "bold" }}>{t(item.title)}&nbsp;</span>
        {t(item.content)}
      </p>
    ));
  };

  const getDataCard = () => {
    return card.data.map((item, idx) => (
      <Col key={idx} md={12} xs={24} className="card-data">
        <Title content={t(item.title)} size={3} />
        {item.content ? (
          <p className="card-data_item"> {t(item.content)} </p>
        ) : (
          getSubcontent(item)
        )}
      </Col>
    ));
  };

  const getTags = () => {
    return card.tags.map((tag, idx) => (
      <Col key={idx} className="card-tag-col">
        <span className="card-tag">{tag}</span>
      </Col>
    ));
  };

  return (
    <Styles>
      <div className="modal-container">
        <div className="card-container_title">
          <div className="card-container-content">
            <Title content={t(card.title)} size={1} />

            <Row type="flex" className="card-container-tags">
              {getTags()}
            </Row>
            <p>{t(card.description)}</p>
          </div>
          <div className="card-container_image">{card.number}</div>
        </div>
        <Row type="flex" gutter={[48, 8]}>
          {getDataCard()}
        </Row>
      </div>
    </Styles>
  );
};

export default ModalCard;
