import React from "react";
import { Form, Input } from "antd";
import PropTypes from "prop-types";

const { Item } = Form;
const { TextArea } = Input;

const TextAreaItem = ({
  label,
  fieldName,
  placeholder,
  getFieldDecorator,
  rules,
  rows,
}) => (
  <Item label={label}>
    {getFieldDecorator(fieldName, {
      rules: rules,
      validateTrigger: "onSubmit",
    })(
      <TextArea
        placeholder={placeholder}
        rows={rows}
        style={{ height: "160px" }}
      />
    )}
  </Item>
);

export default TextAreaItem;

/*----- PropTypes -----*/
TextAreaItem.propTypes = {
  /* *
   * Specifies Field name
   */
  fieldName: PropTypes.string.isRequired,
  /* *
   * Specifies placeholder text
   */
  placeholder: PropTypes.string.isRequired,
  /* *
   * Specifies field decorator
   */
  getFieldDecorator: PropTypes.func.isRequired,
  /* *
   * Specifies Label name
   */
  label: PropTypes.string,
  /* *
   * Specifies field rules / constraints
   */
  rules: PropTypes.array,
  /* *
   * Text area size in rows 
   */
  rows: PropTypes.number,
};
