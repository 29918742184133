import Value1 from "../assets/aboutus/benefits/10.svg";
import Value2 from "../assets/aboutus/benefits/11.svg";
import Value3 from "../assets/aboutus/benefits/12.svg";
import Value4 from "../assets/aboutus/benefits/13.svg";

export const values = [
  {
    title: "values.1.title",
    content: "values.1.content",
    icon: Value2,
  },
  {
    title: "values.0.title",
    content: "values.0.content",
    icon: Value1,
  },
  {
    title: "values.2.title",
    content: "values.2.content",
    icon: Value3,
  },
  {
    title: "values.3.title",
    content: "values.3.content",
    icon: Value4,
  },
];
