import React, { useState } from "react";
import { useTranslation } from "react-i18next";

/*----- Components -----*/
import Title from "../../../components/text/Title";
import { Row, Col, Modal, Icon } from "antd";

/*----- utils -----*/
import { itemsProjectModality, isDevice } from "../../../utils";
import { Container,StyledButton } from "./styles";

import BackgroundImage from "../../../assets/backgrounds/bg-what-we-do.svg";
import BackgroundImageSmall from "../../../assets/backgrounds/bg-what-we-do-small.svg";
import FloatingElementsProjectModality from "./FloatingElementsProjectModality";
import ButtonContainerHome from "../../../components/button/ButtonContainerHome";
import ProjectDiscoveryModal from "./ProjectDiscoveryModal";

export const ProjectModalityCard = ({ title, content }) => (
  <>
    <Title content={title} size={3} />
    <p className="modality-card_content">{content}</p>
  </>
);

const ProjectModality = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const getItems = () => {
    return itemsProjectModality.map((item, idx) => (
      <Col key={idx} xs={24} md={12} xl={8} className="item-project-modality">
        <ProjectModalityCard title={t(item.title)} content={t(item.content)} />
        {item.button && (
          <StyledButton>
            <ButtonContainerHome
              onClick={() => setVisible(true)}
              content={t(item.button)}
              color="white"
            />
          </StyledButton>
        )}
      </Col>
    ));
  };
  const getImage = () => {
    let style = {
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
    };
    let image = BackgroundImage;
    if (isDevice()) {
      image = BackgroundImageSmall;
      style["backgroundPosition"] = "top";
    }
    style["backgroundImage"] = `url(${image})`;
    return style;
  };
  return (
    <Container style={getImage()}>
      <div className="frame-wrapper">
        <FloatingElementsProjectModality />
        <div
          className="data-container-modality"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Title content={t("whatWeDo.howWeDo.title")} size={1} />

          <Title content={t("whatWeDo.howWeDo.description")} size={2} />

          <Row type="flex" gutter={[32, 32]} className="modality-items">
            {getItems()}
          </Row>
        </div>
      </div>
      <Modal
        zIndex={1200}
        width={window.innerWidth - 20}
        title={null}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        style={{ backgroundColor: "none", top: 0, zIndex: 10 }}
        className="custom-modal"
        bodyStyle={{
          backgroundColor: "rgba(00, 29, 44, 0.94)",
          minHeight: "98vh",
        }}
        closeIcon={
          <Icon
            type="close"
            className="icon-custom-close"
            style={{
              color: "white",
              width: "50px",
              position: "absolute",
              right: "20vh",
              top: "10vh",
            }}
          />
        }
      >
        <ProjectDiscoveryModal />
      </Modal>
    </Container>
  );
};

export default ProjectModality;
