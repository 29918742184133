import React from "react";

const Behance = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13" cy="13" r="12.5" stroke="white" fillOpacity="0" />
    <path
      d="M10.102 8.47826C10.5427 8.47826 10.9499 8.51184 11.3235 8.61258C11.6971 8.67975 12.0036 8.81827 12.2764 8.98619C12.5493 9.1541 12.7508 9.39337 12.8893 9.6998C13.0236 10.0062 13.095 10.3798 13.095 10.787C13.095 11.2614 12.9942 11.6686 12.755 11.975C12.5493 12.2814 12.2134 12.5543 11.8021 12.7558C12.3814 12.9237 12.8221 13.2301 13.0908 13.6373C13.3594 14.0445 13.5315 14.5524 13.5315 15.1317C13.5315 15.6061 13.4308 16.0132 13.2587 16.3533C13.0908 16.6933 12.8179 16.9997 12.5115 17.2012C12.205 17.4069 11.8314 17.5748 11.4243 17.6755C11.0171 17.7763 10.6099 17.8435 10.2027 17.8435L5.65234 17.8519V8.47826H10.102ZM9.82911 12.2814C10.2027 12.2814 10.5092 12.1807 10.7442 12.0086C10.9793 11.8365 11.0842 11.5342 11.0842 11.1606C11.0842 10.9549 11.0507 10.7534 10.9835 10.6191C10.9163 10.4848 10.8156 10.3798 10.6771 10.2791C10.5427 10.2119 10.4042 10.1448 10.2363 10.1112C10.0684 10.0776 9.89628 10.0776 9.69479 10.0776H7.72604V12.2856C7.72604 12.2814 9.82911 12.2814 9.82911 12.2814ZM9.92986 16.2903C10.1356 16.2903 10.337 16.2567 10.5092 16.2231C10.6771 16.1895 10.8492 16.1224 10.9835 16.0174C11.1178 15.9125 11.2228 15.8117 11.3235 15.6438C11.3907 15.4759 11.4578 15.2702 11.4578 15.031C11.4578 14.5566 11.3235 14.2166 11.0507 13.9773C10.7778 13.7716 10.4042 13.6709 9.96344 13.6709H7.72604V16.2861H9.92986V16.2903Z"
      fill="white"
    />
    <path
      d="M16.4859 16.2567C16.7588 16.5296 17.166 16.6639 17.7075 16.6639C18.0811 16.6639 18.4211 16.5632 18.6939 16.391C18.9668 16.1854 19.1347 15.9839 19.2019 15.7782H20.8642C20.5913 16.5925 20.1842 17.1718 19.6426 17.5454C19.1011 17.8854 18.4547 18.0869 17.6739 18.0869C17.1324 18.0869 16.6538 17.9862 16.2131 17.8141C15.7723 17.6462 15.4323 17.4069 15.1259 17.0669C14.8194 16.7604 14.5843 16.3868 14.4458 15.9461C14.2779 15.5053 14.2065 15.031 14.2065 14.4853C14.2065 13.9773 14.2737 13.4988 14.4458 13.058C14.6179 12.6173 14.853 12.2437 15.1594 11.9036C15.4659 11.5972 15.8395 11.3244 16.2467 11.1564C16.6874 10.9885 17.1282 10.8836 17.6739 10.8836C18.2532 10.8836 18.7611 10.9843 19.2019 11.2236C19.6426 11.4629 19.9827 11.7315 20.2555 12.1387C20.5284 12.5123 20.7299 12.9531 20.8684 13.4274C20.9356 13.9018 20.9691 14.3803 20.9356 14.9218H16.0116C16.0116 15.4759 16.2131 15.9839 16.4859 16.2567ZM18.6268 12.6886C18.3875 12.4494 18.0139 12.315 17.5731 12.315C17.2667 12.315 17.0316 12.3822 16.8259 12.4829C16.6203 12.5837 16.4859 12.7222 16.3516 12.8565C16.2173 12.9909 16.1459 13.163 16.1123 13.3309C16.0787 13.4988 16.0452 13.6373 16.0452 13.7716H19.1011C19.034 13.2679 18.8619 12.9279 18.6268 12.6886ZM15.638 9.12473H19.4411V10.0398H15.638V9.12473Z"
      fill="white"
    />
  </svg>
);

export default Behance;
