import styled from "styled-components";
import { Row } from "antd";
import Device from "../../../../commons/Device";

export const Container = styled.section`
  padding: 8% 10px 10% 10px;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;

  .background-text-desktop {
    position: absolute;
    bottom: 5%;
    right: 5%;
    width: 47%;

    @media ${Device.xs} {
      display: none;
    }
  }

  .background-text-mobile {
    display: none;
    margin: 10% 0px 13%;
    overflow-x: hidden;
    text-align: center;
    width: 112%;
    position: relative;
    left: -20px;
    @media ${Device.xs} {
      display: block;
    }
  }

  @media ${Device.xs} {
    .section-title {
      font-size: 36px;
      line-height: 34px;
      margin-bottom: 20%;
      text-align: left;
    }
  }
`;

export const DataContainer = styled.div`
  width: 100%;
  padding-left: 15%;
  padding-right: 15%;

  @media ${Device.xs} {
    padding-left: 5%;
    padding-right: 5%;
  }
`;
export const StyledRow = styled(Row)`
  margin: 10% 0px 12% 0px;

  @media ${Device.xs} {
    margin: 10% 0px;
  }
`;
