/*----- Core -----*/
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Device from "../../../commons/Device";
import { Row, Col } from "antd";

import BackgroundText from "../../../components/text/BackgroundText";

import Top1 from "../../../assets/aboutus/workplace/top-1.png";
import Top2 from "../../../assets/aboutus/workplace/top-2.png";
import Top3 from "../../../assets/aboutus/workplace/top-3.png";
import Top4 from "../../../assets/aboutus/workplace/top-4.png";
import Bottom1 from "../../../assets/aboutus/workplace/bottom-1.png";
import Bottom2 from "../../../assets/aboutus/workplace/bottom-2.png";
import Bottom3 from "../../../assets/aboutus/workplace/bottom-3.png";
import Bottom4 from "../../../assets/aboutus/workplace/bottom-4.png";

const Styles = styled.div`
  padding-top: 7%;
  padding-left: 15.8%;
  padding-right: 15.8%;

  .border {
    border-right: 27px solid white;
    border-bottom: 25px solid white;
  }

  img {
    width: 100%;
    max-height: 371px;
    overflow: hidden;
    object-fit: cover;
  }

  @media ${Device.xs} {
    padding-left: 10%;
    padding-right: 10%;

    .border {
      border-right: 16px solid white;
      border-bottom: 12px solid white;
    }
  }
`;

const Workspace = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Styles>
        <Row type="flex">
          <Col
            xs={{ span: 12, order: 1 }}
            sm={{ span: 6, order: 1 }}
            md={{ span: 6, order: 1 }}
          >
            <div className="border">
              <img src={Top1} alt={"imagen decorativa"} />
            </div>
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            sm={{ span: 6, order: 1 }}
            md={{ span: 6, order: 2 }}
          >
            <div className="border">
              <img src={Top2} alt={"imagen decorativa"} />
            </div>
          </Col>

          <Col
            xs={{ span: 12, order: 1 }}
            sm={{ span: 6, order: 1 }}
            md={{ span: 6, order: 4 }}
          >
            <div className="border">
              <img src={Top3} alt={"imagen decorativa"} />
            </div>
          </Col>

          <Col
            xs={{ span: 12, order: 1 }}
            sm={{ span: 6, order: 1 }}
            md={{ span: 6, order: 4 }}
          >
            <div className="border">
              <img src={Top4} alt={"imagen decorativa"} />
            </div>
          </Col>
        </Row>

        <Row type="flex">
          <Col
            xs={{ span: 12, order: 1 }}
            sm={{ span: 6, order: 1 }}
            md={{ span: 6, order: 1 }}
          >
            <div className="border">
              <img src={Bottom1} alt={"imagen decorativa"} />
            </div>
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            sm={{ span: 6, order: 1 }}
            md={{ span: 6, order: 2 }}
          >
            <div className="border">
              <img src={Bottom2} alt={"imagen decorativa"} />
            </div>
          </Col>

          <Col
            xs={{ span: 12, order: 1 }}
            sm={{ span: 6, order: 1 }}
            md={{ span: 6, order: 4 }}
          >
            <div className="border">
              <img src={Bottom3} alt={"imagen decorativa"} />
            </div>
          </Col>

          <Col
            xs={{ span: 12, order: 1 }}
            sm={{ span: 6, order: 1 }}
            md={{ span: 6, order: 4 }}
          >
            <div className="border">
              <img src={Bottom4} alt={"imagen decorativa"} />
            </div>
          </Col>
        </Row>
      </Styles>

      <BackgroundText
        content={t("backgrounds.realTeam")}
        position="relative"
        fontSize="10.99"
        lineHeight="35%"
        marginBottom="2.7%"
        mobileFontSize="22.5"
        mobileLineHeight="97%"
        mobileMarginTop="-8%"
      />
    </div>
  );
};
export default Workspace;
