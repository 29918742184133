/*----- Core -----*/
import React from "react";
import styled from "styled-components";

import Title from "../../../components/text/Title";
import BarlowBold from "../../../commons/mixins/fonts/BarlowBold";
import Barlow from "../../../commons/mixins/fonts/Barlow";
import Device from "../../../commons/Device";
import { Row, Col } from "antd";

const Styles = styled.div`
  margin-bottom: 2rem;
  padding: 2rem;
  width: 100%;
  min-height: 40vh;
  box-shadow: 0.911435px 0.911435px 22.7859px rgba(0, 0, 0, 0.15);
  border-radius: 18.7062px;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  .benefit-icon {
    display: flex;
    align-items: baseline;
    img {
      width: 100%;
      padding: 1rem;
    }
  }
  .benefit-icon-wrapper {
    flex-grow: 1;
  }
  .benefit-description {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .custom-title {
    margin: 0;
    ${BarlowBold(22, 600, "#352F80")}
  }

  p {
    ${Barlow(18, 400, "#352F80")}
    margin: 0;
  }
  @media ${Device.xl} {
    min-height: 340px;
    .custom-title {
      font-size: 32px;
    }
    p {
      font-size: 18px;
    }
  }

  @media ${Device.xs} {
    .custom-title {
      font-size: 28px;
    }
    p {
      font-size: 25px;
    }
  }
`;

const ValueCard = ({ title, content, icon }) => (
  <Styles>
    <Row type="flex" justify="start" className="benefit-icon-wrapper">
      <Col xs={11} className="benefit-icon">
        <img src={icon} alt={title} />
      </Col>
    </Row>
    <Title content={title} size={3} />
    <p>{content}</p>
  </Styles>
);
export default ValueCard;
