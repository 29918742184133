/*----- Core -----*/
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

/*----- Components -----*/
import { Row, Col } from "antd";
import BubbleParticles from "../../../components/particles/BubbleParticles";
import ButtonContainerHome from "../../../components/button/ButtonContainerHome";
/*----- Commons -----*/
import Device from "../../../commons/Device";
import Color from "../../../commons/Color";
import HerbariumFont from "../../../commons/mixins/fonts/Herbarium";

/*----- Assets -----*/
import BackgroundImage from "../../../assets/backgrounds/bg-canvas.png";

const StyledCanvas = styled.section`
  position: relative;
  height: 100vh;
  width: 100%;
  .button-wrapper {
    padding: 0;
  }
  .herbarium {
    ${HerbariumFont(45, 400, Color.button)};
    margin-left: 2px;
    margin-bottom: 0;
  }

  .barlow-text-small {
    font-family: Barlow;
    font-style: normal;
    font-weight: normal;
    font-size: 28.6855px;
    line-height: 0%;
    color: #ffffff;
  }

  .barlow-text-big {
    font-family: BarlowBold;
    font-size: 45px;
    line-height: 45px;
    color: #ffffff;
    margin: 0;
  }

  .data-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-evenly;
  }

  .first-data-container {
    width: 95%;
    padding-left: 15%;
    padding-right: 15%;
    display: flex;
  }

  .second-data-container {
    width: 95%;
    padding-left: 15%;
    padding-right: 15%;
    display: flex;
  }

  .third-data-container {
    width: 95%;
    padding-left: 15%;
    padding-right: 15%;
    display: flex;
  }

  @media ${Device.xs} {
    .first-data-container,
    .second-data-container,
    .third-data-container {
      padding-left: 10%;
      padding-right: 10%;
    }

    .herbarium {
      ${HerbariumFont(45, 400, Color.button)};
      margin-left: 15px;
    }
    .barlow-text-big {
      font-family: BarlowBold;
      font-size: 35px;
      line-height: 35px;
    }
    .barlow-text {
      font-family: Barlow;
      font-style: normal;
      font-weight: normal;
      font-size: 28.6855px;
      line-height: 0%;
      color: #ffffff;
      padding-left: 10px;
    }
  }
`;

const CanvasAnimation = () => {
  const { t } = useTranslation();
  return (
    <StyledCanvas
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <BubbleParticles />
      <div className="data-container">
        <Row className="first-data-container">
          <Col span={24}>
            <div className="title-container">
              <span className="barlow-text-small">{t("home.header1")}</span>
              <span className="herbarium">{t("home.header2")}</span>
              <p className="barlow-text-small">{t("home.header3")}</p>
            </div>
          </Col>
        </Row>

        <Row className="second-data-container">
          <Col>
            <div className="title-container">
              <p className="barlow-text-big">{t("home.body1")}</p>
              <p className="barlow-text-big">{t("home.body2")}</p>
            </div>
          </Col>
        </Row>

        <Row className="third-data-container">
          <Col span={24} className="button-wrapper">
            <ButtonContainerHome
              destination={"/getInTouch"}
              content={t("menu.contact")}
              color={"white"}
            />
          </Col>
        </Row>
      </div>
    </StyledCanvas>
  );
};

export default CanvasAnimation;
