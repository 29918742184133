import React from "react";

const Facebook = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13" cy="13" r="12.5" stroke="white" fillOpacity="0" />
    <path
      d="M11.3702 20V12.736H10V10.966H11.3702V10.1978C11.3702 9.18476 11.6652 8.39991 12.2553 7.84329C12.8511 7.2811 13.6823 7 14.7489 7C15.0894 7 15.5064 7.05566 16 7.16699L15.9745 9.03725C15.7702 8.98715 15.5206 8.96211 15.2255 8.96211C14.3007 8.96211 13.8383 9.38793 13.8383 10.2396V10.966H15.6681V12.736H13.8383V20H11.3702Z"
      fill="white"
    />
  </svg>
);

export default Facebook;
