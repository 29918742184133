/*----- Core -----*/
import React from "react";
import styled from "styled-components";

/*----- Components -----*/

import Title from "../../../components/text/Title";
import ValueCard from "./ValueCard";
import { values } from "../../../utils";
import Device from "../../../commons/Device";
import { Row, Col } from "antd";
import FrameBackground from "../../../assets/services/frame-about-us.svg";
import { useTranslation } from "react-i18next";

const Styles = styled.div`
  .background-frame {
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${FrameBackground});
    z-index: -1;
    position: relative;
    padding: 0 15%;
  }

  .values-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 999;
    padding-bottom: 2rem;
  }
  .values-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .values-title {
    color: #de3080 !important;
    margin: 0;
    font-size: 36px;
  }
  .value-entry {
    color: #231a77 !important;
    margin: 0;
    font-size: 40px;
  }
  .values-card-deck {
    width: 100%;
  }
  .value-card {
    padding: 1rem;
  }

  @media ${Device.md} {
    .left-col {
      padding-bottom: 4rem;
      padding-right: 2rem;
    }
    .right-col {
      padding-top: 4rem;
      padding-right: 2rem;
    }
    .values-title {
      font-size: 1.75rem;
    }
    .value-entry {
      font-size: 2.5rem;
    }
    .background-wrapper {
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(${FrameBackground});
    }

    .values-container {
      padding-bottom: 2rem;
      align-items: baseline;
    }
  }
  @media ${Device.xs} {
    .background-frame {
      padding: 0 5%;
      & > div {
        width: 100%;
      }
      .values-container {
        align-items: flex-start;
      }
    }
  }
`;

const ValueList = () => {
  const { t } = useTranslation();
  const getValues = () => (
    <Row
      type="flex"
      justify="center"
      className="values-card-deck"
      data-aos="fade-up"
      data-aos-duration="2000"
    >
      <Col lg={24} xl={12} className="left-col">
        {values.slice(0, 2).map((value, idx) => (
          <ValueCard
            key={idx}
            title={t(value.title)}
            content={t(value.content)}
            icon={value.icon}
          />
        ))}
      </Col>
      <Col lg={24} xl={12} className="right-col">
        {values.slice(2, 5).map((value, idx) => (
          <ValueCard
            key={idx}
            title={t(value.title)}
            content={t(value.content)}
            icon={value.icon}
          />
        ))}
      </Col>
    </Row>
  );

  return (
    <Styles>
      <Row type="flex" justify="center" className="background-frame">
        <Col md={24} lg={10}>
          <div className="values-container">
            <div
              className="values-wrapper"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <Title
                content={t("aboutUs.ourValues")}
                size={2}
                className="values-title"
              />
              <Title
                content={t("aboutUs.value1")}
                size={1}
                className="value-entry"
              />
              <Title
                content={t("aboutUs.value2")}
                size={1}
                className="value-entry"
              />
              <Title
                content={t("aboutUs.value3")}
                size={1}
                className="value-entry"
              />
            </div>
          </div>
        </Col>
        <Col md={24} lg={14}>
          {getValues()}
        </Col>
      </Row>
    </Styles>
  );
};

export default ValueList;
