/*----- Core -----*/
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Form, Button } from 'antd';

import Color from '../../../commons/Color';
import Device from '../../../commons/Device';
import Barlow from '../../../commons/mixins/fonts/Barlow';

import InputItem from '../../../components/formItems/InputItem';
import TextAreaItem from '../../../components/formItems/TextAreaItem';
import InputFiles from './InputFiles';
import errorNotifications from '../../../components/commons/notifications/ErrorNotifications';
import successNotifications from '../../../components/commons/notifications/SuccessNotifications';

const Styles = styled.div`
  .ant-input,
  .ant-input:focus {
    border-top: none;
    border-left: none;
    border-right: none;
  }

  .container-button-form {
    button {
      ${Barlow(14, 700, 'white')};
      background: ${Color.button};
      text-transform: capitalize;
      height: 45px;
      border-radius: 3px;
      padding: 0 10px;
      cursor: pointer;
      border-color: initial;
      min-width: 160px;
    }

    button:hover {
      color: white;
      background: ${Color.buttonSecondary};
      transform: scale(1.04);
      box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.2);
    }
  }

  .ant-form-item {
    margin-bottom: 5px;
  }

  .ant-upload.ant-upload-drag p.ant-upload-text {
    color: #8e8e8e8a;
    font-size: 14px;
  }

  .ant-upload.ant-upload-drag .ant-upload {
    padding: 8px 0;
  }

  .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    margin-bottom: 10px;
  }

  @media ${Device.xs} {
    .container-button-form {
      button {
        min-width: 100%;
      }
    }
  }
`;
const CvForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [errorFile, setErrorFile] = useState(false);
  const { t } = useTranslation();
  const { form } = props;
  const { getFieldDecorator } = form;

  const hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    form.validateFields();
    if (hasErrors(form.getFieldsError())) {
      console.log('Error de validación');
    } else {
      if (!files.length) {
        setErrorFile('Debe seleccionar archivo/s.');
        return;
      }
      sendEmail(form.getFieldsValue());
    }
  };

  const sendEmail = async (formValues) => {
    setLoading(true);

    const getMenssage = () => {
      return formValues.message && formValues.message.length
        ? `<p>Mensaje: ${formValues.message}</p>`
        : '';
    };

    let response = await fetch('https://mensajes.snappler.com/api/messages', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Token token=b0b36e74-297a-43a2-82f4-3185680b9564',
      },
      mode: 'cors',
      body: JSON.stringify({
        sender: formValues.name,
        sender_email: 'snappler-web@snappler.com',
        recipients: ['rrhh@snappler.com'],
        subject: 'CV snappler-web',
        template_name: 'message-attachment',
        template_params: {
          header: 'Nuevo mensaje desde la web',
          body: `<p>Email de ${formValues.email}</p>
                            <p>Url del test ${
                              formValues.url ? formValues.url : '---'
                            }</p>
                            ${getMenssage()}`,
        },
        reply_to: [formValues.email],
        attachments: files,
      }),
    });

    if (response.ok) {
      successNotifications();
      setErrorFile('');
      setFiles([]);
      form.resetFields();
    } else {
      errorNotifications();
    }

    setLoading(false);
  };

  return (
    <Styles className="cv-form">
      <Form
        onSubmit={(event) => {
          handleSubmit(event);
        }}
      >
        <InputItem
          fieldName={'name'}
          getFieldDecorator={getFieldDecorator}
          placeholder={t('joinOurTeam.form.name')}
          rules={[
            {
              required: true,
              message: t('joinOurTeam.form.error.required'),
            },
          ]}
        />

        <InputItem
          fieldName={'email'}
          getFieldDecorator={getFieldDecorator}
          placeholder="Email"
          rules={[
            {
              required: true,
              message: t('joinOurTeam.form.error.required'),
            },
            {
              type: 'email',
              message: t('joinOurTeam.form.error.invalid'),
            },
          ]}
        />

        <TextAreaItem
          fieldName={'message'}
          placeholder={t('joinOurTeam.form.msg')}
          getFieldDecorator={getFieldDecorator}
        />

        <InputFiles files={files} setFiles={setFiles} error={errorFile} />

        <div className="container-button-form">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="custom-button-ant"
            loading={loading}
          >
            {t('joinOurTeam.form.submit')}
          </Button>
        </div>
      </Form>
    </Styles>
  );
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'cv_form' })(CvForm));
