/*----- Core -----*/
import React from 'react'
import styled from 'styled-components'

/*----- Commons -----*/
import Device from '../Device'

/*----- Assets -----*/
import Cube from '../../assets/services/Grupo1818.svg'

const Styles = styled.div`
    position:absolute;
    z-index:3;
    bottom: 16%;
    left: 12%;
    background-color:transparent;
    
    img{
        width:2vh
    }

    @media ${Device.xs} {
        display:none;
    }
`

const FloatingCube = () => {
    return (
        <Styles>
            <img src={Cube} alt="Cubo" />
        </Styles>
    )
}

export default FloatingCube