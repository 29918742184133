/*----- Core -----*/
import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

/*----- Commons -----*/
import BarlowBold from "../../commons/mixins/fonts/BarlowBold";
import Device from "../../commons/Device";
import Color from "../../commons/Color";

const Styles = styled.div`
  .section-title {
    ${BarlowBold(40, 600, Color.text)};
    line-height: 40px;
  }

  ${(props) =>
    props.align &&
    css`
      text-align: right;
    `}

  @media ${Device.xs} {
    text-align: center;
    .section-title {
      ${BarlowBold(36, 600, Color.text)};
      line-height: 36px;
    }
  }

  ${(props) =>
    props.color &&
    css`
      .section-title {
        color: ${props.color};
      }
    `}
`;

const SectionTitle = ({ content, color, align }) => (
  <Styles color={color} align={align} className="title-container">
    {content}
  </Styles>
);

/*----- PropTypes -----*/
SectionTitle.propTypes = {
  /* *
   * title color
   */
  color: PropTypes.string,
  /* *
   * title alignment
   */
  align: PropTypes.string,
};

export default SectionTitle;
