import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataContainer, Img, DiamondImage } from './styles';
import { Col, Row } from 'antd';
import Description from './description';
import Title from '../../components/text/Title';
import Diamond from '../../assets/services/Group7.svg';
import PropTypes from 'prop-types';

const SuccessStoryDesktop = ({ project }) => {
  const { t } = useTranslation();
  return (
    <DataContainer>
      <Row type="flex">
        <Col lg={10} className="description-col">
          <Title
            content={t('projects.aboutTitle')}
            size={2}
            className="project-description"
          />
          <p>{t(project.about)}</p>
          {project.subprojects ? (
            project.subprojects.map((project, index) => (
              <div key={index}>
                <Title
                  content={t(project.title)}
                  size={2}
                  className="subproject-title"
                />
                <Description
                  challenge={t(project.challenge)}
                  interventions={project.interventions}
                />
              </div>
            ))
          ) : (
            <Description
              challenge={t(project.challenge)}
              interventions={project.interventions}
            />
          )}
          <DiamondImage src={Diamond} alt="Diamante" />
        </Col>
        <Col lg={12} className="mockups-col">
          {!project.subprojects
            ? project.mockups.map((mockup, index) => (
                <Img src={mockup} key={index} alt={t(project.title)} />
              ))
            : project.subprojects.map((project, index) => (
                <Img src={project.mockup} key={index} alt={t(project.title)} />
              ))}
        </Col>
      </Row>
    </DataContainer>
  );
};

export default SuccessStoryDesktop;

SuccessStoryDesktop.propTypes = {
  /* *
   * The story project
   */
  project: PropTypes.object.isRequired,
};
