/*----- Core -----*/
import React from "react";
import FloatingImage from "../../../commons/FloatingImage";
import Lightning from "../../../commons/lightning";
import Diamond from "../../../assets/services/diamond.svg";

const FloatingElements = () => (
  <>
    <Lightning
      left="34%"
      top="245px"
      width="1px"
      height="111px"
      downColor="#FFFFFF00"
      upColor="#DE3080"
      displayMobile="none"
      topMobile="80%"
      leftMobile="80%"
    />
    <Lightning
      left="65%"
      top="120px"
      width="1px"
      height="212px"
      downColor="#FFFFFF00"
      upColor="#40ADE3"
      displayMobile="none"
      topMobile="25%"
      leftMobile="80%"
    />

    <Lightning
      top="500px"
      left="77%"
      width="1px"
      height="112px"
      downColor="#FFFFFF00"
      upColor="#352F80"
    />
    <FloatingImage
      image={Diamond}
      right={"45%"}
      top={"550px"}
      displayMobile="none"
      rightMobile="35%"
      topMobile="80%"
    />
  </>
);

export default FloatingElements;
