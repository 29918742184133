/*----- Core -----*/
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

/*----- Components -----*/
import { Drawer } from "antd";
import OptionDrawer from "./OptionDrawer";
import { Drawers } from "./Drawers";

/*----- Commons -----*/
import Color from "../../../commons/Color";
import Device from "../../../commons/Device";

/*----- Assets -----*/
import BackgroundMenu from "../../../assets/images/background-menu.svg";

import { isDevice } from "../../../utils";
import RadioGroup from "../../form/RadioGroup";
import { useTranslation } from "react-i18next";

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content {
    background-image: url("${BackgroundMenu}");
    background-size: 85%;
    background-repeat: no-repeat;
    background-position: top right;
    background-color: rgba(0, 21, 41, 0.75);
    /* padding:10%; */
    overflow: hidden;
  }

  .ant-drawer-close {
    svg {
      fill: white;
      width: 1.5em;
      height: 1.5em;
    }
    &:hover {
      svg {
        fill: ${Color.button};
      }
    }
  }
  .ant-drawer-wrapper-body {
    position: relative;
    overflow: hidden;
    padding: 10%;
  }

  @media ${Device.xl} {
    .ant-drawer-wrapper-body {
      padding-top: 20%;
    }
  }

  @media ${Device.xs} {
    .ant-drawer-close {
      width: 100px;
      height: 10px;
    }
    .ant-drawer-content {
      padding: 30px 5px;
      background-size: contain;
    }

    .ant-drawer-wrapper-body {
      padding: 5%;
    }
    .ant-drawer-close {
      svg {
        width: 1em;
        height: 1em;
      }
    }
  }

  @media ${Device.md} {
    .ant-drawer-close {
      width: 200px;
      height: 150px;
    }
  }

  @media ${Device.lg} {
    .ant-drawer-close {
      width: 320px;
      height: 200px;
      svg {
        width: 1.5em;
        height: 1.5em;
      }
    }
  }
`;

const CustomDrawer = ({ visible, onClose }) => {
  const { i18n, t } = useTranslation();
  const getDrawers = () => {
    return Drawers.map((drawer, index) => {
      return (
        <OptionDrawer
          key={index}
          content={t(drawer.content)}
          destination={drawer.destination}
          onSelect={onClose}
        />
      );
    });
  };
  return (
    <StyledDrawer
      placement="right"
      closable={true}
      onClose={onClose}
      visible={visible}
      style={{ zIndex: 2000 }}
      width={isDevice() ? "70%" : "37%"}
    >
      {getDrawers()}
      <RadioGroup
        options={[
          { label: t("menu.langEs"), value: "es" },
          { label: t("menu.langEn"), value: "en" },
        ]}
        value={i18n.language}
        handleClick={(lng) => i18n.changeLanguage(lng)}
      />
    </StyledDrawer>
  );
};

export default CustomDrawer;

CustomDrawer.propTypes = {
  /* *
   * Turn on/off visibility
   */
  visible: PropTypes.bool.isRequired,
  /* *
   * Callback handler when Closed
   */
  onClose: PropTypes.func.isRequired,
};
