import { css } from "styled-components";

const CustomFont = (font, size, weight, color) => css`
  font-family: ${font};
  color: ${color};
  font-size: ${size}px;
  font-weight: ${weight};
`;

export default CustomFont;
