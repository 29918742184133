import React from "react";
import Cross from "../../assets/services/cross.svg";
import Cube from "../../assets/services/card-cube.svg";
import Diamond from "../../assets/services/card-diamond.svg";
import FloatingImage from "../../commons/FloatingImage";

const FloatingElements = () => (
  <>
    <FloatingImage image={Cross} width={"5px"} left={"10%"} top={"5%"} />
    <FloatingImage image={Cube} width={"15px"} left={"5%"} top={"10%"} />
    <FloatingImage image={Diamond} width={"20px"} right={"10%"} top={"35%"} />
  </>
);

export default FloatingElements;
