import styled from "styled-components";
import Device from "../../../../commons/Device";

export const StyledPartners = styled.section`
  min-height: 80vh;
  padding: 40px 10px;
  position: relative;
  display: flex;
  align-items: center;

  .section-title {
    margin-bottom: 25px;
  }
  .container-partner-list {
    padding: 0px 45px;
  }

  @media ${Device.xs} {
    margin-top: 3rem;

    .container-partner-list {
      padding: 0px;
    }

    .section-title {
      font-size: 36px;
      line-height: 34px;
      margin-bottom: 15%;
      text-align: left;
    }
  }
`;

export const DataContaier = styled.div`
  width: 100%;
  top: 6vh;
  padding-left: 15%;
  padding-right: 15%;

  @media ${Device.xs} {
    top: 0vh;
    padding-left: 5%;
    padding-right: 5%;
  }
`;

export const PartnerListContainer = styled.div`
  @media ${Device.xs} {
    padding: 0px;
  }
`;
