/*----- Core -----*/
import React from "react";
import { useTranslation } from "react-i18next";
import { ImageContainer, TitleContainer, MainContainer } from "./styles";

/*----- Components -----*/
import Title from "../../../components/text/Title";

const GetInTouchHeader = () => {
  const { t } = useTranslation();
  return (
    <MainContainer>
      <ImageContainer />
      <TitleContainer>
        <Title content={t("menu.contact")} size={1} />
      </TitleContainer>
    </MainContainer>
  );
};

export default GetInTouchHeader;
