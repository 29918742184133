/*----- Core -----*/
import React, { useEffect } from "react";
import { connect } from "react-redux";

/*----- Sections -----*/
import CanvasAnimation from "./section/CanvasAnimation";
import Software from "./section/Software";
import Partners from "./section/Partners";
import Team from "./section/Team";
import Projects from "./section/Projects";

/*----- Actions -----*/
import { changeLogoColor } from "../app/AppAction";

const Home = (props) => {
  useEffect(() => {
    props.changeLogoColor("white");
  }, [props.changeLogoColor, props]);

  return (
    <>
      <CanvasAnimation />
      <Software />
      <Projects />
      <Team />
      <Partners />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  changeLogoColor: (payload) => changeLogoColor(dispatch, payload),
});

export default connect(null, mapDispatchToProps)(Home);
