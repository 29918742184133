/*----- Core -----*/
import React from "react";

import Lightning from "../../../../commons/lightning";
import FloatingImage from "../../../../commons/FloatingImage";
import Diamond from "../../../../assets/services/Grupo1.svg";

const FloatingElements = () => (
  <>
    <Lightning
      top="5%"
      right="30%"
      width="1px"
      height="150px"
      downColor="#FFFFFF00"
      upColor="#00B6FF"
    />
    <FloatingImage image={Diamond} width={"30px"} left={"35%"} bottom={"15%"} />
  </>
);

export default FloatingElements;
