import React from 'react'
import styled from 'styled-components'

/*----- Commons -----*/
import Device from '../Device'

/*----- Assets -----*/
import LightningPic from '../../assets/services/Grupo1827.svg'

const Styles = styled.div`
    position:absolute;
    z-index:3;
    top: 24%;
    left: 7%;
    background-color:transparent;

    @media ${Device.xs} {
        display:none;
    }
`

const Lightning = () => {
    return (
        <Styles>
            <img src={LightningPic} alt="LightningPic" />
        </Styles>
    )
}

export default Lightning