import styled from "styled-components";
import Device from "../../../../commons/Device";

export const StyledServices = styled.section`
  /* min-height: 115vh; */
  position: relative;

  .data-container {
    width: 100%;
    /* padding-top: 10px!important; */
  }

  .button-container {
    padding-top: 3rem;
  }

  .number-container {
    position: absolute;
    width: 60%;
    right: 0;
    top: 90%;
  }

  .title-container {
    padding-top: 0;
  }

  @media ${Device.md} {
    .text-team {
      width: 100%;
    }
    .text-experiences {
      width: 65%;
      padding-left: 2rem;
      padding-top: 0.75rem;
    }
  }

  @media ${Device.md} {
    .title-container {
      padding-top: 0;
    }
  }

  @media ${Device.xs} {
    padding-top: 30px;
    .data-container {
      top: 12%;
    }

    .title-container {
      padding-top: 65px;
    }

    .button-container {
      padding-top: 2em;
    }

    .number-container {
      display: none;
    }

    .section-title {
      display: none;
    }
  }
`;
