import React from "react";

import NumberOne from "../assets/services/numbers/NumberOne";
import NumberTwo from "../assets/services/numbers/NumberTwo";
import NumberThree from "../assets/services/numbers/NumberThree";

export const itemsWhatWeDo = [
  {
    title: "whatWeDo.applications.title",
    description:
      "whatWeDo.applications.description",
    tags: [
      "HTML5",
      "CSS",
      "JAVASCRIPT",
      "RUBY",
      "REACT",
      "RAILS",
      "PWA",
      "SQL",
      "NoSQL",
      "AWS",
      "SERVERLESS",
    ],
    number: <NumberOne />,
    data: [
      {
        title: "whatWeDo.applications.data.first.title",
        content:
          "whatWeDo.applications.data.first.content",
      },
      {
        title: "whatWeDo.applications.data.second.title",
        content:
          "whatWeDo.applications.data.second.content",
      },
    ],
  },
  {
    title: "whatWeDo.design.title",
    description:
      "whatWeDo.design.description",
    tags: ["SERVICE DESIGN", "UX/UI", "DESIGN THINKING","UX WRITING"],
    number: <NumberTwo />,
    data: [
      {
        title: "whatWeDo.design.data.first.title",
        subcontent: [
          {
            content:
            "whatWeDo.design.data.first.content.subcontent1",
          },
          {
            content:
            "whatWeDo.design.data.first.content.subcontent2",
          },
          {
            content:
            "whatWeDo.design.data.first.content.subcontent3",
          },
        ],
      },
      {
        title: "whatWeDo.design.data.second.title",
        subcontent: [
          {
            content:
            "whatWeDo.design.data.second.content.subcontent1",
          },
          {
            content:
            "whatWeDo.design.data.second.content.subcontent2",
          },
          {
            content:
            "whatWeDo.design.data.second.content.subcontent3",
          },
        ],
      },
    ],
  },
  {
    title: "whatWeDo.devops.title",
    description:
      "whatWeDo.devops.description",
    tags: ["AWS", "DOCKER", "CLOUD FORMATION", "CI/CD"],
    number: <NumberThree />,
    data: [
      {
        title: "whatWeDo.devops.data.first.title",
        content:
        "whatWeDo.devops.data.first.content",
      },
      {
        title:  "whatWeDo.devops.data.second.title",
        subcontent: [
          {
            title: "whatWeDo.devops.data.second.content.subcontent1.title",
            content:
            "whatWeDo.devops.data.second.content.subcontent1.content",
          },
          {
            title: "whatWeDo.devops.data.second.content.subcontent2.title",
            content:
            "whatWeDo.devops.data.second.content.subcontent3.content",
          },
          {
            title: "whatWeDo.devops.data.second.content.subcontent3.title",
            content:
            "whatWeDo.devops.data.second.content.subcontent3.content",
          },
        ],
      },
    ],
  },
];
