/*----- Antd -----*/
import { notification } from "antd";
import PropTypes from "prop-types";

const ErrorNotifications = (
  description = "Ha ocurrido un error. Intente de nuevo"
) => {
  return notification.error({
    message: description,
    duration: 5,
    placement: "topRight",
  });
};

export default ErrorNotifications;

ErrorNotifications.propTypes = {
  /* *
   * Notification message
   */
  description: PropTypes.string,
};
