/*----- Imports -----*/
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { css } from "styled-components";
import { Link } from "react-router-dom";
/*----- Commons-----*/
import Barlow from "../../commons/mixins/fonts/Barlow";
import Color from "../../commons/Color";
import Device from "../../commons/Device";

/*----- Styles-----*/
const StyledDefaultButton = styled(Link)`
  ${Barlow(14, 700, "white")};
  text-transform: capitalize;
  height: 45px;
  border-radius: 3px;
  padding: 0 10px;

  cursor: pointer;

  min-width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 5%;
  /* max-width: 200px; */
  border: 1.5px solid white;

  transition: all 0.3s ease-out;
  &:focus {
    outline: none;
  }
  &:hover {
    color: #002944;
    background: white;
    transform: scale(1.04);
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.2);
  }

  ${(props) =>
    props.invert &&
    css`
      background: white;
      box-shadow: none !important;
      border: 1px solid ${Color.button};
      color: ${Color.button};
      &:hover {
        color: ${Color.button};
      }
    `}

  ${(props) =>
    props.secondary &&
    css`
      background: ${Color.secondary};
      border: none;
      color: white;
      &:hover {
        color: "white" !important;
      }
    `}

	${(props) =>
    props.secondary &&
    props.invert &&
    css`
      background: none;
      border: 1px solid ${Color.secondary};
      color: ${Color.secondary} !important;
    `}

	${(props) =>
    props.color &&
    css`
      ${Barlow(14, 600, props.color)};
    `}

	.default-button__icon {
    width: 16px;
    margin-right: 8px;
  }

  @media ${Device.xs} {
    height: 45px;
  }
`;

const StyledButtonContainer = styled.div`
  @media ${Device.md} {
    padding-left: 8vw;
    padding-top: 44px;
  }

  @media ${Device.xs} {
    padding-top: 67px;
  }
`;

/*----- Component -----*/
const WhiteButton = ({
  content,
  destination,
  invert,
  icon,
  secondary,
  color,
  handlerClick,
}) => (
  <StyledButtonContainer className="white-button-container">
    <StyledDefaultButton
      to={destination}
      color={color}
      className="default-button"
      invert={invert}
      secondary={secondary}
      onClick={handlerClick}
    >
      {icon ? (
        <img
          src={icon}
          className="default-button__icon"
          alt={`${content} icon`}
        />
      ) : null}
      {content}
    </StyledDefaultButton>
  </StyledButtonContainer>
);
export default WhiteButton;

/*----- PropTypes -----*/
WhiteButton.propTypes = {
  /* *
   *  Destination route path
   */
  destination: PropTypes.string.isRequired,
  /* *
   * Button's text
   */
  content: PropTypes.string,
  /* *
   * Invert button style
   */
  invert: PropTypes.bool,
  /* *
   *  Source image/icon asset
   */
  icon: PropTypes.string,
  /* *
   * Activate secondary color styles
   */
  secondary: PropTypes.bool,
  /* *
   * Set primary color style
   */
  color: PropTypes.string,
  /* *
   * Callback handler when clicked
   */
  handlerClick: PropTypes.func,
};
