/*----- Core -----*/
import React from 'react'
import styled from 'styled-components'

/*----- Commons -----*/
import Device from '../Device'

/*----- Assets -----*/
import Diamond from '../../assets/services/Grupo1817.svg'

const Styles = styled.div`
    position:absolute;
    z-index:3;
    top: 15%;
    right: 8%;
    background-color:transparent;

    img{
        width:3vh
    }

    @media ${Device.xs} {
        display:none;
    }
`

const FloatingDiamond = () => {
    return (
        <Styles>
            <img src={Diamond} alt="Diamond" />
        </Styles>
    )
}

export default FloatingDiamond