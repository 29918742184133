/*----- Core -----*/
import React from "react";
import styled from "styled-components";

/*----- Assets -----*/
import Logo from "../../../Logo";
import { Link } from "react-router-dom";

/*----- Commons -----*/
import Device from "../../../commons/Device";

const StyledMenuLogo = styled.div`
  .conainer-logo {
    padding-top: 7px;
  }

  svg {
    fill: white;
    width: 107px;
  }

  @media ${Device.xs} {
    .conainer-logo {
      padding-top: 10px;
    }
  }
`;

const MenuLogo = () => (
  <StyledMenuLogo className="menu-logo">
    <div className="conainer-logo">
      <Link to={"/"}>
        <Logo />
      </Link>
    </div>
  </StyledMenuLogo>
);

export default MenuLogo;
