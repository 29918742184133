import styled from "styled-components";
import Device from "../../../../commons/Device";

export const StyledTeam = styled.section`
  .data-container {
    position: absolute;
    top: 70%;
    padding-left: 15%;
  }

  .video-container {
    position: relative;
    height: 94vh;
  }

  video {
    top: 0%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 0.6;
    position: absolute;
  }

  .video-overlay {
    height: 100%;
    background-color: #030c39;
  }

  .background-text-container {
    top: 25%;
    position: absolute;
    text-align: right;
  }

  .svg-container {
    padding-left: 15%;
    padding-right: 8%;
  }

  @media ${Device.xs} {
    .video-container {
      max-width: 140%;
      overflow: hidden;
    }

    video {
      top: 50%;
      transform: translate(-49%, -50%);
      width: 200%;
    }

    .svg-container {
      padding: 0;
    }

    .background-text-container {
      top: 2%;
    }
    .data-container {
      padding-left: 7.5%;
    }
  }
`;
