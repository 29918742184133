import styled from "styled-components";
import Device from "../../commons/Device";
import Color from "../../commons/Color";
import Barlow from "../../commons/mixins/fonts/Barlow";
import BarlowBold from "../../commons/mixins/fonts/BarlowBold";

export const CardContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  /* opacity: 0.9; */
  position: relative;

  @media ${Device.xs} {
    margin-bottom: 50px;
  }

  .information-card {
    display: none;
  }

  svg {
    display: none;
    fill: #77c0ff;
    width: 18px;
    height: 18px;
  }

  @media ${Device.sm} {
    &&:hover {
      cursor: pointer;
      .information-card {
        background: #002944;
        display: block;
        position: absolute;
        top: 0px;
        height: 100%;
        width: 100%;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .see-more {
        display: block;
        position: absolute;
        bottom: 1rem;
        right: 1rem;
      }
    }
  }

  @media ${Device.xs} {
    .information-card {
      display: block;
    }
  }
`;

export const StyledImage = styled.img`
  border-radius: 8px;
  width: 100%;
  height: auto;
`;

export const StyledInformationCard = styled.div`
  text-align: center;
  height: 100%;
  padding: 0px 15px;
  /* opacity: 0.9; */
`;

export const StyledInformationTitle = styled.p`
  ${Barlow(32, 600, "#FFF")};
  text-align: center;
  line-height: 32px;
  margin-bottom: 16px;

  @media ${Device.xs} {
    margin-top: 16px;
    color: ${Color.text};
    line-height: 26px;
    margin-bottom: 10px;
  }
`;
export const StyledInformationSubtitle = styled.p`
  ${BarlowBold(24, 600, "#FFF")};
  text-align: center;
  line-height: 30px;
  margin-bottom: 4px;

  @media ${Device.xs} {
    margin-top: 16px;
    color: ${Color.text};
    line-height: 26px;
    margin-bottom: 10px;
  }
`;

export const StyledInformationTitleDesktop = styled(StyledInformationTitle)`
  text-align: left;
  color: ${Color.text};
  /* font-size: 22px; */
  margin-bottom: 0px;
`;

export const ContainerTags = styled.div`
  margin: 15px 0px 10px;
`;
export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5px;
  padding-right: 10px;
  margin-bottom: 24px;
`;
