/*----- Core -----*/
import React, { useRef } from "react";
import { Icon, Button } from "antd";
import Barlow from "../../../commons/mixins/fonts/Barlow";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Styles = styled.div`
  input[type="file"] {
    color: transparent;
  }

  .filename {
    ${Barlow(16, 400, "#1E115C")}
    margin: 5px 0px;

    span {
      padding-left: 10px;
      svg {
        fill: red;
      }
    }

    span:hover {
      cursor: pointer;
    }
  }

  button span {
    ${Barlow(14, 400, "#1E115C")}
    color: rgba(0, 0, 0, 0.65);
  }
`;

const InputFiles = ({ files, setFiles, error }) => {
  const fileRef = useRef();
  const { t } = useTranslation();
  const changeFile = (e) => {
    const getBase64File = async (file) => {
      if (!file) {
        return;
      }

      let file64 = await new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

      if (file64) {
        file64 = file64.split("base64,");
        file64 = file64[1];

        let dataFile = {
          type: file.type,
          name: file.name,
          content: file64,
        };

        setFiles([...files, dataFile]);
      }
    };

    getBase64File(fileRef.current.files[0]);
  };

  const removeFile = (idx) => {
    let data = files.filter((file, i) => {
      return i !== idx;
    });
    setFiles(data);
  };

  const getFilenames = () => {
    return files.map((file, idx) => (
      <div key={idx}>
        <p className="filename">
          {file.name}{" "}
          <span onClick={() => removeFile(idx)}>
            <Icon type="delete" />
          </span>
        </p>
      </div>
    ));
  };

  return (
    <Styles>
      <Button
        icon="upload"
        onClick={() => {
          console.log(fileRef.current.click());
        }}
      >
        {t("joinOurTeam.form.files")}
      </Button>
      <input
        style={{ display: "none" }}
        type="file"
        accept=".jpg,.png,.doc,.docx,.pdf"
        onChange={changeFile}
        ref={fileRef}
      />
      {getFilenames()}
      <p>{error}</p>
    </Styles>
  );
};

export default InputFiles;
