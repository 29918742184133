export const questions = [
  {
    subtitle: 'projectDiscovery.ProjectDiscovery.subtitle',
    benefits: 'projectDiscovery.benefits',
    label: 'projectDiscovery.ProjectDiscovery.label',
    seeMore: '/projectDiscovery/ProjectDiscovery',
    tags: 'projectDiscovery.tags',
    tagsMobile: 'projectDiscovery.ProjectDiscovery.tagsMobile',
    about: 'projectDiscovery.ProjectDiscovery.about',
    about2: 'projectDiscovery.ProjectDiscovery.about2',
    challenge: 'projectDiscovery.ProjectDiscovery.challenge',
    howTo: 'projectDiscovery.ProjectDiscovery.howTo',
    bonusTrack: 'projectDiscovery.bonus',
    ourExp: 'projectDiscovery.exp',
  },
];
