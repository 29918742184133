/*----- Core -----*/
import React from "react";
import { useTranslation } from "react-i18next";

/*----- Components -----*/
import SectionTitle from "../../../../components/text/SectionTitle";
import PartnerList from "./PartnerList";
import FloatingElements from "./FloatingElements";
import { StyledPartners, DataContaier, PartnerListContainer } from "./styles";

const Partners = () => {
  const { t } = useTranslation();
  return (
    <StyledPartners>
      <FloatingElements />
      <DataContaier data-aos="fade-up" data-aos-duration="1500">
        <SectionTitle
          content={<h2 className="section-title">{t("home.partners")}</h2>}
        />
        <PartnerListContainer>
          <PartnerList />
        </PartnerListContainer>
      </DataContaier>
    </StyledPartners>
  );
};

export default Partners;
